import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { TransformedGiraData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/transform-result.tsx';
import type { JiraSettingsAGG } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import { PROJECT_ACTIVE } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import type {
	TransformedDataToLegacyGraphql,
	TransformedDataToLegacyGira,
} from '@atlassian/jira-issue-shared-types/src/common/types/transformed-data-type.tsx';
import { normalizeComments } from '../../comment-transformer';
// eslint-disable-next-line @atlassian/tangerine/import/no-dangling-index -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import { compareComment } from '../comments/index';
import type { TransformDataOld, TransformDataNew } from '../index';

export type CombinedData = TransformedDataToLegacyGraphql & TransformDataOld;

const projectDefaults = {
	projectId: '',
	projectName: '',
	projectKey: '',
	projectStatus: PROJECT_ACTIVE,
	projectType: SOFTWARE_PROJECT,
	isSimplified: false,
};

// Only check the result on page load
let didConsistencyRun = false;
export const combineResponseData = (
	aggLegacyGraphqlData: TransformedDataToLegacyGraphql | null,
	aggLegacyGiraData: TransformedDataToLegacyGira | null,
	giraData: TransformedGiraData,
): CombinedData => {
	if (!aggLegacyGraphqlData || !aggLegacyGiraData) {
		// The transformers did not return anything - this happens if the data is missing for some reason
		// We should never fall into this case but its here for type checking
		const message = 'Failed to transform issue data from AGG, transformers returned empty value';
		throw new Error(message);
	}

	const jiraSettings = {
		jiraSettings: {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			...(aggLegacyGiraData.jiraSettings as JiraSettingsAGG),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			newIssueViewTransitionSettings: (giraData as TransformDataNew).jiraSettings
				?.newIssueViewTransitionSettings,
		},
	};

	const attachments = ff('issue.details.gira-agg-migration-attachments_ecebe')
		? {
				attachments: {
					totalCount: giraData.totalAttachmentsCount || 0,
					nodes: aggLegacyGiraData.attachments || [],
					deletableCount: 0,
				},
			}
		: {
				attachments: giraData.attachments,
			};

	const comments = ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9')
		? {
				...normalizeComments(
					{
						comments: aggLegacyGiraData.comments || [],
						totalComments: giraData.totalComments,
					},
					0,
					giraData.commentsStartIndex,
				),
			}
		: {
				comments: giraData.comments,
			};

	if (!didConsistencyRun && ff('issue-jiv-14530-comments-consistency-check_x716d')) {
		const normalisedAggComments = normalizeComments(
			{
				comments: aggLegacyGiraData.comments || [],
				totalComments: giraData.totalComments,
			},
			0,
			giraData.commentsStartIndex,
		);
		didConsistencyRun = true;
		compareComment(giraData, normalisedAggComments);
	}

	const final = {
		...giraData,
		...aggLegacyGraphqlData,
		...aggLegacyGiraData,
		...jiraSettings,
		...attachments,
		...comments,
		project: {
			...projectDefaults,
			...aggLegacyGraphqlData?.project,
			issueTypes: giraData.issueTypes,
		},
	};

	return final;
};
