import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { deleteIssue } from '@atlassian/jira-issue-view-services/src/issue/issue-delete-server';
import {
	deleteIssueSuccess,
	deleteIssueFailure,
	DELETE_ISSUE_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/issue-delete-actions';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	fireOperationalAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';

const LOG_LOCATION = 'issue.delete.issue-delete-epic';

const fireAnalytics = (
	event: UIAnalyticsEvent,
	action: ActionSubjectAndAction,
	actionSubjectId: string,
	error: Error,
) => {
	if (error) {
		fireOperationalAnalytics(event, action, actionSubjectId);
	} else {
		fireTrackAnalytics(event, { action, actionSubjectId });
	}
};

const getMessageFromError = (error: Error) => {
	try {
		return JSON.parse(error.message).errorMessages[0];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return 'Failed to delete issue';
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(DELETE_ISSUE_REQUEST).mergeMap(({ payload }) => {
		const { issueKey, analyticsEvent, application, edition } = payload;
		const baseUrl = baseUrlSelector(store.getState());

		return deleteIssue(baseUrl, issueKey)
			.map(() => {
				// @ts-expect-error - TS2554 - Expected 4 arguments, but got 3.
				fireAnalytics(analyticsEvent, 'deleted', 'issueDeleted');
				return deleteIssueSuccess(issueKey);
			})
			.do(() => {
				sendExperienceAnalytics({
					experience: 'deleteIssueSucceeded',
					wasExperienceSuccesful: true,
					analyticsSource: 'issueDeleted',
					application: application ?? null,
					edition: edition ?? null,
				});
			})
			.catch((error: Error | FetchError) => {
				log.safeErrorWithoutCustomerData(LOG_LOCATION, 'Failed to delete issue', error);
				fireAnalytics(analyticsEvent, 'delete failed', 'issueDeletedError', error);
				if (!(ff('ken-429-restrict-4xx-error_vi8v7') && isHttpClientErrorResponse(error))) {
					sendExperienceAnalytics({
						experience: 'deleteIssueFailed',
						wasExperienceSuccesful: false,
						analyticsSource: 'issueDeleted',
						application: application ?? null,
						edition: edition ?? null,
					});
				}
				return Observable.of(deleteIssueFailure(issueKey, getMessageFromError(error)));
			});
	});
