import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	annualReadyStandard: {
		id: 'polaris-ideas.beta-banner.annual-ready-standard',
		defaultMessage:
			'Your Standard plan trial ends on {date}. Annual subscriptions and Partner purchasing are now available.',
		description: 'Banner text for the users on Standard trial plan waiting for partner payment',
	},
	betaWarningExtendedLink: {
		id: 'polaris-ideas.beta-banner.beta-warning-extended-link',
		defaultMessage: 'Learn more here',
		description:
			'Link to instructions on how to move from Beta to the Free or Standard plan when on proactively extended Beta plan',
	},
});
