import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	fetchRecentlyVisitedConfluencePagesFailed,
	fetchRecentlyVisitedConfluencePagesSuccess,
	FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/fetch-recently-visited-confluence-pages-actions';
import { cloudIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { systemConfluenceAppLinkSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-app-links-selector';
import fetchRecentlyVisitedConfluencePages from './fetch-recently-visited-confluence-pages-stargate';

const LOG_LOCATION = 'issue.fetch.confluence-recently-viewed-epic';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST).switchMap(() => {
		const state = store.getState();

		// NOTE: The way things are wired here forces this call to be against the current instance cloudId.
		//       The impact here is that any recentlyViewedConfluencePages are always against the current instance.
		//       We could wire up which confluence appLink is currently selected through state, but we would still not
		//       have the cloudId to make the correct stargate call. This should probably be moved to the backend, or get
		//       the backend to return the cloudId with confluence appLinks. If this is kept in the FE, the following call
		//       should return the currently selected confluenceAppLink.
		const systemConfluenceAppLink = systemConfluenceAppLinkSelector(state);
		// If there is no systemConfluenceAppLink, it's save to pass empty baseUrl so that links are completed with the
		// current site base url.
		const systemConfluenceBaseUrl = systemConfluenceAppLink?.baseUrl || '';
		const cloudId = cloudIdSelector(state);
		const response = fetchRecentlyVisitedConfluencePages(systemConfluenceBaseUrl, cloudId);

		return response.map(fetchRecentlyVisitedConfluencePagesSuccess).catch((error) => {
			log.safeErrorWithoutCustomerData(
				LOG_LOCATION,
				'FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST: Failed to fetch recently viewed confluence pages',
				error,
			);
			return Observable.of(fetchRecentlyVisitedConfluencePagesFailed());
		});
	});
