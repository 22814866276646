import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { ActionsObservable } from 'redux-observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { toLinkDirection } from '@atlassian/jira-issue-view-common-types/src/linked-issue-type';
import type { IssueViewFieldUpdateActions } from '@atlassian/jira-issue-view-field-update-events';
import {
	ChangeEventTypes,
	type OnChangeCallback,
} from '@atlassian/jira-issue-view-model/src/change-type';
import {
	type SimpleChangeIssueTypeSuccess,
	SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/change-issue-type-actions';
import {
	type ChildIssueAddSuccess,
	type IssueChildrenOrderChangeSuccessAction,
	type SubtaskAddSuccess,
	type ChildIssueFieldUpdateAction,
	CHILD_ISSUE_ADD_SUCCESS,
	ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS,
	SUBTASK_ADD_SUCCESS,
	CHILD_ISSUE_FIELD_UPDATED,
} from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions';
import {
	type CreateConfluencePageLinkSuccess,
	type DeleteConfluencePageLinkSuccess,
	CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
	DELETE_CONFLUENCE_PAGE_LINK_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions';
import {
	type FetchLinkedIssuesDataSuccess,
	type DeleteLinkedIssueSuccess,
	FETCH_LINKED_ISSUES_DATA_SUCCESS,
	DELETE_LINKED_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions';
import {
	type IssueRelationshipUpdateSuccess,
	ISSUE_RELATIONSHIP_UPDATE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/issue-relationship-actions';
import { issueIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	type FieldSaveSuccessAction,
	type FieldSaveRequestAction,
	type FieldSaveFailureAction,
	type FieldUpdatedAction,
	type FieldConfirmNewFieldAction,
	type FieldConfirmFailureNewFieldAction,
	FIELD_SAVE_SUCCESS,
	FIELD_SAVE_REQUEST,
	FIELD_SAVE_FAILURE,
	FIELD_UPDATED,
	FIELD_CONFIRM_NEW_FIELD,
	FIELD_CONFIRM_FAILURE_NEW_FIELD,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getNewLinksFromAction } from './link';

type Action =
	| FieldSaveSuccessAction
	| FieldSaveRequestAction
	| FieldSaveFailureAction
	| FieldUpdatedAction
	| FieldConfirmNewFieldAction
	| FieldConfirmFailureNewFieldAction
	| SimpleChangeIssueTypeSuccess // link creation
	| IssueRelationshipUpdateSuccess
	| FetchLinkedIssuesDataSuccess
	| DeleteLinkedIssueSuccess
	| ChildIssueAddSuccess
	| SubtaskAddSuccess
	| IssueChildrenOrderChangeSuccessAction
	| ChildIssueFieldUpdateAction
	| CreateConfluencePageLinkSuccess
	| DeleteConfluencePageLinkSuccess;

const actions = [
	FIELD_SAVE_SUCCESS,
	SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS,
	ISSUE_RELATIONSHIP_UPDATE_SUCCESS,
	FIELD_SAVE_REQUEST,
	FIELD_SAVE_FAILURE,
	FIELD_UPDATED,
	FIELD_CONFIRM_NEW_FIELD,
	FIELD_CONFIRM_FAILURE_NEW_FIELD,
	// action is fired after a new link is created
	FETCH_LINKED_ISSUES_DATA_SUCCESS,
	DELETE_LINKED_ISSUE_SUCCESS,
	CHILD_ISSUE_ADD_SUCCESS,
	SUBTASK_ADD_SUCCESS,
	ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS,
	CHILD_ISSUE_FIELD_UPDATED,
	CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
	DELETE_CONFLUENCE_PAGE_LINK_SUCCESS,
];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
		onChange?: OnChangeCallback,
		issueViewFieldUpdateActions?: IssueViewFieldUpdateActions,
	) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(...actions)
			.do((action: Action) => {
				if (!onChange) {
					return;
				}

				try {
					const issueId = toIssueId(String(issueIdSelector(store.getState())));

					switch (action.type) {
						case SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS: {
							const {
								payload: { fieldId, targetTypeName },
							} = action;

							onChange({
								issueId,
								type: ChangeEventTypes.ISSUE_TYPE_CHANGED,
								fieldId,
								meta: {
									typeName: targetTypeName,
								},
							});

							break;
						}
						case FIELD_UPDATED: {
							const {
								payload: { fieldId, fieldValue },
							} = action;

							issueViewFieldUpdateActions?.fieldChanged(issueId, fieldId, fieldValue);

							break;
						}
						case ISSUE_RELATIONSHIP_UPDATE_SUCCESS: {
							const {
								payload: { oldParentIdsByIssueIds, parentIssueId: newParentIssueId },
							} = action;

							onChange({
								issueId,
								type: ChangeEventTypes.ISSUE_RELATIONSHIP_UPDATED,
								meta: {
									newParentIssueId,
									oldParentIssueId: oldParentIdsByIssueIds[issueId] || null,
								},
							});

							break;
						}
						case FETCH_LINKED_ISSUES_DATA_SUCCESS: {
							const links = getNewLinksFromAction(issueId, action);

							if (links.length) {
								onChange({
									issueId,
									type: ChangeEventTypes.ISSUE_LINKS_CREATED,
									meta: {
										links,
									},
								});
							}

							break;
						}
						case DELETE_LINKED_ISSUE_SUCCESS: {
							const {
								meta: { issueId: linkedIssueId, direction },
							} = action;

							onChange({
								issueId,
								type: ChangeEventTypes.ISSUE_LINK_DELETED,
								meta: {
									linkedIssueId,
									/**
									 * BENTO-3476
									 * Model for server link types does not exists yet
									 * This temporary function used to convert direction into proper format
									 * Eventually all values must be replaced with transformed data
									 */
									direction: toLinkDirection(direction),
								},
							});

							break;
						}
						case CHILD_ISSUE_ADD_SUCCESS: {
							const { issueKey, issueLink, issueSummary, issueTypeIconUrl, issueTypeName } =
								action.payload.childIssue;
							onChange({
								issueId,
								type: ChangeEventTypes.CHILD_ISSUE_ADDED,
								meta: {
									childIssue: {
										id: toIssueId(action.payload.childIssue.id),
										issueKey,
										issueLink,
										issueSummary,
										issueTypeIconUrl: issueTypeIconUrl || undefined,
										issueTypeName: issueTypeName || undefined,
									},
								},
							});

							break;
						}
						case SUBTASK_ADD_SUCCESS: {
							const { issueKey, issueLink, issueSummary, issueTypeIconUrl, issueTypeName } =
								action.payload.childIssue;
							onChange({
								issueId,
								type: ChangeEventTypes.SUBTASK_ADDED,
								meta: {
									childIssue: {
										id: toIssueId(action.payload.childIssue.id),
										issueKey,
										issueLink,
										issueSummary,
										issueTypeIconUrl: issueTypeIconUrl || undefined,
										issueTypeName: issueTypeName || undefined,
									},
								},
							});

							break;
						}
						case ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS: {
							onChange({
								issueId,
								type: ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED,
								meta: action.payload,
							});

							break;
						}
						case FIELD_SAVE_REQUEST: {
							const {
								payload: { fieldId, value },
							} = action;

							issueViewFieldUpdateActions?.fieldChangeRequested(issueId, fieldId, value);

							break;
						}
						case FIELD_SAVE_FAILURE: {
							const {
								payload: { fieldId },
							} = action;

							issueViewFieldUpdateActions?.fieldChangeFailed(issueId, fieldId);

							break;
						}
						case FIELD_CONFIRM_NEW_FIELD: {
							const {
								payload: { fieldId, fieldValue },
							} = action;

							onChange({
								issueId,
								type: ChangeEventTypes.FIELD_CHANGE_REQUESTED,
								meta: {
									fieldId,
									fieldValue,
								},
							});

							break;
						}
						case CREATE_CONFLUENCE_PAGE_LINK_SUCCESS: {
							onChange({
								issueId,
								type: ChangeEventTypes.CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
							});

							break;
						}
						case DELETE_CONFLUENCE_PAGE_LINK_SUCCESS: {
							onChange({
								issueId,
								type: ChangeEventTypes.DELETE_CONFLUENCE_PAGE_LINK_SUCCESS,
							});

							break;
						}
						case FIELD_CONFIRM_FAILURE_NEW_FIELD: {
							const {
								payload: { fieldId },
							} = action;

							onChange({
								issueId,
								type: ChangeEventTypes.FIELD_CHANGE_FAILED,
								meta: {
									fieldId,
								},
							});

							break;
						}
						case CHILD_ISSUE_FIELD_UPDATED: {
							const {
								payload: { childIssueId },
							} = action;

							onChange({
								issueId,
								type: ChangeEventTypes.CHILD_ISSUE_FIELD_UPDATED,
								meta: {
									childIssueId,
								},
							});

							break;
						}
						default:
							break;
					}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					log.safeErrorWithoutCustomerData(
						'issue.issue-field.ops.change-notification',
						'Failed to call onChange callback',
						error,
					);
				}
			})
			.ignoreElements();
