import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals';
import { AccessNotPermittedError } from '@atlassian/jira-polaris-remote-view/src/common/errors/access-not-permitted-error.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { createViewAri } from '../../../../common/utils/ari';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_VIEWS_START,
	PAGE_LOAD_MARK_LOAD_VIEWS_END,
} from '../../../../common/utils/metrics/project';
import { initialState } from '../../constants';
import { createGetViewByAri } from '../../selectors/view';
import { hasViewUnsavedChanges } from '../../selectors/view/autosave/index.tsx';
import { getCurrentView } from '../../selectors/view/current/index.tsx';
import type { Props, State } from '../../types';
import { setDraft } from '../autosave';
import { loadViewComments } from '../comments/load-view-comments/index.tsx';
import { deleteViewFromState } from '../delete';
import { loadViewDescription } from '../description/load-view-description/index.tsx';
import { loadViewLastViewed } from '../last-viewed';
import { loadViewMarkers } from '../markers';
import { refreshIssueRanking } from '../refresh-view-rank';
import { setViewSets } from '../set';
import { setForceReloadViewOnNextUpdate } from '../set-force-reload';
import {
	setArrangementInformation,
	refreshArrangementInformation,
} from '../timeline/arrangement-information';
import { trackAtlasAnalytics, trackAtlasSiteAnalytics } from '../utils/atlas-analytics';
import { getAutosaveAffectedViewConfigurations } from '../utils/autosave';
import { checkIsViewCollapsed, findViewSet, findViewWithSlug } from '../utils/views';
import { isViewAriInViewSets, upsertView } from './utils';

const getCurrentViewWithFallback = (
	currentViewSlug: undefined | string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	viewSets: any,
): View | undefined => {
	const currentView = findViewWithSlug(viewSets, currentViewSlug);
	if (currentView !== undefined) {
		return currentView;
	}
	return viewSets[0]?.views[0];
};

export const loadViews =
	(isInit?: boolean): Action<State, Props> =>
	async (
		{ getState, setState, dispatch },
		{
			containerAri,
			fields,
			onViewLoadingFailed,
			hasNoProjectPermissions,
			currentViewSlug,
			viewRemote,
			isSharedView,
			isCollectionView,
			createAnalyticsEvent,
			cloudId,
		},
	) => {
		if (isInit) {
			setState(initialState);
		}

		if (hasNoProjectPermissions) {
			return;
		}

		// Bail early if we don't have what we need to load
		if (containerAri === undefined || fields === undefined || Object.keys(fields).length === 0) {
			return;
		}

		const fieldKeys = Object.keys(fields);

		const loadingProps = { containerAri, fieldKeys };
		const {
			forceReloadOnNextUpdate,
			meta: { loadingProps: previousLoadingProps },
		} = getState();

		// Only load again when the props this function depends on have changed
		// a) containerAri changed
		// b) field key set has changed. any other change to the field object should not trigger a backend load
		// c) views aren't loaded yet
		let { viewSets } = getState();
		if (
			!forceReloadOnNextUpdate &&
			containerAri === previousLoadingProps?.containerAri &&
			isShallowEqual(fieldKeys, previousLoadingProps?.fieldKeys) &&
			viewSets.length
		) {
			return;
		}

		try {
			// when user changes to another project, load the views
			// or when the project stays the same, the views are empty
			if (
				forceReloadOnNextUpdate ||
				containerAri !== previousLoadingProps?.containerAri ||
				!viewSets.length
			) {
				jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_VIEWS_START);
				setState({
					meta: { loading: true, error: undefined, loadingProps },
				});

				viewSets = await viewRemote.fetchAll(fields, currentViewSlug, checkIsViewCollapsed);
				jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_VIEWS_END);

				dispatch(setViewSets(viewSets));
				const currentView = getCurrentViewWithFallback(currentViewSlug, viewSets);
				const currentViewId = currentView?.viewId;
				const currentViewUUID = currentView?.uuid;

				if (currentView !== undefined && currentViewId !== undefined) {
					trackAtlasAnalytics(currentView, fields);
					trackAtlasSiteAnalytics(currentView, fields, cloudId, createAnalyticsEvent);
					dispatch(loadViewLastViewed(currentView));
					dispatch(refreshIssueRanking(currentViewId));
					if (!isSharedView) {
						dispatch(loadViewComments(currentViewId));
						dispatch(loadViewDescription(currentViewId));
					}
					if (currentView.kind === VIEW_KIND_TIMELINE) {
						// in collection view we get arrangement info with views data
						if (!isCollectionView) {
							dispatch(refreshArrangementInformation(currentViewId));
						}
						if (currentViewUUID) {
							dispatch(loadViewMarkers(currentViewUUID));
						}
					}
				}
				setState({
					meta: {
						...getState().meta,
						loading: false,
					},
				});

				if (forceReloadOnNextUpdate) {
					dispatch(setForceReloadViewOnNextUpdate(false));
				}

				return;
			}

			// update existing views in state with updated field data
			const resolvedFieldsByKey = keyBy(values(fields), ({ key }) => key);
			const updatedViewSets = viewSets.map((viewSet) => {
				const updatedViews = viewSet.views.map((view) => ({
					...view,
					filter: view.filter.filter(
						(f) =>
							f.type !== 'FIELD' ||
							resolvedFieldsByKey[f.field] !== undefined ||
							f.values.length === 0,
					),
					fields: view.fields.filter((fk) => resolvedFieldsByKey[fk] !== undefined),
					hidden: view.hidden.filter((fk) => resolvedFieldsByKey[fk] !== undefined),
					sortBy: view.sortBy?.filter(
						(s: SortField) => resolvedFieldsByKey[s.fieldKey] !== undefined,
					),
				}));
				return {
					...viewSet,
					views: updatedViews,
				};
			});

			setState({
				meta: {
					...getState().meta,
					loading: false,
				},
				viewSets: updatedViewSets,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_VIEWS_END);

			setState({
				meta: { loading: false, error, loadingProps: undefined },
				viewSets: [],
			});
			onViewLoadingFailed(error);
		}
	};

export const loadView =
	(
		id: number,
		realtimeView?: View,
		onSuccess?: () => void,
	): Action<State, Props, Promise<{ isDraftReset: boolean }>> =>
	async ({ getState, dispatch }, props) => {
		const state = getState();
		const { cloudId, viewRemote, onViewLoadingFailed, fields } = props;
		const viewAri = createViewAri(cloudId, id.toString());
		const existingView = createGetViewByAri(viewAri)(state);

		if (
			ff('polaris.view-permissions_plaoi') &&
			!realtimeView &&
			!isViewAriInViewSets(viewAri, state.viewSets)
		) {
			return {
				isDraftReset: false,
			};
		}

		try {
			const transformedView = realtimeView || (await viewRemote.fetchView(viewAri, fields));
			const viewToUpdate = createGetViewByAri(viewAri)(getState());

			if (fg('polaris_realtime-event-fix-read-timestamp')) {
				if (viewToUpdate?.updatedAtTimestamp && transformedView?.updatedAtTimestamp) {
					const viewUpdatedAt = new Date(viewToUpdate.updatedAtTimestamp);
					const transformedViewUpdatedAt = new Date(transformedView.updatedAtTimestamp);
					if (transformedViewUpdatedAt <= viewUpdatedAt) {
						return {
							isDraftReset: false,
						};
					}
				}
			} else if (viewToUpdate?.updatedAtTimestamp && realtimeView?.updatedAtTimestamp) {
				const viewUpdatedAt = new Date(viewToUpdate.updatedAtTimestamp);
				const transformedViewUpdatedAt = new Date(realtimeView.updatedAtTimestamp);
				if (transformedViewUpdatedAt <= viewUpdatedAt) {
					return {
						isDraftReset: false,
					};
				}
			}

			// do nothing if another update request has started for the view
			// to avoid updating the state with old configuration
			if (viewToUpdate?.saving) {
				return {
					isDraftReset: false,
				};
			}

			const { viewSets } = getState();
			const existingViewSet = findViewSet(
				viewSets,
				(viewSet) => viewSet.id === transformedView.viewSetId,
			);
			// do nothing if the view is created/updated in non-existing viewset
			// should be fixed once real-time events and separate queries for viewsets are introduced
			if (!existingViewSet) {
				return {
					isDraftReset: false,
				};
			}

			const updatedViewSets = viewSets.map((viewSet) => {
				if (viewSet.type === 'PRIORITIZE') {
					return {
						...viewSet,
						views:
							transformedView.viewSetId === viewSet.id
								? upsertView(viewSet.views, transformedView)
								: viewSet.views,
						viewSets: viewSet.viewSets?.map((subViewSet) => {
							if (transformedView.viewSetId === subViewSet.id) {
								return {
									...subViewSet,
									views: upsertView(subViewSet.views, transformedView),
								};
							}
							return subViewSet;
						}),
					};
				}
				return viewSet;
			});

			dispatch(setViewSets(updatedViewSets));

			onSuccess?.();

			const currentView = getCurrentView(state, props);
			const isCurrentViewUpdated = existingView && currentView?.id === existingView.id;
			const updatedView = createGetViewByAri(viewAri)(getState()); // needs new state since we updated it with setViewSets above

			const currentViewHasUnsavedChanges = hasViewUnsavedChanges(state, props);
			if (updatedView && isCurrentViewUpdated) {
				const currentViewConfigurations = getAutosaveAffectedViewConfigurations(existingView);
				const updatedViewConfigurations = getAutosaveAffectedViewConfigurations(updatedView);

				const hasConfigChanged =
					!isEqual(currentViewConfigurations, updatedViewConfigurations) ||
					existingView.isAutosaveEnabled !== updatedView.isAutosaveEnabled;

				if (currentViewHasUnsavedChanges && !hasConfigChanged) {
					// restoring existing unsaved changes (draft) in case if properties not related to autosave config have changed
					dispatch(setDraft(existingView.draft));
				}

				if (existingView.kind === VIEW_KIND_TIMELINE) {
					// restoring existing arrangementInformation
					dispatch(
						setArrangementInformation(viewAri, existingView.timelineConfig?.arrangementInformation),
					);
				}

				return {
					isDraftReset: currentViewHasUnsavedChanges && hasConfigChanged,
				};
			}
			// restoring existing unsaved changes (draft) for current view if loaded view was different
			if (currentView && currentViewHasUnsavedChanges) {
				dispatch(setDraft(currentView.draft));
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof ViewNotFoundError) {
				dispatch(deleteViewFromState(id));
			} else if (error instanceof AccessNotPermittedError) {
				if (ff('polaris.view-permissions_plaoi')) {
					dispatch(deleteViewFromState(id));
				} else {
					onViewLoadingFailed(error);
				}
			} else {
				onViewLoadingFailed(error);
			}
		}

		return {
			isDraftReset: false,
		};
	};
