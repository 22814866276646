import { COMMENT } from '@atlassian/jira-issue-view-configurations';
import type { IssueKey, AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getDraftKey = (loggedInAccountId: AccountId | null, issueKey: IssueKey, id: string) =>
	`${String(loggedInAccountId)}.${issueKey}.${id}`;

export const getFieldNameFromKey = (key: string) => key.split('.')[2];

export const createHashKey = (draftKey: string) => `${draftKey}-previous-hash`;

export const createCommentDraftKey = (commentId: string) => `${COMMENT}_${commentId}`;
