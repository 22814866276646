import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import deleteWorklog from '../../rest/delete-worklog';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	worklogId: string,
	timeRemaining: string,
	isAdjustTimeRemainingChecked: boolean,
): Promise<null> =>
	deleteWorklog(baseUrl, issueKey, worklogId, timeRemaining, isAdjustTimeRemainingChecked);
