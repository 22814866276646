import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import type { RemoteAssociatedIssueContextType } from '@atlassian/jira-associated-issues-context-service/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { isIssueViewWhiteboardsStandaloneEnabled } from '@atlassian/jira-issue-view-feature-flags';
import { APPLINK_AUTHENTICATION_SUCCESS } from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions';
import {
	fetchIssueRemoteDataSuccess,
	FETCH_ISSUE_REMOTE_DATA_REQUEST,
	fetchIssueRemoteDataFailure,
} from '@atlassian/jira-issue-view-store/src/actions/issue-remote-data-actions';
import { SAVE_REMOTE_LINKED_ISSUE_SUCCESS } from '@atlassian/jira-issue-view-store/src/actions/remote-issue-links-actions';
import {
	cloudIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import type { DataProvider } from '@atlassian/jira-providers-issue/src/model/data-provider.tsx';
import type { IssueRemoteData } from '@atlassian/jira-providers-issue/src/model/issue.tsx';

export const setAssociatedIssueContext = (
	issue: IssueRemoteData,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
) => {
	if (!associatedIssuesContextActions) {
		return;
	}

	// Update the associated issue context store with the isResolved state of the new linked issues
	const remoteLinkedIssues = issue.remoteLinks?.remoteLinkedIssues || [];
	const newLinkedIssuesContext: RemoteAssociatedIssueContextType = {};
	remoteLinkedIssues.forEach((remoteLinkedIssue) => {
		if (!!remoteLinkedIssue.details && 'associatedIssue' in remoteLinkedIssue.details) {
			const remoteLinkedIssueGlobalId = remoteLinkedIssue.preview.globalId;
			if (remoteLinkedIssueGlobalId) {
				Object.assign(newLinkedIssuesContext, {
					[remoteLinkedIssueGlobalId]: {
						isResolved: remoteLinkedIssue.details.associatedIssue.isResolved ?? false,
					},
				});
			}
		}
	});
	associatedIssuesContextActions.mergeRemoteAssociatedIssuesContext(newLinkedIssuesContext);
};

export const handleFetchIssueRemoteDataRequest =
	(dataProvider: DataProvider, associatedIssuesContextActions?: AssociatedIssuesContextActions) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
		// @ts-expect-error - TS2345 - Argument of type '(action: any) => Observable<{ type: "FETCH_ISSUE_REMOTE_DATA_SUCCESS"; payload: any; }> | Observable<{ type: "FETCH_ISSUE_REMOTE_DATA_FAILURE"; }>' is not assignable to parameter of type '(value: any, index: number) => ObservableInput<{ type: "FETCH_ISSUE_REMOTE_DATA_SUCCESS"; payload: any; }>'.
		action$.ofType(FETCH_ISSUE_REMOTE_DATA_REQUEST).switchMap((action) => {
			if (action.payload) {
				const issueKey = action.payload;
				const state = store.getState();
				const cloudId = cloudIdSelector(state);

				return dataProvider
					.getIssueRemoteData$(issueKey, cloudId, isIssueViewWhiteboardsStandaloneEnabled())
					.filter(() => issueKey === issueKeySelector(store.getState()))
					.do((issue) => {
						setAssociatedIssueContext(issue, associatedIssuesContextActions);
					})
					.flatMap((issue) => Observable.of(fetchIssueRemoteDataSuccess(issue)));
			}
			// if we don't get payload back from backend, still need to trigger action telling remote data has been loaded
			// so we can still smooth scroll.
			return Observable.of(fetchIssueRemoteDataFailure());
		});

export const handleRefreshIssueRemoteData =
	(dataProvider: DataProvider, associatedIssuesContextActions?: AssociatedIssuesContextActions) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(APPLINK_AUTHENTICATION_SUCCESS, SAVE_REMOTE_LINKED_ISSUE_SUCCESS)
			.switchMap(() => {
				const state = store.getState();
				const issueKey = issueKeySelector(state);
				const cloudId = cloudIdSelector(state);
				return issueKey
					? dataProvider
							.getIssueRemoteData$(issueKey, cloudId, isIssueViewWhiteboardsStandaloneEnabled())
							.filter(() => issueKey === issueKeySelector(store.getState()))
							.do((issue) => {
								setAssociatedIssueContext(issue, associatedIssuesContextActions);
							})
							.flatMap((issue) => Observable.of(fetchIssueRemoteDataSuccess(issue)))
					: Observable.empty<never>();
			});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	dataProvider: DataProvider,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
) =>
	combineEpics(
		handleFetchIssueRemoteDataRequest(dataProvider, associatedIssuesContextActions),
		handleRefreshIssueRemoteData(dataProvider, associatedIssuesContextActions),
	);
