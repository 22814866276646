import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_SUCCESS } from '../actions/fetch-recently-visited-confluence-pages-actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state: ConfluencePage[] = [], action: any) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_SUCCESS: {
			return payload;
		}

		default:
			return state;
	}
};
