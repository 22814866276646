import React, { useCallback, useEffect, useMemo, useState, type Ref } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import MemoryObserver from '@atlassian/jira-memory-metrics/src/ui/index.tsx';
import {
	UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
} from '@atlassian/jira-navigation-system';
import {
	FULLSCREEN_ZINDEX,
	PAGE_MARGIN_X,
} from '@atlassian/jira-polaris-common/src/common/constants';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useIsSharedViewAuthenticationMissing } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import { useCopyValuesTasks } from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { useGlobalFieldsUpdateSync } from '@atlassian/jira-polaris-common/src/controllers/global-fields/utils/index.tsx';
import { useIsSingleIssueLoaded } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import { useIsInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useIsRightSidebarOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	useCurrentPolarisRoute,
	useIsIssueOpenInFullscreen,
	useIsIssueOpenInSidebar,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks';
import {
	useHasSharedViewConfigError,
	useCurrentViewKind,
	useViewAnalyticsData,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { usePrioritizeViewCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types';
import { PolarisAppsOverview } from '@atlassian/jira-polaris-common/src/ui/apps';
import { MergeIdeasModal } from '@atlassian/jira-polaris-common/src/ui/merge-ideas-dialog';
import { CopyValuesProgress } from '@atlassian/jira-polaris-component-copy-values/src/ui/copy-values-progress/index.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { StandardTrialBanner, StandardTrialBannerContainer } from '../beta-banner';
import IdeaView from '../idea-view/async';
import { SmartLinkIdeaView } from '../idea-view/smartlink-idea-view/index.tsx';
import { RightSidebar } from '../right-sidebar/main.tsx';
import { PolarisProjectSettings } from '../settings';
import { SharedViewSignupHero } from '../shared-view-signup-hero';
import { VIEW_CONTENT_CONTAINER_ID } from '../view-content/idea-board/utils/use-board-export';
import { ViewContent } from '../view-content/main.tsx';
import { ViewControls } from '../view-controls';
import { ViewHeader } from '../view-header';
import { IssueLimitBanner } from './issue-limit-banner';
import { SubmitJpdProjectLoadedEvent } from './jpd-project-loaded-event';
import { NoViewsScreen } from './no-views-screen';
import { QuickstartViewVideoContainer } from './quick-start-view-video';
import { RealtimeEventsRefreshBanner } from './realtime-events-refresh-banner';

export type MainContentProps = {
	fullscreen: boolean;
	embedded: boolean;
	section: string;
	viewControlsRef: Ref<HTMLDivElement>;
	onToggleFullscreen: () => void;
};

const IdeaViewWithSelectedIdea = ({ embedded }: { embedded: boolean }) => {
	const selectedIssueKey = useSelectedIssueKey();
	return embedded ? (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style>{`
				[data-ds--page-layout--slot="main"] {
					height: auto;
				}

				body {
					overflow-y: auto;
				}

            `}</style>
			<SmartLinkIdeaView issueKey={selectedIssueKey} />
		</>
	) : (
		<IdeaViewContainer>
			<IdeaView issueKey={selectedIssueKey} />
		</IdeaViewContainer>
	);
};

const MainContent = ({
	fullscreen,
	embedded,
	section,
	viewControlsRef,
	onToggleFullscreen,
}: MainContentProps) => {
	const { resource } = useCurrentPolarisRoute();
	const [isViewContentReady, setIsViewContentReady] = useState(false);
	const isIssuesInitialized = useIsInitialized();
	const viewKind = useCurrentViewKind();
	const isSharedView = useIsSharedView();
	const isSharedViewAuthenticationMissing = useIsSharedViewAuthenticationMissing();
	const isSingleIssueLoaded = useIsSingleIssueLoaded();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const isViewsLoading = useIsViewsLoading();
	const viewCount = usePrioritizeViewCount();
	const copyValuesTasks = useCopyValuesTasks();

	useGlobalFieldsUpdateSync();

	const onViewContentReady = useCallback(() => setIsViewContentReady(true), []);

	const viewAnalyticsData = useViewAnalyticsData();

	const isIssueViewSidebarOpen = useIsIssueOpenInSidebar();

	const isRightSidebarOpen = useIsRightSidebarOpen()[0] || isIssueViewSidebarOpen;

	const isIssueViewFullscreenOpen = useIsIssueOpenInFullscreen();

	const isViewControlsHidden = isSharedView && hasSharedViewConfigError;

	const shouldDisplayPaddingRight = useCallback(() => {
		// This is for removing the whitespace between board and sidebar content. (see POL-3770) The padding condition should remain the same for all other use cases.
		if (viewKind === VIEW_KIND_BOARD && isRightSidebarOpen) {
			return false;
		}

		return viewKind !== VIEW_KIND_TABLE && viewKind !== VIEW_KIND_TIMELINE;
	}, [viewKind, isRightSidebarOpen]);

	useEffect(() => {
		if (!viewKind) return;
		experience.project.pageLoad.success();
		experience.project.pageLoadWithRateLimit.success();
	}, [viewKind]);

	useEffect(() => {
		if (!isIssueViewFullscreenOpen && section === ViewSectionTypeViews) return;
		onViewContentReady();
	}, [isIssueViewFullscreenOpen, section, onViewContentReady]);

	const attributes = useMemo(
		() => ({
			...viewAnalyticsData?.attributes,
			page: `polaris-project-${section}-${resource}`,
		}),
		[viewAnalyticsData?.attributes, section, resource],
	);

	return (
		<>
			<ContextualAnalyticsData attributes={attributes} containers={viewAnalyticsData?.containers}>
				<MainContentContainer isFullscreen={fullscreen}>
					{isIssueViewFullscreenOpen && (
						<LeftContainer isHidden={!isIssueViewFullscreenOpen}>
							<StandardTrialBanner />
							<IdeaViewWithSelectedIdea embedded={embedded} />
						</LeftContainer>
					)}
					<LeftContainer isHidden={isIssueViewFullscreenOpen}>
						{section === ViewSectionTypeViews && (
							<>
								{!isViewsLoading &&
								!isViewContentReady &&
								isIssuesInitialized &&
								viewCount === 0 ? (
									<NoViewsScreen />
								) : (
									<>
										{ff('polaris-published-views-login-info-screen') &&
										isSharedView &&
										isSharedViewAuthenticationMissing ? (
											<SharedViewSignupHero />
										) : (
											<>
												<IssueLimitBanner />
												<RealtimeEventsRefreshBanner />
												{!embedded && (
													<ViewControlsContainer
														ref={viewControlsRef}
														isFullWidth={fullscreen || isSharedView}
													>
														<StandardTrialBannerContainer>
															<ViewHeader
																isFullscreen={fullscreen}
																onToggleFullscreen={onToggleFullscreen}
															/>
														</StandardTrialBannerContainer>
														{!isViewControlsHidden && <ViewControls />}
													</ViewControlsContainer>
												)}
												<ViewContentContainer
													paddingRight={shouldDisplayPaddingRight()}
													// used by image export
													id={VIEW_CONTENT_CONTAINER_ID}
												>
													<ViewContent
														isHidden={isIssueViewFullscreenOpen}
														onReady={onViewContentReady}
													/>
													{
														// GALILEO-721
														fg('killswitch_jpd_onboarding_video') && (
															<ErrorBoundary
																id="jira.polaris.quick-start-view-video"
																packageName="jira.polaris.quick-start-view-video"
																// eslint-disable-next-line @typescript-eslint/no-explicit-any
																onError={(error?: any) => {
																	fireErrorAnalytics({
																		meta: {
																			id: 'QuickstartViewVideoContainer',
																			packageName: 'jiraPolarisIdeas',

																			teamName: 'galileo-growth',
																		},
																		attributes: {
																			errorContext:
																				'Failed rendering quick start view video container',
																			errorMessage: error.message,
																			source: 'QuickstartViewVideoContainer',
																		},
																		error,
																	});
																}}
															>
																<QuickstartViewVideoContainer />
															</ErrorBoundary>
														)
													}
												</ViewContentContainer>
											</>
										)}
									</>
								)}
							</>
						)}
						{section === 'apps' && <PolarisAppsOverview />}
						{section === 'settings' && (
							<StandardTrialBannerContainer>
								<PolarisProjectSettings />
							</StandardTrialBannerContainer>
						)}
						<CopyValuesProgress tasks={copyValuesTasks} />
					</LeftContainer>
					<MergeIdeasModal />
					{isRightSidebarOpen && !embedded && <RightSidebar isFullScreen={fullscreen} />}
				</MainContentContainer>
			</ContextualAnalyticsData>
			{isViewContentReady && (isIssuesInitialized || isSingleIssueLoaded) && (
				<SubmitJpdProjectLoadedEvent key="submit-jpd-project-loaded-event" />
			)}
			{isViewContentReady && isIssuesInitialized && <MemoryObserver />}
		</>
	);
};

export default MainContent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentContainer = styled.div<{ isFullscreen: boolean }>({
	display: 'flex',
	width: '100%',
	/* stylelint-disable-next-line function-whitespace-after */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isFullscreen }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isFullscreen ? `calc(100vh - ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT})` : '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: ({ isFullscreen }) => isFullscreen && 'absolute',
	/* stylelint-disable-next-line function-whitespace-after */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ isFullscreen }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isFullscreen && `calc(-1 * ${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: ({ isFullscreen }) => isFullscreen && FULLSCREEN_ZINDEX,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewControlsContainer = styled.div<{ isFullWidth: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	padding: `${token('space.050', '4px')} 0 0`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(100vw - var(--leftSidebarWidth, 0px))'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewContentContainer = styled.div<{ paddingRight: boolean }>({
	display: 'flex',
	flex: '1 1 auto',
	minHeight: 0,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingLeft: `${PAGE_MARGIN_X}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingRight: ({ paddingRight }) => paddingRight && `${PAGE_MARGIN_X}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftContainer = styled.div<{ isHidden: boolean }>({
	flex: '1 0 0px',
	flexDirection: 'column',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'flex'),
	maxWidth: '100%',
	minWidth: 0, // POL-5375 Fixes flex behavior
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaViewContainer = styled.div({
	width: '100%',
	height: '100%',
	maxWidth: '1600px',
	alignSelf: 'center',
});
