import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { RequestPanelMetadata } from '@atlassian/jira-issue-view-common-types/src/request-panel-metadata-type';
import {
	type FetchRequestPanelMetadata,
	requestPanelMetadataFetchFailure,
	requestPanelMetadataFetchSuccess,
	FETCH_REQUEST_PANEL_METADATA,
} from '@atlassian/jira-issue-view-store/src/actions/request-panel-metadata-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';

export const getUrl = (baseUrl: string, issueKey: string): string =>
	`${baseUrl}/rest/servicedesk/1/servicedesk/request/${issueKey}/request-panel-metadata`;

const fetchRequestPanelMetadata = (state: State) =>
	fetchJson$(getUrl(baseUrlSelector(state), issueKeySelector(state)));

const handleSuccess = (result: RequestPanelMetadata) =>
	Observable.of(requestPanelMetadataFetchSuccess(result));

const handleFailure = (error: Error) => {
	log.safeErrorWithoutCustomerData('issue.request-panel-metadata.fetch', error.message, error);
	return Observable.of(requestPanelMetadataFetchFailure());
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<FetchRequestPanelMetadata>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_REQUEST_PANEL_METADATA).flatMap(() =>
		fetchRequestPanelMetadata(store.getState())
			// @ts-expect-error - TS2345 - Argument of type '(result: unknown) => Observable<{ type: "REQUEST_PANEL_METADATA_FETCH_SUCCESS"; payload: { channel: "unknown" | "jira" | "email" | "api" | "deployment" | "portal" | "anonymousportal"; requestUrl: string | undefined; csatComment: string | undefined; }; }> | Observable<...>' is not assignable to parameter of type '(value: unknown, index: number) => ObservableInput<{ type: "REQUEST_PANEL_METADATA_FETCH_SUCCESS"; payload: { channel: "unknown" | "jira" | "email" | "api" | "deployment" | "portal" | "anonymousportal"; requestUrl: string | undefined; csatComment: string | undefined; }; }>'.
			.flatMap((result) => {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				if (result.errors) {
					// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Error'.
					return handleFailure(result);
				}

				// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'RequestPanelMetadata'.
				return handleSuccess(result);
			})
			.catch((error) => handleFailure(error)),
	);
