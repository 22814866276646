import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import {
	performPutRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { ISSUE_CONTENT_PANEL_CUSTOMISED } from './ecosystem-property-keys';

const getURL = (baseUrl: BaseUrl, issueKey: IssueKey, addonModuleKey: string) =>
	`${baseUrl}/rest/api/2/issue/${issueKey}/properties/${addonModuleKey}`;

export const saveExtensionToIssue = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	addonModuleKey: string,
) => {
	const url = getURL(baseUrl, issueKey, addonModuleKey);
	return Observable.fromPromise(
		performPutRequest(url, {
			body: JSON.stringify({ added: addonModuleKey }),
		}),
	);
};

export const removeExtensionFromIssue = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	addonModuleKey: string,
) => {
	const url = getURL(baseUrl, issueKey, addonModuleKey);
	return Observable.fromPromise(performDeleteRequest(url));
};

export const saveContentPanelsCustomisedOnIssue = (baseUrl: BaseUrl, issueKey: IssueKey) => {
	const url = getURL(baseUrl, issueKey, ISSUE_CONTENT_PANEL_CUSTOMISED);
	return Observable.fromPromise(
		performPutRequest(url, {
			body: JSON.stringify({ createdOn: new Date() }),
		}),
	);
};
