import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { GIVE_FEEDBACK } from '@atlassian/jira-issue-view-common-constants/src/view-constants';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { IssueViewSecurityLevelField } from '@atlassian/jira-issue-view-layout-security-level-field/src/ui/index.tsx';
import type { FeedbackRenderer } from '@atlassian/jira-issue-view-model/src/feedback-type';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { applicationKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { Watchers } from '@atlassian/jira-issue-view-watchers/src/header';
import type { headerActions_issueViewFoundation_HeaderActionsView$key } from '@atlassian/jira-relay/src/__generated__/headerActions_issueViewFoundation_HeaderActionsView.graphql';
import { AsyncIssueActions } from '../../issue-actions/async';
import type { IssueDeleteCallbacks } from '../../issue-actions/delete-issue/types';
import ModalActionsCloseButton from '../../modal-close-button';
import { IssueRestriction } from '../issue-restriction';
import { SecurityLevelIcon } from '../security-level';
import { ShareButton } from '../share-button';
import { Voters } from '../voters';
import { RightAligned } from './styled';

type HeaderActionsViewProps = {
	issueDeleteCallbacks?: IssueDeleteCallbacks;
	shouldShowCloseButton?: boolean;
	error?: string | null;
	applicationKey: string;
	renderFeedback?: FeedbackRenderer;
	viewModeOptions?: ViewModeOptions;
	onClose?: () => void;
	headerActions?: headerActions_issueViewFoundation_HeaderActionsView$key | null;
};

export const HeaderActionsView = ({
	onClose = noop,
	renderFeedback = () => null,
	shouldShowCloseButton = false,
	issueDeleteCallbacks = {},
	viewModeOptions = { viewModeSwitchEnabled: false },
	applicationKey,
	headerActions,
}: HeaderActionsViewProps) => {
	const data =
		ff('relay-migration-issue-fields-security-level_7y5uv') && headerActions
			? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
				useFragment<headerActions_issueViewFoundation_HeaderActionsView$key>(
					graphql`
						fragment headerActions_issueViewFoundation_HeaderActionsView on JiraIssue {
							...ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField
						}
					`,
					headerActions,
				)
			: null;

	let feedbackComponent;
	if (renderFeedback) {
		const feedback = renderFeedback(applicationKey, true);
		if (feedback != null) {
			feedbackComponent = (
				<SpotlightTarget name={GIVE_FEEDBACK}>
					<FeedbackWrapper>{feedback}</FeedbackWrapper>
				</SpotlightTarget>
			);
		}
	}
	return (
		<HeaderWrapper>
			<UnselectableRightAligned>
				{feedbackComponent}
				{ff('relay-migration-issue-fields-security-level_7y5uv') && data ? (
					<IssueViewSecurityLevelField securityLevelField={data} />
				) : (
					<SecurityLevelIcon
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						data-testid="issue.views.issue-base.foundation.header.header-actions.security-level-icon"
					/>
				)}
				<IssueRestriction />
				<Watchers />
				<Voters />
				<ShareButton />
				<AsyncIssueActions
					issueDeleteCallbacks={issueDeleteCallbacks}
					viewModeOptions={viewModeOptions}
					onClose={onClose}
				/>
				{shouldShowCloseButton ? <ModalActionsCloseButton onClose={onClose} /> : null}
			</UnselectableRightAligned>
		</HeaderWrapper>
	);
};
HeaderActionsView.displayName = 'HeaderActionsView';

export const HeaderActions = flowWithSafeComponent(
	connect(
		(state: State) => ({
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			applicationKey: applicationKeySelector(state)!,
		}),
		{},
	),
)(HeaderActionsView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 4}px`,
	marginRight: 0,
	marginLeft: 'auto',
	marginBottom: token('space.025', '2px'),
	height: '100%',
	alignItems: 'center',
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnselectableRightAligned = styled(RightAligned)({
	userSelect: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackWrapper = styled.div({
	flexShrink: 0,
});
