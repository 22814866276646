import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import uniqWith from 'lodash/uniqWith';
import values from 'lodash/values';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { STATUS_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	DELIVERY_CALCULATION_MODE,
	DELIVERY_CALCULATION_STRATEGIES,
} from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { Props, State } from '../types';
import { archivedMapping } from '../utils/field-mapping/archived';
import { fieldMapping } from '../utils/field-mapping/index.tsx';
import { numberMapping } from '../utils/field-mapping/number';
import { stringMapping } from '../utils/field-mapping/string';
import type { FieldMapping } from '../utils/field-mapping/types';

export type FieldMappings<TMappingType> = Record<FieldKey, FieldMapping<TMappingType>>;

const NO_FIELDS: Array<Field> = [];

export const getFields = createSelector(
	(_state: State, props: Props | undefined) => props?.fields,
	(fields?: Field[]) => {
		if (!fields) {
			return NO_FIELDS;
		}
		return fields.filter((field) => {
			const isHidden = field.configuration?.hidden;
			const isRestricted = field.hasRestrictedContext;
			return !isHidden && !isRestricted;
		});
	},
);

const getAllFields = createSelector(
	(_state: State, props: Props | undefined) => props?.fields,
	(fields?: Field[]) => fields || NO_FIELDS,
);

export const getAllFieldsByKey = createSelector(getAllFields, (fields) =>
	keyBy(fields, (field) => field.key),
);

export const createGetFieldOfType = (type: FieldType) =>
	createSelector(getFields, (fields) => fields.find((field) => field.type === type));

export const getFieldKeys = createSelector(
	getFields,
	(_: State, props: Props) => props.rankField,
	(fields, rankField) =>
		fields
			?.map((field) => field.key)
			.concat(rankField || '')
			.filter((str) => str.length > 0) || [],
);

export const createGetField = (fieldKey?: FieldKey) =>
	createSelector(getAllFieldsByKey, (fields) =>
		fieldKey !== undefined ? fields[fieldKey] : undefined,
	);

export const createGetFieldOptions = (fieldKey?: FieldKey) => {
	const getField = createGetField(fieldKey);

	return createSelector(
		getField,
		(_, props: Props | undefined) => props,
		(field) => field?.options,
	);
};

export const createGetFieldOptionsWithAriResolved = cacheSelectorCreator((fieldKey?: FieldKey) => {
	const getFieldOptions = createGetFieldOptions(fieldKey);

	return createSelector(getFieldOptions, (options?: FieldOption[]): OptionProperty[] => {
		if (options === undefined) {
			return [];
		}
		return options.reduce<OptionProperty[]>(
			(agg, { jiraOptionId, value, weight }) => [
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...agg,
				{
					id: jiraOptionId,
					value,
					weight,
				},
			],
			[],
		);
	});
});

export const createGetFieldOptionsWithAriResolvedById = cacheSelectorCreator(
	(fieldKey?: FieldKey) => {
		const getGetFieldOptionsWithAriResolved = createGetFieldOptionsWithAriResolved(fieldKey);

		return createSelector(getGetFieldOptionsWithAriResolved, (options) =>
			options.length ? keyBy(options, ({ id }) => id) : undefined,
		);
	},
);

export const createGetGenericFieldMappings = <TMappingType,>(
	mappingFactory: (issuesRemote: IssuesRemote, arg1: Field) => FieldMapping<TMappingType>,
	fieldTypesCondition: FieldType[],
): ((arg1: State, arg2: Props | undefined) => FieldMappings<TMappingType>) =>
	createSelector(
		getFields,
		// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
		({ containerProps }) => containerProps?.issuesRemote!,
		(fields: Field[], issuesRemote: IssuesRemote) =>
			fields
				.filter(({ type }) => fieldTypesCondition.includes(type)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.reduce<Record<string, any>>(
					(agg, field) => ({
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...agg,
						[field.key]: mappingFactory(issuesRemote, field),
					}),
					{},
				),
	);

export const getArchivedFieldMappings = createGetGenericFieldMappings<OptionFieldValue>(
	archivedMapping,
	[FIELD_TYPES.ARCHIVED],
);

export const getNumberFieldMappings = createGetGenericFieldMappings<number>(numberMapping, [
	FIELD_TYPES.CHECKBOX,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.NUMBER,
	FIELD_TYPES.RATING,
	FIELD_TYPES.SLIDER,
]);

export const getStringFieldMappings = createGetGenericFieldMappings<string>(stringMapping, [
	FIELD_TYPES.SHORT_TEXT,
	FIELD_TYPES.SUMMARY,
	FIELD_TYPES.HYPERLINK,
	FIELD_TYPES.CREATED,
	FIELD_TYPES.UPDATED,
	FIELD_TYPES.DATE,
]);

export const getFieldMappings = createSelector(
	getAllFields,
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	({ containerProps }) => containerProps?.issuesRemote!,
	(fields: Field[], issuesRemote) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fields.reduce<Record<string, FieldMapping<any>>>(
			(agg, field) => ({
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...agg,
				[field.key]: fieldMapping(issuesRemote, fields, field),
			}),
			{},
		),
);

/**
 * Filters out fields that are hidden or context-restricted
 */
export const getVisibleFieldMappings = createSelector(
	getAllFields,
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	({ containerProps }) => containerProps?.issuesRemote!,
	(fields: Field[], issuesRemote) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fields.reduce<Record<string, FieldMapping<any>>>((agg, field) => {
			const isHidden = field?.configuration?.hidden;
			const isRestricted = field?.hasRestrictedContext;
			if (isHidden || isRestricted) {
				return agg;
			}
			return {
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...agg,
				[field.key]: fieldMapping(issuesRemote, fields, field),
			};
		}, {}),
);

export const getFieldMappingsAsList = createSelector(getFieldMappings, values);

export const createGetFieldMapping = (fieldKey?: FieldKey) =>
	createSelector(getFieldMappings, (fieldMappings) =>
		fieldKey !== undefined ? fieldMappings[fieldKey] : undefined,
	);

export const getAllFieldValues = (fieldKey?: FieldKey) =>
	createSelector(
		createGetFieldMapping(fieldKey),
		(state: State, props?: Props) => ({ state, props }),
		(mapping, { state, props }) =>
			mapping ? Object.values(mapping.getAllValues(state, props)).filter(Boolean) : [],
	);

export const getIssuesStatusById = createSelector(
	(state: State) => state.properties.status[STATUS_FIELDKEY],
	(statusProperties): Record<string, StatusFieldValue> =>
		keyBy(
			Object.values(statusProperties ?? {}).filter((status) => !!status),
			'id',
		),
);

export const getSortedDistinctIssueStatuses = createSelector(
	createGetFieldMapping(STATUS_FIELDKEY),
	getIssuesStatusById,
	(mapping, issuesStatusById) =>
		mapping
			? uniqWith(
					Object.values(issuesStatusById),
					(x, y) => mapping.comparator(x, y, 'ASC') === 0,
				).sort((x, y) => mapping.comparator(x, y, 'ASC'))
			: [],
);

export const getIssuesSimiliarStatusIdsByStatusId = createSelector(
	createGetFieldMapping(STATUS_FIELDKEY),
	getIssuesStatusById,
	(mapping, statusesById): Record<string, string[]> => {
		if (!mapping) return {};
		const statuses = Object.values(statusesById);

		const isStatusEqual = (x: StatusFieldValue) => (y: StatusFieldValue) =>
			mapping.comparator(x, y, 'ASC') === 0;

		const similarStatusesIdsBystatusId = mapValues(statusesById, (status) =>
			statuses.filter(isStatusEqual(status)).map(({ id }) => id),
		);

		return similarStatusesIdsBystatusId;
	},
);

export const createGetFieldLabel = <TFieldValue,>(
	fieldKey: FieldKey,
	groupIdentity?: string,
	fieldValue?: TFieldValue,
) => {
	const getFieldMapping = createGetFieldMapping(fieldKey);

	return createSelector(getFieldMapping, (mapping) =>
		groupIdentity !== undefined ? mapping?.getLabel(groupIdentity, fieldValue) : undefined,
	);
};

export const createGetIsWeightedField = (fieldKey: FieldKey) => {
	const getFieldOptionsWithAriResolved = createGetFieldOptionsWithAriResolved(fieldKey);

	return createSelector(getFieldOptionsWithAriResolved, (options = []) =>
		options.some((option) => option.weight !== 1),
	);
};

export const getDeliveryStatusField = createSelector(getFields, (fields) =>
	fields?.find((field) => field.type === FIELD_TYPES.DELIVERY_STATUS),
);

export const getDeliveryCalculationStrategy = createSelector(
	getDeliveryStatusField,
	(field) => field?.presentation?.type || DELIVERY_CALCULATION_STRATEGIES.DEFAULT,
);

export const getDeliveryCalculationMode = createSelector(
	getDeliveryStatusField,
	(field) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(field?.formula as LinkedIssuesFormula)?.parameters?.calculationMode ||
		DELIVERY_CALCULATION_MODE.ISSUE_COUNT,
);
