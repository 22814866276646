import React, { type ComponentType } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import IssueMeatballMenu from '@atlassian/jira-issue-meatball-menu/src/ui/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { OwnProps as Props } from './types';

const LazyIssueActionsMenu = lazyAfterPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-issue-actions-menu" */ './index'),
);

export const AsyncIssueActions = (props: Props) => (
	<Placeholder
		name="async-issue-actions-menu"
		fallback={<IssueMeatballMenu isDisabled getMeatballMenuData={() => []} />}
	>
		<LazyIssueActionsMenu {...props} />
	</Placeholder>
);
