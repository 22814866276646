import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	explanation: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.explanation',
		defaultMessage:
			'When copying this project field value, the different values added to the project ideas, will be copied into the new global field. <a>More on copying fields.</a>',
		description: 'Explanation of the feature',
	},
	copyFrom: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.copy-from',
		defaultMessage: 'Copy values from this project field:',
		description: 'Field to copy the values from',
	},
	copyTo: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.copy-to',
		defaultMessage: 'To a global field:',
		description: 'Global field to copy the values to',
	},
	selectGlobalFieldLabel: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.select-global-field-label',
		defaultMessage: 'Select global field',
		description: 'Label for Global field dropdown',
	},
	field: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.field',
		defaultMessage: 'field',
		description: 'Field type suffix',
	},
	selectField: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.select-field',
		defaultMessage: 'Select field',
		description: 'Placeholder for global field selection',
	},
	selectGlobalField: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.select-global-field',
		defaultMessage: 'Select a global field',
		description: 'Label for global field selection',
	},
	copyValues: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.copy-values',
		defaultMessage: 'Copy values',
		description: 'Label of the button that copies values of a field to a global field',
	},
});
