import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { Grid, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldValueDecorations } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	DRAWER,
} from '@atlassian/jira-product-analytics-bridge';
import { CopyValuesContextProvider } from '../../controllers';
import { COPY_VALUES_FORM_SUBMIT_BUTTON_PORTAL_ID, CopyValuesForm } from './copy-values-form';
import { DONT_COPY_OPTION_ID } from './copy-values-form/options-mapping/options-mapping-modal/field-options-mapper/field-option-selector';
import messages from './messages';

type CopyValuesSidebarProps = {
	onCopyValues: (
		fromFieldKey: FieldKey,
		toFieldKey: FieldKey,
		fieldOptionsValueMapping: Record<string, string | undefined>,
	) => Promise<void>;
	onClose: () => void;
	field: Field;
	fieldValueDecorations: FieldValueDecorations;
	globalFields: Field[];
	canManageGlobalFields: boolean;
};

export const CopyValuesSidebar = ({
	onCopyValues,
	onClose,
	field,
	globalFields,
	canManageGlobalFields,
	fieldValueDecorations,
}: CopyValuesSidebarProps) => {
	const { formatMessage } = useIntl();
	const { error } = useNotifications();

	const handleCopyValues = useCallback(
		async (
			fromFieldKey: FieldKey,
			toFieldKey: FieldKey,
			optionsValueMapping: Record<string, string | undefined>,
		) => {
			try {
				await onCopyValues(
					fromFieldKey,
					toFieldKey,
					prepareOptionValuesMapping(optionsValueMapping),
				);
				onClose();
			} catch (err) {
				error({
					title: formatMessage(messages.copyValuesErrorTitle),
					description: formatMessage(messages.copyValuesErrorDescription),
				});
			}
		},
		[onCopyValues, error, formatMessage, onClose],
	);

	return (
		<CopyValuesContextProvider
			sourceField={field}
			globalFields={globalFields}
			fieldValueDecorations={fieldValueDecorations}
			canManageGlobalFields={canManageGlobalFields}
		>
			<ContextualAnalyticsData sourceName="copyValues" sourceType={DRAWER}>
				<FireScreenAnalytics />
				<Stack xcss={sidebarContainerStyles}>
					<RightSidebarHeader
						title={formatMessage(messages.copyValues)}
						onBack={onClose}
						backLabel={formatMessage(messages.backToSettingsLabel)}
						onClose={onClose}
					/>
					<RightSidebarBody>
						<CopyValuesForm onCopyValues={handleCopyValues} />
					</RightSidebarBody>
					<RightSidebarFooter>
						<Grid gap="space.100" templateColumns="1fr 1fr">
							<Button onClick={onClose}>{formatMessage(messages.cancel)}</Button>
							<div id={COPY_VALUES_FORM_SUBMIT_BUTTON_PORTAL_ID} />
						</Grid>
					</RightSidebarFooter>
				</Stack>
			</ContextualAnalyticsData>
		</CopyValuesContextProvider>
	);
};

const sidebarContainerStyles = xcss({
	height: '100%',
	paddingBlockStart: 'space.300',
});

const prepareOptionValuesMapping = (mapping: Record<string, string | undefined>) => {
	const preprocessedMapping: Record<string, string | undefined> = {};

	for (const [fromOptionId, toOptionId] of Object.entries(mapping)) {
		preprocessedMapping[fromOptionId] = toOptionId === DONT_COPY_OPTION_ID ? undefined : toOptionId;
	}

	return preprocessedMapping;
};
