import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { ServerRestUser } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	transformUser,
	validateUser,
} from '@atlassian/jira-issue-view-services/src/issue/user-transformer';
import {
	FETCH_LOGGED_IN_USER_REQUEST,
	fetchLoggedInUserFailure,
	fetchLoggedInUserSuccess,
	type UserFetchAction,
} from '@atlassian/jira-issue-view-store/src/actions/user-fetch-actions';
import {
	accountIdloggedInUserSelector,
	baseUrlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { getUserUrl } from './user-urls';

export const LOG_LOCATION = 'issue.fetch.user-fetch-epic';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<UserFetchAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(FETCH_LOGGED_IN_USER_REQUEST).switchMap(() => {
		const state: State = store.getState();

		const loggedInAccountId = accountIdloggedInUserSelector(state);
		if (!loggedInAccountId) return Observable.empty<never>();
		const baseUrl = baseUrlSelector(state);
		const url = getUserUrl(baseUrl, loggedInAccountId);

		return fetchJson$<ServerRestUser | null>(url)
			.map(validateUser)
			.map((serverUser: ServerRestUser) => {
				const loggedInUserSuccessAction = transformUser(serverUser);
				return fetchLoggedInUserSuccess(loggedInUserSuccessAction);
			})
			.catch((error) => {
				trackOrLogClientError(
					LOG_LOCATION,
					'FETCH_LOGGED_IN_USER_REQUEST: Failed to fetch logged in user details',
					error,
				);
				return Observable.of(fetchLoggedInUserFailure());
			});
	});
