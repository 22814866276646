import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { WorklogMap } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { FormValues as EditModalValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type';
import type {
	WorklogModal,
	TimeTrackingDeltas,
} from '@atlassian/jira-issue-view-common-types/src/worklog-type';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

export const FETCH_WORKLOGS_REQUEST = 'state.FETCH_WORKLOGS_REQUEST' as const;
export const fetchWorklogsRequest = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: FETCH_WORKLOGS_REQUEST,
	payload: { baseUrl, issueKey },
	meta: { analyticsEvent },
});
export type FetchWorklogsRequestAction = ReturnType<typeof fetchWorklogsRequest>;

export const FETCH_WORKLOGS_REQUEST_INITIAL = 'state.FETCH_WORKLOGS_REQUEST_INITIAL' as const;
export const fetchWorklogsRequestInitial = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: FETCH_WORKLOGS_REQUEST_INITIAL,
	payload: { baseUrl, issueKey },
	meta: { analyticsEvent },
});
export type FetchWorklogsRequestInitialAction = ReturnType<typeof fetchWorklogsRequestInitial>;

type SuccessMeta = {
	shouldFetchViewContext: boolean;
};

export const FETCH_WORKLOGS_SUCCESS = 'state.FETCH_WORKLOGS_SUCCESS' as const;
export const fetchWorklogsSuccess = (
	sortedWorklogIds: string[],
	worklogs: WorklogMap,
	totalWorklogs: number,
) => ({
	type: FETCH_WORKLOGS_SUCCESS,
	payload: { sortedWorklogIds, worklogs, totalWorklogs },
});
export type FetchWorklogsSuccessAction = ReturnType<typeof fetchWorklogsSuccess>;

export const FETCH_WORKLOGS_FAILURE = 'state.FETCH_WORKLOGS_FAILURE' as const;
export const fetchWorklogsFailure = () => ({ type: FETCH_WORKLOGS_FAILURE });
export type FetchWorklogsFailureAction = ReturnType<typeof fetchWorklogsFailure>;

export const ADD_WORKLOG_REQUEST = 'state.ADD_WORKLOG_REQUEST' as const;
export const addWorklogRequest = (values: EditModalValues, analyticsEvent: UIAnalyticsEvent) => ({
	type: ADD_WORKLOG_REQUEST,
	payload: { values },
	meta: { analyticsEvent },
});
export type AddWorklogRequestAction = ReturnType<typeof addWorklogRequest>;

export const ADD_WORKLOG_SUCCESS = 'state.ADD_WORKLOG_SUCCESS' as const;

export const addWorklogSuccess = (
	worklogId: string,
	timeTracking: TimeTrackingState,
	meta: SuccessMeta,
	issueKey: IssueKey,
) => ({
	type: ADD_WORKLOG_SUCCESS,
	payload: { worklogId, timeTracking, issueKey },
	meta,
});
export type AddWorklogSuccessAction = ReturnType<typeof addWorklogSuccess>;

export const FETCH_SURROUNDING_WORKLOGS_BY_ID = 'state.FETCH_SURROUNDING_WORKLOGS_BY_ID' as const;
export const fetchSurroundingWorklogsById = (worklogId: string) => ({
	type: FETCH_SURROUNDING_WORKLOGS_BY_ID,
	payload: { worklogId },
});
export type FetchSurroundingWorklogsByIdAction = ReturnType<typeof fetchSurroundingWorklogsById>;

export const FETCH_WORKLOGS_BY_ID_SUCCESS = 'state.FETCH_WORKLOGS_BY_ID_SUCCESS' as const;
export const fetchWorklogsByIdSuccess = (
	sortedWorklogIds: string[],
	worklogs: WorklogMap,
	startIndex: number,
	totalWorklogs: number,
) => ({
	type: FETCH_WORKLOGS_BY_ID_SUCCESS,
	payload: { sortedWorklogIds, worklogs, startIndex, totalWorklogs },
});
export type FetchWorklogsByIdSuccessAction = ReturnType<typeof fetchWorklogsByIdSuccess>;

export const FETCH_WORKLOGS_BY_ID_FAILURE = 'state.FETCH_WORKLOGS_BY_ID_FAILURE' as const;
export const fetchWorklogsByIdFailure = () => ({ type: FETCH_WORKLOGS_BY_ID_FAILURE });
export type FetchWorklogsByIdFailureAction = ReturnType<typeof fetchWorklogsByIdFailure>;

export const ADD_WORKLOG_FAILURE = 'state.ADD_WORKLOG_FAILURE' as const;
export const addWorklogFailure = ({ invalidMessage }: { invalidMessage?: string } = {}) => ({
	type: ADD_WORKLOG_FAILURE,
	payload: { invalidMessage },
});
export type AddWorklogFailureAction = ReturnType<typeof addWorklogFailure>;

export const UPDATE_TIME_REMAINING_REQUEST = 'state.UPDATE_TIME_REMAINING_REQUEST' as const;
export const updateTimeRemainingRequest = (
	timeRemaining: string,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: UPDATE_TIME_REMAINING_REQUEST,
	payload: { timeRemaining },
	meta: { analyticsEvent },
});
export type UpdateTimeRemainingRequestAction = ReturnType<typeof updateTimeRemainingRequest>;

export const UPDATE_TIME_REMAINING_SUCCESS = 'state.UPDATE_TIME_REMAINING_SUCCESS' as const;
export const updateTimeRemainingSuccess = (timeRemaining: number | null) => ({
	type: UPDATE_TIME_REMAINING_SUCCESS,
	payload: { timeRemaining },
});
export type UpdateTimeRemainingSuccessAction = ReturnType<typeof updateTimeRemainingSuccess>;

export const UPDATE_TIME_REMAINING_FAILURE = 'state.UPDATE_TIME_REMAINING_FAILURE' as const;
export const updateTimeRemainingFailure = () => ({
	type: UPDATE_TIME_REMAINING_FAILURE,
});
export type UpdateTimeRemainingFailureAction = ReturnType<typeof updateTimeRemainingFailure>;

export const OPEN_MODAL = 'state.OPEN_MODAL' as const;
export const openModal = (modalType: WorklogModal, worklogId: string | null) => ({
	type: OPEN_MODAL,
	payload: { modalType, worklogId },
});
export type OpenModalAction = ReturnType<typeof openModal>;

export const CLOSE_MODAL = 'state.CLOSE_MODAL' as const;
export const closeModal = () => ({ type: CLOSE_MODAL });
export type CloseModalAction = ReturnType<typeof closeModal>;

export const EDIT_WORKLOG_REQUEST = 'state.EDIT_WORKLOG_REQUEST' as const;
export const editWorklogRequest = (
	worklogId: string,
	values: EditModalValues,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: EDIT_WORKLOG_REQUEST,
	payload: { worklogId, values },
	meta: { analyticsEvent },
});
export type EditWorklogRequestAction = ReturnType<typeof editWorklogRequest>;

export const EDIT_WORKLOG_SUCCESS = 'state.EDIT_WORKLOG_SUCCESS' as const;

export const editWorklogSuccess = (
	worklogs: WorklogMap,
	worklogId: string,
	timeTrackingDeltas: TimeTrackingDeltas,
	meta: SuccessMeta,
) => ({
	type: EDIT_WORKLOG_SUCCESS,
	payload: { worklogs, worklogId, timeTrackingDeltas },
	meta,
});
export type EditWorklogSuccessAction = ReturnType<typeof editWorklogSuccess>;

export const EDIT_WORKLOG_FAILURE = 'state.EDIT_WORKLOG_FAILURE' as const;
export const editWorklogFailure = () => ({
	type: EDIT_WORKLOG_FAILURE,
});
export type EditWorklogFailureAction = ReturnType<typeof editWorklogFailure>;

export const DELETE_WORKLOG_REQUEST = 'state.DELETE_WORKLOG_REQUEST' as const;
export const deleteWorklogRequest = (
	worklogId: string,
	timeRemaining: string,
	isAdjustTimeRemainingChecked: boolean,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: DELETE_WORKLOG_REQUEST,
	payload: { worklogId, timeRemaining, isAdjustTimeRemainingChecked },
	meta: { analyticsEvent },
});
export type DeleteWorklogRequestAction = ReturnType<typeof deleteWorklogRequest>;

export const DELETE_WORKLOG_SUCCESS = 'state.DELETE_WORKLOG_SUCCESS' as const;
export const deleteWorklogSuccess = (
	worklogId: string,
	timeTrackingDeltas: TimeTrackingDeltas,
) => ({
	type: DELETE_WORKLOG_SUCCESS,
	payload: { worklogId, timeTrackingDeltas },
});
export type DeleteWorklogSuccessAction = ReturnType<typeof deleteWorklogSuccess>;

export const DELETE_WORKLOG_FAILURE = 'state.DELETE_WORKLOG_FAILURE' as const;
export const deleteWorklogFailure = () => ({ type: DELETE_WORKLOG_FAILURE });
export type DeleteWorklogFailureAction = ReturnType<typeof deleteWorklogFailure>;

export const DISPOSE_WORKLOG_APP = 'state.DISPOSE_WORKLOG_APP' as const;
export const disposeWorklogApp = () => ({ type: DISPOSE_WORKLOG_APP });
export type DisposeWorklogAppAction = ReturnType<typeof disposeWorklogApp>;

// SET_WORKLOG_SCROLL_STATUS
export const SET_WORKLOG_SCROLL_STATUS = 'SET_WORKLOG_SCROLL_STATUS' as const;

export type SetWorklogScrollStatus = {
	type: typeof SET_WORKLOG_SCROLL_STATUS;
	payload: {
		status: boolean;
	};
};

export const setWorklogScrollStatus = (status: boolean): SetWorklogScrollStatus => ({
	type: SET_WORKLOG_SCROLL_STATUS,
	payload: {
		status,
	},
});

// BENTO-6520 - Activity sorting
export const FETCH_SORTED_WORKLOGS_REQUEST = 'state.FETCH_SORTED_WORKLOGS_REQUEST' as const;
export const fetchSortedWorklogsRequest = () => ({
	type: FETCH_SORTED_WORKLOGS_REQUEST,
});
export type FetchSortedWorklogsRequestAction = ReturnType<typeof fetchSortedWorklogsRequest>;

export const FETCH_SORTED_WORKLOGS_SUCCESS = 'state.FETCH_SORTED_WORKLOGS_SUCCESS' as const;
export const fetchSortedWorklogsSuccess = (payload: {
	worklogs: WorklogMap;
	totalWorklogs: number;
	startIndex: number;
	sortedWorklogIds: string[];
}) => ({
	payload,
	type: FETCH_SORTED_WORKLOGS_SUCCESS,
});
export type FetchSortedWorklogsSuccessAction = ReturnType<typeof fetchSortedWorklogsSuccess>;

export const FETCH_SORTED_WORKLOGS_FAILURE = 'state.FETCH_SORTED_WORKLOGS_FAILURE' as const;
export const fetchSortedWorklogsFailure = () => ({
	type: FETCH_SORTED_WORKLOGS_FAILURE,
});
export type FetchSortedWorklogsFailureAction = ReturnType<typeof fetchSortedWorklogsFailure>;

export const FETCH_NEWER_SORTED_WORKLOGS_REQUEST =
	'state.FETCH_NEWER_SORTED_WORKLOGS_REQUEST' as const;
export const fetchNewerSortedWorklogsRequest = () => ({
	type: FETCH_NEWER_SORTED_WORKLOGS_REQUEST,
});
export type FetchNewerSortedWorklogsRequestAction = ReturnType<
	typeof fetchNewerSortedWorklogsRequest
>;

export const FETCH_NEWER_SORTED_WORKLOGS_SUCCESS =
	'state.FETCH_NEWER_SORTED_WORKLOGS_SUCCESS' as const;
export const fetchNewerSortedWorklogsSuccess = (payload: {
	worklogs: WorklogMap;
	totalWorklogs: number;
	startIndex: number;
	sortedWorklogIds: string[];
	selectedSortOrder: ActivitySortOrderType;
}) => ({
	type: FETCH_NEWER_SORTED_WORKLOGS_SUCCESS,
	payload,
});
export type FetchNewerSortedWorklogsSuccessAction = ReturnType<
	typeof fetchNewerSortedWorklogsSuccess
>;

export const FETCH_OLDER_SORTED_WORKLOGS_REQUEST =
	'state.FETCH_OLDER_SORTED_WORKLOGS_REQUEST' as const;
export const fetchOlderSortedWorklogsRequest = () => ({
	type: FETCH_OLDER_SORTED_WORKLOGS_REQUEST,
});
export type FetchOlderSortedWorklogsRequestAction = ReturnType<
	typeof fetchOlderSortedWorklogsRequest
>;

export const FETCH_OLDER_SORTED_WORKLOGS_SUCCESS =
	'state.FETCH_OLDER_SORTED_WORKLOGS_SUCCESS' as const;
export const fetchOlderSortedWorklogsSuccess = (payload: {
	worklogs: WorklogMap;
	totalWorklogs: number;
	startIndex: number;
	sortedWorklogIds: string[];
	selectedSortOrder: ActivitySortOrderType;
}) => ({
	type: FETCH_OLDER_SORTED_WORKLOGS_SUCCESS,
	payload,
});
export type FetchOlderSortedWorklogsSuccessAction = ReturnType<
	typeof fetchOlderSortedWorklogsSuccess
>;

export const FETCH_SORTED_WORKLOGS_RETRY = 'state.FETCH_SORTED_WORKLOGS_RETRY' as const;
export const fetchSortedWorklogsRetry = () => ({
	type: FETCH_SORTED_WORKLOGS_RETRY,
});
export type FetchSortedWorklogsRetryAction = ReturnType<typeof fetchSortedWorklogsRetry>;

export type Action =
	| FetchWorklogsRequestAction
	| FetchWorklogsRequestInitialAction
	| FetchWorklogsSuccessAction
	| FetchWorklogsFailureAction
	| AddWorklogSuccessAction
	| OpenModalAction
	| CloseModalAction
	| EditWorklogRequestAction
	| EditWorklogSuccessAction
	| EditWorklogFailureAction
	| DeleteWorklogRequestAction
	| DeleteWorklogSuccessAction
	| DeleteWorklogFailureAction
	| DisposeWorklogAppAction
	| AddWorklogRequestAction
	| UpdateTimeRemainingRequestAction
	| SetWorklogScrollStatus
	| FetchSortedWorklogsRequestAction
	| FetchSortedWorklogsSuccessAction
	| FetchSortedWorklogsFailureAction
	| FetchNewerSortedWorklogsRequestAction
	| FetchNewerSortedWorklogsSuccessAction
	| FetchOlderSortedWorklogsRequestAction
	| FetchOlderSortedWorklogsSuccessAction
	| FetchSortedWorklogsRetryAction;
