import React, { type ReactNode, useEffect, useRef } from 'react';
import defer from 'lodash/defer';
import Form, { Field as FormField, FormFooter } from '@atlaskit/form';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { messages } from './messages';
import type { DefaultFormValues, DefaultFieldTypeOption, Props } from './types';

export function CreateFieldForm<
	FieldTypeOption extends DefaultFieldTypeOption = DefaultFieldTypeOption,
	FormValues extends DefaultFormValues<FieldTypeOption> = DefaultFormValues<FieldTypeOption>,
>({
	defaultFieldName = '',
	defaultFieldTypeValue,
	fieldTypeOptionGroups,
	formId,
	isFieldTypeSelected,
	onFieldTypeChange,
	onSubmit,
	renderAdditionalFields,
	renderFieldTypeOption,
	renderControls,
	testId = 'polaris-lib-create-field-form.ui.create-field',
	validators,
}: Props<FormValues, FieldTypeOption>) {
	const { formatMessage } = useIntl();

	const nameFieldRef = useRef<HTMLElement | undefined>();

	useEffect(() => {
		// Sometimes the field was loosing focus when opening the sidebar, so we defer the focus.
		defer(() => {
			nameFieldRef.current?.focus();
		});
	}, []);

	return (
		<Stack>
			<Form<FormValues> onSubmit={onSubmit}>
				{({ formProps, submitting }) => (
					<form autoComplete="off" {...formProps} data-testid={testId} id={formId}>
						<FormField
							name="fieldName"
							testId={`${testId}.field-name`}
							label={
								<FieldLabelContainer>{formatMessage(messages.fieldNameLabel)}</FieldLabelContainer>
							}
							isRequired
							defaultValue={defaultFieldName}
							validate={validators.fieldName}
						>
							{({ fieldProps, error }) => (
								<>
									<Textfield
										{...fieldProps}
										ref={nameFieldRef}
										placeholder={formatMessage(messages.fieldNamePlaceholder)}
										testId="polaris-lib-create-field-form.ui.field-name"
									/>
									{error !== undefined && (
										<ErrorContainer data-testid="polaris-lib-create-field-form.ui.name-error">
											{error}
										</ErrorContainer>
									)}
								</>
							)}
						</FormField>
						<FormField
							name="fieldType"
							testId={`${testId}.field-type`}
							label={
								<FieldLabelContainer>{formatMessage(messages.fieldTypeLabel)}</FieldLabelContainer>
							}
							isRequired
							validate={validators.fieldType}
							defaultValue={defaultFieldTypeValue}
							isDisabled={defaultFieldTypeValue !== undefined}
						>
							{({ fieldProps, error }) => (
								<Box testId="polaris-lib-create-field-form.ui.field-type">
									<Select
										inputId={fieldProps.id}
										classNamePrefix="field-type-select"
										menuPortalTarget={document.body}
										// without properly set zIndex value dropdown with options is cut inside modal dialogs
										// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
										styles={{
											menuPortal: (base) => ({
												...base,

												zIndex: layers.modal,
											}),
											menu: (base) => ({ ...base, zIndex: 10 }),
										}}
										{...fieldProps}
										maxMenuHeight={440}
										placeholder={formatMessage(messages.fieldTypePlaceholder)}
										formatOptionLabel={(props, { selectValue }) =>
											renderFieldTypeOption({ ...props, selectValue })
										}
										isOptionSelected={isFieldTypeSelected}
										options={fieldTypeOptionGroups}
										hideSelectedOptions
										onChange={(newValue) => {
											if (newValue) {
												fieldProps.onChange(newValue);
												onFieldTypeChange?.(newValue);
											}
										}}
									/>
									{error !== undefined && (
										<ErrorContainer data-testid="polaris-lib-create-field-form.ui.type-error">
											{error}
										</ErrorContainer>
									)}
								</Box>
							)}
						</FormField>
						{renderAdditionalFields?.()}
						{renderControls ? <FormFooter>{renderControls(submitting)}</FormFooter> : null}
					</form>
				)}
			</Form>
		</Stack>
	);
}

export const FieldLabelContainer = ({ children }: { children: ReactNode }) =>
	fg('jpd-visual-refresh_typography_aurora') ? (
		<Text size="small" color="color.text.subtle">
			{children}
		</Text>
	) : (
		<Box xcss={fieldLabelStyles}>{children}</Box>
	);

const fieldLabelStyles = xcss({
	display: 'inline-flex',
	color: 'color.text.subtle',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	fontWeight: 400,
});
