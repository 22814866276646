import { statusCategories } from '@atlassian/jira-common-constants';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import {
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
	type IssueLink,
	type IssueLinks,
	type IssueLinkDirection,
	type LinkedIssue,
	type NormalizedIssueLinks,
	type NormalizedLinkedIssues,
	type ServerIssueLinks,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import {
	type IssueKey,
	type BaseUrl,
	toBaseUrl,
	toIssueId,
	toIssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueHtmlUrl } from './issue-urls';
import { transformUser } from './user-transformer';

const { statusCategoryForId } = statusCategories;

export const transformLinkedIssue = (
	linkedIssue: ServerAssociatedIssue,
	baseUrl: BaseUrl,
): LinkedIssue => {
	const { id, key, fields } = linkedIssue;

	return {
		id,
		issueKey: key,
		issueLink: getIssueHtmlUrl(baseUrl, key),
		issueSummary: fields.summary,
		statusCategory: statusCategoryForId(fields.status?.statusCategory?.id),
		statusCategoryId: fields.status?.statusCategory?.id,
		statusName: fields.status && fields.status.name,
		statusId: fields.status && fields.status.id,
		issuePriorityUrl: fields.priority?.iconUrl ?? null,
		issuePriorityName: fields.priority?.name ?? null,
		issueTypeIconUrl: fields.issuetype?.iconUrl ?? null,
		issueTypeName: fields.issuetype?.name ?? null,
		assigneeDisplayName: fields.assignee ? transformUser(fields.assignee).displayName : null,
		assigneeUrl: fields.assignee ? transformUser(fields.assignee).avatarUrl : null,
	};
};

/**
 * transform array of server issue links to array of issue links to be stored in the state
 */
export const transformIssueLinks = (
	baseUrl: BaseUrl,
	issueLinksServer: ServerIssueLinks | null = [],
): IssueLinks => {
	const issueLinks: NormalizedIssueLinks = {};
	const linkedIssues: NormalizedLinkedIssues = {};
	issueLinksServer &&
		issueLinksServer.filter(Boolean).forEach(({ id, inwardIssue, outwardIssue, type }) => {
			let typeName = null;
			let linkedIssue: ServerAssociatedIssue;
			let direction: IssueLinkDirection;

			if (inwardIssue) {
				direction = INWARD_LINK_DIRECTION;
				typeName = type.inward;
				linkedIssue = inwardIssue;
			} else if (outwardIssue) {
				direction = OUTWARD_LINK_DIRECTION;
				typeName = type.outward;
				linkedIssue = outwardIssue;
			}

			// @ts-expect-error - TS2454 - Variable 'linkedIssue' is used before being assigned.
			if (typeName && linkedIssue != null) {
				const newIssueLink = {
					id: toIssueId(id),
					linkedIssueKey: linkedIssue.key,
					// @ts-expect-error - TS2454 - Variable 'direction' is used before being assigned.
					direction,
					typeName,
					typeId: type.id,
				};

				issueLinks[id] = newIssueLink;

				const newLinkedIssue = transformLinkedIssue(linkedIssue, toBaseUrl(''));
				linkedIssues[newLinkedIssue.issueKey] = newLinkedIssue;
			}
		});

	const linkedIssueKeys: IssueKey[] = issueLinks
		? Object.values<IssueLink>(issueLinks).map(({ linkedIssueKey }) => toIssueKey(linkedIssueKey))
		: [];

	return {
		issueLinks,
		linkedIssueKeys,
		linkedIssues,
	};
};
