import type { UserAuth } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import {
	FETCH_USER_AUTH_FAILURE,
	FETCH_USER_AUTH_SUCCESS,
	type FetchUserAuthSuccessAction,
	type FetchUserAuthFailureAction,
} from './user-auth-actions';

export const initialState = null;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: UserAuth | null = initialState,
	action: FetchUserAuthSuccessAction | FetchUserAuthFailureAction,
) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH_USER_AUTH_SUCCESS:
			return payload;
		case FETCH_USER_AUTH_FAILURE:
			return initialState;
		default:
			return state;
	}
};
