import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/last';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/do';
import type { ActionsObservable } from 'redux-observable';
import identity from 'lodash/identity';
import isEqual from 'lodash/isEqual';
// @ts-expect-error - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';
import { Observable } from 'rxjs/Observable';
import type { Observer } from 'rxjs/Observer';
import iframeRedirect from '@atlassian/jira-common-navigation/src/iframe-redirect';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { clearFetchIssueNetworkTimes } from '@atlassian/jira-issue-view-common-utils/src/browser-metrics/index.tsx';
import boardOperations from '@atlassian/jira-issue-view-common/src/legacy/board-event';
import type { RecentIssuesActions } from '@atlassian/jira-issue-view-services/src/recent-issues-service/types.tsx';
import {
	loadNewIssue,
	loadRecentIssue,
	NAVIGATE_TO_NEW_ISSUE,
	NAVIGATE_TO_PROJECT,
	NAVIGATE_TO_SPRINT,
} from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions';
import { fetchIssueRemoteDataRequest } from '@atlassian/jira-issue-view-store/src/actions/issue-remote-data-actions';
import {
	analyticsSourceSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import type { DataProvider } from '@atlassian/jira-providers-issue/src/model/data-provider.tsx';
import {
	type IssueKey,
	toIssueKey as convertToIssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { initialiseMetrics, markIssueStartRendering } from '../common/metrics';

const LOG_LOCATION = 'issue.issue-navigation-epic';

// @ts-expect-error - TS7031 - Binding element 'pathname' implicitly has an 'any' type. | TS7031 - Binding element 'search' implicitly has an 'any' type.
const toUrl = ({ pathname, search }) => pathname + search;

export const replaceIssueKey = (
	url: string,
	fromIssueKey: string,
	toIssueKey: string,
): string | null => {
	const regex = new RegExp(`${fromIssueKey}(?![\\d])`, 'g');

	const matches = url.match(regex);
	if (matches === null || matches.length !== 1) {
		return null;
	}

	if (toIssueKey.endsWith(fromIssueKey)) {
		return url;
	}

	return url.replace(fromIssueKey, toIssueKey);
};

export const issueNavigationEpic =
	(
		history: BrowserHistory,
		dataProvider: DataProvider,
		recentIssuesActions: RecentIssuesActions,
		issueContextActions: IssueContextServiceActions,
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>, store: MiddlewareAPI<State>) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const urlIssueKeyMap: Record<string, any> = {};
		const navigateToNewIssue$ = action$
			.ofType(NAVIGATE_TO_NEW_ISSUE) // eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
			.pluck('payload') // @ts-expect-error([Part of upgrade 4.9-5.4]) - No overload matches this call.
			.do(({ toIssueKey }) => {
				issueContextActions.mergeIssueContext({ issueKey: convertToIssueKey(toIssueKey) });
			}) // @ts-expect-error TS2769 no overload matches
			.do(({ toIssueKey }) => {
				const state = store.getState();
				const analyticsSource = analyticsSourceSelector(state);
				const isLoadedWithPage = issueContextActions.getIsLoadedWithPage();
				const recentIssuesCount = recentIssuesActions.getRecentIssuesCount();
				const recentIssue = recentIssuesActions.getRecentIssue(toIssueKey);
				initialiseMetrics({
					// @ts-expect-error - Type 'string' is not assignable to type 'AnalyticsSource'.
					analyticsSource: String(analyticsSource),
					issueContextActions,
					isLoadedWithPage,
					isRecentIssue: !!recentIssue,
					recentIssuesCount,
					isSPA: true,
					store,
					dataProvider,
				});
				markIssueStartRendering();
			}) // @ts-expect-error TS2769 no overload matches
			.do(({ fromIssueKey, toIssueKey }) => {
				if (history) {
					urlIssueKeyMap[toUrl(window.location)] = fromIssueKey;

					const url = replaceIssueKey(toUrl(window.location), fromIssueKey, toIssueKey);
					if (url) {
						urlIssueKeyMap[url] = toIssueKey;
						history.push(url);
					}
				}
			});

		const historyChange$ = !history
			? Observable.empty<never>()
			: Observable.create((observer: Observer<unknown>) =>
					history.listen((location: unknown, action: unknown) => {
						observer.next({ location, action });
					}),
				)

					// @ts-expect-error - TS7031 - Binding element 'action' implicitly has an 'any' type.
					.filter(({ action }) => action === 'POP')
					.filter(() => {
						const isSpaEnabled = issueContextActions.getIsSpaEnabled();
						return !isSpaEnabled;
					})
					// @ts-expect-error - TS7031 - Binding element 'location' implicitly has an 'any' type.
					.map(({ location }) => urlIssueKeyMap[toUrl(location)])
					.filter(identity)
					.map(
						(
							toIssueKey: string,
						): {
							fromIssueKey: IssueKey;
							toIssueKey: IssueKey;
						} => ({
							fromIssueKey: issueKeySelector(store.getState()),
							toIssueKey,
						}),
					)
					// @ts-expect-error - TS7031 - Binding element 'fromIssueKey' implicitly has an 'any' type. | TS7031 - Binding element 'toIssueKey' implicitly has an 'any' type.
					.filter(({ fromIssueKey, toIssueKey }) => fromIssueKey !== toIssueKey);

		return (
			// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<{ toIssueKey: any; }>'.
			Observable.merge(navigateToNewIssue$, historyChange$)
				.distinctUntilChanged((fromIssueKey, toIssueKey) => isEqual(fromIssueKey, toIssueKey))
				.flatMap(({ toIssueKey }) => {
					clearFetchIssueNetworkTimes();

					const recentIssue = recentIssuesActions.getRecentIssue(toIssueKey);
					if (recentIssue) {
						return Observable.of(loadRecentIssue(toIssueKey, recentIssue));
					}

					return Observable.of(
						loadNewIssue(toIssueKey),
						// @ts-expect-error - TS2769 - No overload matches this call.
						fetchIssueRemoteDataRequest(toIssueKey),
					);
				})
				// This ensures the stream ends when action$ ends. This is
				// basically just to ensure the tests run properly.
				.takeUntil(action$.last().catch(() => Observable.of(true)))
				.catch((error) =>
					log.safeErrorWithoutCustomerData(
						LOG_LOCATION,
						'Unexpected error in issue-navigation-epic',
						error,
					),
				)
		);
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const projectNavigationEpic = (action$: ActionsObservable<any>) =>
	action$
		.ofType(NAVIGATE_TO_PROJECT)
		.do((action) => {
			iframeRedirect(action.payload.projectUrl, false, action.payload.pushAction);
		})
		.ignoreElements();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sprintNavigationEpic = (action$: ActionsObservable<any>) =>
	action$
		.ofType(NAVIGATE_TO_SPRINT)
		.do((action) => {
			boardOperations.refreshSprintOnBoard(action.payload.sprintId);
			boardOperations.hideIssueView();
		})
		.ignoreElements();
