import React, {
	useCallback,
	useMemo,
	useRef,
	useEffect,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type MouseEvent,
	type KeyboardEvent,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey, useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';
import TriggerButton from './trigger-button';

export type OwnProps = {
	icon: ReactElement<ComponentProps<typeof AsyncIcon>>;
};

export type Props = {
	isOpen: boolean;
	issueId: IssueId | null;
	issueTypes?: IssueType[];
} & OwnProps & {
		showIssueTypeList: (arg1: boolean) => void;
		loadIssueTypes: (arg1: string) => void;
		changeIssueType: (
			arg1: IssueKey,
			arg2: IssueId,
			arg3: IssueType,
			arg4: IssueType,
			arg5: UIAnalyticsEvent,
		) => void;
	};

const filterSubTaskTypePredicate = (currentType: IssueType, targetType: IssueType): boolean => {
	if (currentType.subtask) {
		return targetType.subtask;
	}
	return true;
};

const ChangeIssueType = ({
	isOpen,
	icon,
	issueTypes,
	issueId,
	showIssueTypeList,
	loadIssueTypes,
	changeIssueType,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const issueKey = useIssueKey();
	const [currentType] = useFieldValue({
		issueKey,
		fieldKey: 'issuetype',
	});
	const projectKey = useProjectKey();

	const handleItemClick = useCallback(
		(nextType: IssueType) => {
			if (issueId !== null) {
				changeIssueType(
					issueKey,
					issueId,
					currentType,
					nextType,
					// todo: check context componentName
					createAnalyticsEvent({}),
				);
				showIssueTypeList(false);
			}
		},
		[changeIssueType, createAnalyticsEvent, currentType, issueId, issueKey, showIssueTypeList],
	);

	const handleOpenChange = useCallback(
		({
			isOpen: eventPropIsOpen,
		}: {
			isOpen: boolean;
			event?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>;
		}) => {
			showIssueTypeList(eventPropIsOpen);
		},
		[showIssueTypeList],
	);

	const handleButtonClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
		(_, analyticsEvent) => {
			if (projectKey) {
				if (!isOpen) {
					// todo: check this event, missing atlaskit button info
					fireUIAnalytics(
						analyticsEvent.update({
							name: 'changeIssueTypeDropdown',
						}),
						'button clicked',
						'changeIssueTypeDropdownButton',
					);
				}
				showIssueTypeList(!isOpen);
			}
		},
		[isOpen, projectKey, showIssueTypeList],
	);

	const renderedIssueTypes = useMemo(():
		| ReactElement<ComponentProps<typeof DropdownItem>>[]
		| null => {
		if (currentType && issueTypes && issueTypes.length !== 0) {
			const transformedIssueTypes = issueTypes
				.filter((type: IssueType) => type.id !== currentType.id)
				.filter((type: IssueType) => filterSubTaskTypePredicate(currentType, type))
				.map((issueType: IssueType) => (
					<DropdownItem
						testId={`issue.views.issue-base.foundation.change-issue-type.item.${issueType.id}`}
						key={issueType.id}
						elemBefore={<AsyncIcon url={issueType.iconUrl} alt="" />}
						onClick={() => handleItemClick(issueType)}
					>
						{issueType.name}
					</DropdownItem>
				));

			return [
				<DropdownItem
					testId={`issue.views.issue-base.foundation.change-issue-type.item.${currentType.id}`}
					key={currentType.id}
					elemBefore={<AsyncIcon url={currentType.iconUrl} alt="" />}
					onClick={() => handleItemClick(currentType)}
				>
					{currentType.name}
				</DropdownItem>,
				...transformedIssueTypes,
			];
		}
		return null;
	}, [currentType, handleItemClick, issueTypes]);

	const firstRun = useRef(true);
	useEffect(() => {
		// only load issues on component updates
		if (!firstRun.current && projectKey) {
			loadIssueTypes(projectKey);
		} else {
			firstRun.current = false;
		}
	}, [projectKey, isOpen, loadIssueTypes]);

	const renderTriggerButton = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'triggerRef' implicitly has an 'any' type.
		({ triggerRef, ...props }) => (
			<TriggerButton
				{...props}
				icon={icon}
				issueType={currentType && currentType.name ? currentType.name : ''}
				onClick={handleButtonClick}
				ref={triggerRef}
			/>
		),
		[currentType, handleButtonClick, icon],
	);

	return (
		<DropdownMenu
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.foundation.change-issue-type.dropdown"
			trigger={renderTriggerButton}
			placement="bottom-start"
			appearance="default"
			// @ts-expect-error - TS2769 - No overload matches this call.
			onOpenChange={handleOpenChange}
			isOpen={isOpen}
			isTriggerNotTabbable
		>
			<DropdownItemGroup title={formatMessage(messages.changeTypeGroupName)}>
				{renderedIssueTypes}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default ChangeIssueType;
