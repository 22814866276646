import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { format, parseISO } from 'date-fns';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time/src/common/utils/seconds-to-whole-minute-time-string/index.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ServerWorklog } from '../model';
import { getWorklogUrl } from './common';

const getRequestPayload = (values: FormValues) => {
	// Convert from seconds to minutes because the
	// backend doesn't support weeks/days/hours with decimal places other than
	// 0.25, 0.5 or 0.75
	// we have to convert the final value to a whole number as passing decimal
	// values in minutes errors.
	const timeSpent = secondsToWholeMinuteTimeString(values.timeLoggedSeconds || 0);

	return {
		body: JSON.stringify(
			omitBy(
				{
					timeSpent,
					started: values.dateStarted
						? format(parseISO(values.dateStarted), "yyyy-MM-dd'T'HH:mm:ss.SSSxx")
						: undefined,
					comment: values.workDescription,
				},
				isUndefined,
			),
		),
	};
};

const getUrl = (baseUrl: BaseUrl, issueKey: IssueKey, worklogId: string, values: FormValues) => {
	// Convert from seconds to minutes because the
	// backend doesn't support weeks/days/hours with decimal places other than
	// 0.25, 0.5 or 0.75
	// we have to convert the final value to a whole number as passing decimal
	// values in minutes errors.
	const timeRemainingTimeString = values.timeRemaining
		? secondsToWholeMinuteTimeString(values.timeRemainingSeconds || 0)
		: values.timeRemaining;

	const queryString = timeRemainingTimeString
		? `?adjustEstimate=new&newEstimate=${timeRemainingTimeString}`
		: '?adjustEstimate=leave';
	return `${getWorklogUrl(baseUrl, issueKey)}/${worklogId}${queryString}`;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	worklogId: string,
	values: FormValues,
): Promise<ServerWorklog> =>
	performPutRequest(getUrl(baseUrl, issueKey, worklogId, values), getRequestPayload(values));
