import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type { Subtask } from '@atlassian/jira-issue-shared-types/src/common/types/subtask-type.tsx';
import type { Subtask as SubtaskServer } from '@atlassian/jira-issue-view-common-types/src/issue-server-type';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueHtmlUrl } from './issue-urls';
import { transformUser } from './user-transformer';

const transformSubtask = (subtask: SubtaskServer, baseUrl: BaseUrl) => ({
	id: subtask.id,
	issueLink: getIssueHtmlUrl(baseUrl, subtask.key),
	issueTypeIconUrl: subtask.fields.issuetype.iconUrl,
	issueTypeName: subtask.fields.issuetype.name,
	issuePriorityUrl: (subtask.fields.priority && subtask.fields.priority.iconUrl) || undefined,
	issuePriorityName: (subtask.fields.priority && subtask.fields.priority.name) || undefined,
	issueKey: subtask.key,
	issueSummary: subtask.fields.summary,
	statusCategory: statusCategoryForId(subtask.fields.status.statusCategory.id),
	statusCategoryId: subtask.fields.status.statusCategory.id,
	statusId: subtask.fields.status.id,
	statusName: subtask.fields.status.name,
	isLoading: false,
	hasError: false,
	assigneeUrl: subtask.fields.assignee ? transformUser(subtask.fields.assignee).avatarUrl : null,
	assigneeDisplayName: subtask.fields.assignee
		? transformUser(subtask.fields.assignee).displayName
		: null,
	issueTimeTracking: subtask.fields.timetracking,
});

export const transformSubtasks = (
	subtasks: SubtaskServer[] | null = [],
	baseUrl: BaseUrl,
): Subtask[] =>
	subtasks === null ? [] : subtasks.map((subtask) => transformSubtask(subtask, baseUrl));
