import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	cardCoverUpdateSuccess,
	cardCoverUpdateFailure,
	type CardCoverUpdateRequest,
	CARD_COVER_UPDATE_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/card-cover-actions';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { idSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getCardCoverUrl = (
	baseUrl: string,
	issueId: IssueId,
	attachmentId?: number | null,
): string => {
	const cardCoverUrl = `${baseUrl}/rest/greenhopper/1.0/xboard/work/media/${issueId}`;
	return attachmentId ? `${cardCoverUrl}?attachmentId=${attachmentId}` : cardCoverUrl;
};

const updateMediaCover = (state: State, action: CardCoverUpdateRequest) => {
	const baseUrl = baseUrlSelector(state);
	const issueId = idSelector(state);
	const { attachmentId } = action.payload;

	if (issueId === null || issueId === undefined) {
		return Observable.empty<never>();
	}

	const fetchOptions = {
		method: attachmentId ? 'PUT' : 'DELETE',
	};

	return fetchJson$(getCardCoverUrl(baseUrl, issueId, attachmentId), fetchOptions);
};

const handleSuccess = () => Observable.of(cardCoverUpdateSuccess());

const handleFailure = (error: string, action: CardCoverUpdateRequest) => {
	log.safeErrorWithoutCustomerData('issue.card-cover.update', error, new Error(error));
	return Observable.of(cardCoverUpdateFailure(action.payload.currentAttachmentId));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(CARD_COVER_UPDATE_REQUEST).flatMap((action) =>
		updateMediaCover(store.getState(), action)
			.flatMap(() => handleSuccess())
			.catch((error) => handleFailure(error, action)),
	);
