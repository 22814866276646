import { ff } from '@atlassian/jira-feature-flagging';
import {
	ASSIGNEE_TYPE,
	PRIORITY_TYPE,
	NUMBER_CF_TYPE,
	SPRINT_TYPE,
	TEXT_GQL_FIELD,
	PARENT_TYPE,
	DATE_CF_TYPE,
	STATUS_TYPE,
	DATETIME_CF_TYPE,
	PROJECT_PICKER_CF_TYPE,
	URL_CF_TYPE,
	STORY_POINTS_TYPE,
	REPORTER_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SECURITY_LEVEL_TYPE,
	SECURITY_LEVEL_TYPE_OVERRIDE,
	SUMMARY_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	USER_CF_TYPE,
	TIME_TRACKING_TYPE,
} from '@atlassian/jira-platform-field-config';

export const getSoftRefreshSupportedFields = (): string[] => {
	const fields: string[] = [];
	if (ff('relay-migration-issue-fields-assignee-ld')) {
		fields.push(ASSIGNEE_TYPE);
	}
	if (ff('relay-migration-issue-fields-reporter_jfzs2')) {
		fields.push(REPORTER_TYPE);
	}
	if (ff('relay-migration-issue-fields-user')) {
		fields.push(USER_CF_TYPE);
	}
	if (ff('relay-migration-issue-fields-priority_ri9vi')) {
		fields.push(PRIORITY_TYPE);
	}

	if (ff('relay-migration-issue-fields-number_q0em4')) {
		fields.push(NUMBER_CF_TYPE, STORY_POINT_ESTIMATE_CF_TYPE, STORY_POINTS_TYPE);
	}

	if (ff('relay-migration-issue-fields-sprint_jozpu')) {
		fields.push(SPRINT_TYPE);
	}

	if (ff('relay-migration-issue-fields-single-line-text_in6w6')) {
		fields.push(TEXT_GQL_FIELD);
	}

	if (ff('relay-migration-issue-fields-parent_5p1ac')) {
		fields.push(PARENT_TYPE);
	}

	if (ff('relay-migration-issue-fields-date_ptj72')) {
		fields.push(DATE_CF_TYPE);
	}

	if (ff('ui-modifications-issue-view-status-custom-field-support_hwx15')) {
		fields.push(STATUS_TYPE);
	}

	if (ff('relay-migration-issue-fields-project-select_w0xem')) {
		fields.push(PROJECT_PICKER_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-date-time_eaqd2')) {
		fields.push(DATETIME_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-url_ob8uu')) {
		fields.push(URL_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-security-level_7y5uv')) {
		fields.push(SECURITY_LEVEL_TYPE, SECURITY_LEVEL_TYPE_OVERRIDE);
	}

	if (ff('relay-migration-issue-fields-summary_eog1v')) {
		fields.push(SUMMARY_TYPE);
	}

	if (ff('relay-migration-issue-fields-single-line-text-message')) {
		fields.push(MESSAGE_VIEW_CF_TYPE, MESSAGE_EDIT_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-time-tracking_eefh0')) {
		fields.push(TIME_TRACKING_TYPE);
	}

	return fields;
};
