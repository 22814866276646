import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import {
	INTERNAL_SERVER_ERROR,
	NOT_FOUND,
} from '@atlassian/jira-common-constants/src/http-status-codes';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types';
import { ISSUE_VIEW_INTERACTIVE_QUERY } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/index.tsx';
import { getClient } from '@atlassian/jira-issue-fetch-services/src/common/utils/client.tsx';
import fetchIssueGiraData from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-data/index.tsx';
import type { GraphQlData as IssueServiceGraphQlData } from '@atlassian/jira-issue-fetch-services/src/types';
import type {
	GiraMediaContext,
	MediaContext,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import type { GraphQlData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type { TransformedGiraData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/transform-result.tsx';
import type {
	JiraSettingsGira,
	JiraSettingsState as JiraSettingsGiraOld,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { MyPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type {
	DynamicGraphQlResponse,
	GraphQlResponse,
} from '@atlassian/jira-issue-view-common-types/src/gira';
import { fallbackOnMissingOrError$ } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/utils/fallback-on-error';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';
import {
	getIsNewValueReturned,
	getIsConsistencyCheckEnabled,
} from '@atlassian/jira-platform-rollout-service-async/src/utils/use-consistency-feature-flag/index.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isGiraAGGConsistencyCheckEnabled } from '../../feature-flags';
import { makeErrorObject } from '../error-utils';
import { transformData as transformTotalAttachmentsCountData } from './attachment-counts';
import { transformData as transformAttachmentsData } from './attachments';
import { transformData as transformCommentsData } from './comments';
import { transformData as transformEcosystemData } from './ecosystem';
import { buildDynamicIssueQuery, type DynamicIssueQueryParams } from './graphql';
import {
	transformData as transformJiraSettings,
	transformTimeTrackingData as transformJiraSettingsTimeTrackingData,
} from './jira-settings';
import { getIsArchived } from './layout';
import { transformData as transformMediaContext } from './media-context';
import { transformData as transformPermissionData } from './permissions';
import { transformData as transformProjectData } from './project';
import { transformData as tranformRemoteLinksData } from './remote-issue-links';
import { transformData as transformWorklogsData } from './worklogs';

const GIRA_AGG_MIGRATION_ISARCHIVED_FLAG = 'issue.details.gira-agg-migration-isarchived';

// forcing type to stop exponential inference with 2 unions
const transformMediaTokens = (mediaContext?: GiraMediaContext): MediaContext =>
	// @ts-expect-error - TS2322 - Type 'MediaContext | {}' is not assignable to type 'MediaContext'.
	mediaContext ? transformMediaContext(mediaContext) : {};

const getIsOldValueReturned = (flagKey: string) => !getIsNewValueReturned(flagKey);

export const transformData = (
	data: GraphQlData | IssueServiceGraphQlData,
	isConsistencyCheck = false,
): TransformedGiraData => {
	const { viewIssue, jira, jiraSettings, permissions, mediaContext, project } = data;

	// If this transform is for the consistency check, we include slices if they have vaue 'CHECK' or 'CHECK_RETURN_NEW'
	// If this transform is for the issue view, we include slices if they have vaue 'OLD' or 'CHECK'
	const shouldIncludeSlice = isConsistencyCheck
		? getIsConsistencyCheckEnabled
		: getIsOldValueReturned;

	return {
		/*
		 *  Do not add feature-flagged spread at the top
		 *  It would break flow check
		 */
		...(ff('issue.details.gira-agg-migration-attachments_ecebe')
			? {}
			: transformAttachmentsData(viewIssue)),
		...transformCommentsData(viewIssue),
		...transformWorklogsData(viewIssue),
		...transformEcosystemData(jira, viewIssue),
		...transformJiraSettings(jiraSettings),
		...transformPermissionData(permissions, viewIssue, jira),
		...transformProjectData(project),
		...(shouldIncludeSlice(GIRA_AGG_MIGRATION_ISARCHIVED_FLAG)
			? { isArchived: getIsArchived(viewIssue) }
			: {}),
		...transformMediaTokens(mediaContext),
		...tranformRemoteLinksData(viewIssue),
		containersByType: viewIssue.containersByType,
		...(ff('issue.details.gira-agg-migration-attachments_ecebe')
			? transformTotalAttachmentsCountData(viewIssue)
			: {}),
	};
};

const transformDynamicData = (data: GraphQlData) => {
	const { viewIssue, jira, jiraSettings, activitySortOrder } = data;

	return {
		...(viewIssue && viewIssue.comments && transformCommentsData(viewIssue)),
		...(viewIssue && transformEcosystemData(jira, viewIssue)),
		...transformJiraSettingsTimeTrackingData(jiraSettings),
		activitySortOrder,
	};
};

export const fetchAllGiraData = async (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	prefetchedResource?: Promise<GraphQlResponse> | null,
	issueContextActions?: IssueContextServiceActions,
) => {
	const response = fallbackOnMissingOrError$(prefetchedResource, () =>
		Observable.fromPromise(fetchIssueGiraData(baseUrl, issueKey)),
	).toPromise();

	const { data, errors } = await response;

	if (!data) {
		// No data at all, not good
		const message = errors
			? JSON.stringify(makeErrorObject(errors))
			: 'No data returned from graphql call';
		throw new IssueViewFetchError(new Error(message), ISSUE_VIEW_INTERACTIVE_QUERY);
	}

	if (data && errors) {
		// log errors for partial data
		log.safeErrorWithoutCustomerData(
			'issue.fetch.gira',
			'Failed to fetch some data from gira',
			makeErrorObject(errors),
		);
	}

	if (!data.viewIssue) {
		if (Array.isArray(errors) && errors.length > 0) {
			const errorMessage = JSON.stringify(makeErrorObject(errors));
			// if `viewIssue` is missing and any errors were in the response
			// assume that an error caused the data to be missing.
			throw new IssueViewFetchError(
				new FetchError(
					INTERNAL_SERVER_ERROR,
					`Call to gira returned empty viewIssue with errors: ${errorMessage}`,
				),
				ISSUE_VIEW_INTERACTIVE_QUERY,
			);
		} else {
			// GraphQL doesn't rely on HTTP status codes to classify errors.
			// But we do have code that knows how to handle 404s (display "Issue not found"
			// screen, etc.), so that's why we're forcing a 404 here.
			throw new IssueViewFetchError(
				new FetchError(NOT_FOUND, 'Call to gira returned empty viewIssue'),
				ISSUE_VIEW_INTERACTIVE_QUERY,
			);
		}
	}

	if (isGiraAGGConsistencyCheckEnabled()) {
		issueContextActions?.mergeIssueContext({
			legacyGiraResponse: cloneDeep(data),
		});
	}

	return transformData(data);
};

export const fetchDynamicGiraData = async (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	dynamicIssueQueryParams?: DynamicIssueQueryParams,
) => {
	const response: DynamicGraphQlResponse = await getClient(baseUrl).query({
		query: buildDynamicIssueQuery(dynamicIssueQueryParams),
		variables: { issueKey },
	});
	const { data, errors } = response;

	if (!data) {
		// No data at all, not good
		const message = errors
			? JSON.stringify(makeErrorObject(errors))
			: 'No data returned from graphql call for dynamic data';
		throw new Error(message);
	}

	if (data && errors) {
		// log errors for partial data
		log.safeErrorWithoutCustomerData(
			'issue.fetch.gira.dynamic',
			'Failed to fetch some data from gira for dynamic data fetch',
			makeErrorObject(errors),
		);
	}

	// @ts-expect-error - TS2345 - Argument of type 'DynamicGraphQlData' is not assignable to parameter of type 'GraphQlData'.
	return transformDynamicData(data);
};

export type TransformDataOld = TransformedGiraData & {
	jiraSettings: JiraSettingsGiraOld;
	myPreferences: MyPreferences | undefined;
};
export type TransformDataNew = TransformedGiraData & {
	jiraSettings: JiraSettingsGira;
};
