import type { Worklog } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { transformWorklog } from '../../common/transform-worklogs';
import editWorklog from '../../rest/edit-worklog';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	worklogId: string,
	values: FormValues,
): Promise<Worklog> =>
	editWorklog(baseUrl, issueKey, worklogId, values).then((worklog) => transformWorklog(worklog));
