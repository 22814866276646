import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import iframeRedirect from '@atlassian/jira-common-navigation/src/iframe-redirect';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { attemptSimpleIssueTypechange } from '@atlassian/jira-issue-view-services/src/issue/change-issue-type-server';
import {
	simpleChangeIssueTypeSuccess,
	simpleChangeIssueTypeNotAllowed,
	simpleChangeIssueTypeFailure,
	SIMPLE_CHANGE_ISSUE_TYPE_REQUEST,
	SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS,
	SIMPLE_CHANGE_ISSUE_TYPE_NOT_ALLOWED,
} from '@atlassian/jira-issue-view-store/src/actions/change-issue-type-actions';
import { refreshIssueRequest } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createAnalyticsPayload = (action: string, actionSubjectId: string, attributes?: any) => ({
	action,
	actionSubjectId,
	attributes,
});

const fireAnalytics = (
	event: UIAnalyticsEvent,
	action: string,
	actionSubjectId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attributes?: any,
) => {
	const payload = createAnalyticsPayload(action, actionSubjectId, attributes);
	fireTrackAnalytics(event, payload);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const simpleChangeIssueType = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(SIMPLE_CHANGE_ISSUE_TYPE_REQUEST).switchMap((action) => {
		const baseUrl = baseUrlSelector(store.getState());
		const { issueKey, targetType, issueId, analyticsEvent, currentType } = action.payload;

		if (!currentType.subtask && targetType.subtask) {
			fireAnalytics(analyticsEvent, 'updated', 'simpleChangeIssueTypeRedirect', {
				subTask: targetType.subtask,
			});
			return Observable.of(simpleChangeIssueTypeNotAllowed(issueId, currentType, targetType));
		}

		return attemptSimpleIssueTypechange(baseUrl, issueKey, targetType.id)
			.map((success) => {
				if (success) {
					fireAnalytics(analyticsEvent, 'updated', 'simpleChangeIssueTypeSuccess');
					return simpleChangeIssueTypeSuccess(issueKey, targetType.name);
				}

				fireAnalytics(analyticsEvent, 'updated', 'simpleChangeIssueTypeRedirect', {
					subTask: targetType.subtask,
				});
				return simpleChangeIssueTypeNotAllowed(issueId, currentType, targetType);
			})
			.catch((error) => {
				log.safeErrorWithoutCustomerData('change.issue.type.simplechange', error.message, error);
				return Observable.of(simpleChangeIssueTypeFailure(error));
			});
	});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const simpleChangeIssueTypeSuccessHandler = (action$: ActionsObservable<any>) =>
	action$
		.ofType(SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS)
		.switchMap(() => Observable.of(refreshIssueRequest()));

const changeIssueTypeRedirectToMoveIssueHandler = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(SIMPLE_CHANGE_ISSUE_TYPE_NOT_ALLOWED)
		.do((action) => {
			const baseUrl = baseUrlSelector(store.getState());
			const { issueId, currentType, targetType } = action.payload;

			if (currentType.subtask && targetType.subtask) {
				iframeRedirect(
					`${baseUrl}/secure/MoveSubTaskChooseOperation!default.jspa?id=${issueId}`,
					false,
				);
			} else if (!currentType.subtask && targetType.subtask) {
				iframeRedirect(`${baseUrl}/secure/ConvertIssue.jspa?id=${issueId}`, false);
			} else {
				iframeRedirect(`${baseUrl}/secure/MoveIssue!default.jspa?id=${issueId}`, false);
			}
		})
		.ignoreElements();

export default combineEpics<Action, State>(
	simpleChangeIssueType,
	simpleChangeIssueTypeSuccessHandler,
	changeIssueTypeRedirectToMoveIssueHandler,
);
