import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators/switchMap';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/switchMap';
import { FORBIDDEN } from '@atlassian/jira-common-constants/src/http-status-codes';
import { isPageVisible } from '@atlassian/jira-common-page-visibility';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import {
	FETCH_USER_AUTH_REQUEST,
	FETCH_USER_AUTH_SUCCESS,
	FETCH_USER_AUTH_FAILURE,
	CHECK_USER_AUTH,
	fetchUserAuthRequest,
	checkUserAuth,
} from '@atlassian/jira-issue-view-store/src/common/media/user-auth/user-auth-actions';
import { userAuthSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fetchUserAuth } from './user-auth-server';

const MINIMUM_FOCUS_TIME_MS = 1000;

export const userAuthEpic = (
	getBaseUrl: (state: State) => string,
	getIssueKey: (state: State) => string,
	mediaContextActions: MediaContextServiceActions,
) => {
	// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'. | TS2304 - Cannot find name 'MiddlewareAPI'.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fetchUserAuthEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<any>) =>
		action$.ofType(FETCH_USER_AUTH_REQUEST).switchMap(() => {
			const state = store.getState();
			const baseUrl = getBaseUrl(state);
			return fetchUserAuth(baseUrl);
		});

	// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const failureLoggingEpic = (action$: ActionsObservable<any>) =>
		action$
			.ofType(FETCH_USER_AUTH_FAILURE)
			// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
			.filter(({ payload }) => payload.statusCode !== FORBIDDEN)
			// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
			.do(({ payload }) => {
				trackOrLogClientError(
					'issue.media.fetch-user-auth-error',
					'Error when fetching media user auth',
					payload,
				);
			})
			.ignoreElements();

	// Whenever the user auth token is fetched, we need to schedule a subsequent fetch
	// to occur just before the current token is due to expire. This is implemented as
	// an infinite loop - "fetch success/failure" actions result in a new (delayed)
	// "fetch request" being scheduled.
	const refreshBeforeTokenExpiryEpic = (
		// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action$: ActionsObservable<any>,
		// @ts-expect-error - TS2304 - Cannot find name 'MiddlewareAPI'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: MiddlewareAPI<any>,
	) =>
		action$.pipe(
			ofTypeOrBatchType(FETCH_USER_AUTH_SUCCESS, CHECK_USER_AUTH),
			switchMap(() => {
				const state = store.getState();
				const issueKey = getIssueKey(state);
				const userAuth = userAuthSelector(state);
				mediaContextActions.setUserAuthContext(toIssueKey(issueKey), userAuth);
				if (userAuth == null || !isPageVisible()) {
					return Observable.empty<never>();
				}

				const { tokenIssueTimestamp, tokenLifespanInMs } = userAuth;
				const wantsRefreshAt = tokenIssueTimestamp + tokenLifespanInMs;
				const timeUntilRefresh = Math.max(1, wantsRefreshAt - Date.now());

				return Observable.of(fetchUserAuthRequest()).delay(timeUntilRefresh);
			}),
		);

	// When a user returns to a tab (either from moving around in their browser, or a wake
	// from device sleep) we want to check their auth token and refresh it if necessary. If a
	// refresh is needed, the switchMap in refreshBeforeTokenExpiryEpic will ensure we don't end
	// up with multiple timed refreshes, only the latest one.
	//
	// The combination of debounce and isPageVisible check means we only fire the event if the user
	// has stayed on the page for at least the debounce time, so as not to spam events.
	const focusChangeEpic = () =>
		Observable.fromEvent(window, 'focus')
			.startWith(null)
			.debounceTime(MINIMUM_FOCUS_TIME_MS)
			.switchMap(() =>
				isPageVisible() ? Observable.of(checkUserAuth()) : Observable.empty<never>(),
			);
	return combineEpics(
		fetchUserAuthEpic,
		refreshBeforeTokenExpiryEpic,
		failureLoggingEpic,
		focusChangeEpic,
	);
};
