import 'rxjs/add/operator/map';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	NEWEST_FIRST,
	type ActivitySortOrderType,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { FetchMoreWorklogs } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import { FETCH_NEWER_SORTED_WORKLOGS_REQUEST } from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import {
	checkEmptyResponse,
	getGraphQlData,
} from '@atlassian/jira-providers-issue/src/source/issue-express/graphql-util';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import fetchMoreWorklogs from '../../rest/fetch-more-worklogs-sorted';
import { sortToOrderByMap, sortToOrderByCreatedMap, transformData } from '../common';

const LOG_LOCATION = 'issue.worklogs.fetch.epic.gira';

export const updateStartIndex = (
	worklog: FetchMoreWorklogs,
	type: string,
	sortOrder: ActivitySortOrderType,
	worklogsStartIndex: number,
) => {
	let newStartIndex;

	if (type === FETCH_NEWER_SORTED_WORKLOGS_REQUEST) {
		newStartIndex = Math.max(
			sortOrder === NEWEST_FIRST
				? worklogsStartIndex - worklog.sortedWorklogIds.length
				: worklogsStartIndex,
			0,
		);
	} else {
		newStartIndex = Math.max(
			sortOrder === NEWEST_FIRST
				? worklogsStartIndex
				: worklogsStartIndex - worklog.sortedWorklogIds.length,
			0,
		);
	}

	return {
		...worklog,
		startIndex: newStartIndex,
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	maxResults: number,
	sort: ActivitySortOrderType,
	startFetchingAtIndex: number,
	type: string,
	worklogsStartIndex: number,
) =>
	// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>' is not assignable to method's 'this' of type 'Observable<FetchWorklogsData>'.
	fetchMoreWorklogs({
		baseUrl,
		issueKey,
		maxResults,
		orderBy: ff('issue-view-worklog-timestamp-fix_8d0dt')
			? sortToOrderByCreatedMap[sort]
			: sortToOrderByMap[sort],
		startAt: startFetchingAtIndex,
	})
		// @ts-expect-error - TS2345 - Argument of type '<T extends ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>(response: GraphqlResponse<T>) => {    data: T;    errors: unknown;}' is not assignable to parameter of type '(value: unknown, index: number) => { data: ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse; errors: unknown; }'.
		.map(checkEmptyResponse)
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GraphqlResponse<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>'.
		.map((response) => getGraphQlData(LOG_LOCATION, response))
		.map(transformData)
		.map((worklog) => updateStartIndex(worklog, type, sort, worklogsStartIndex));
