import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';

// SHARE_AS_COMMENT
export const SHARE_AS_COMMENT = 'SHARE_AS_COMMENT' as const;

export const shareAsComment = (value: ADF) => ({
	type: SHARE_AS_COMMENT,
	payload: { value },
});

export type ShareAsCommentAction = ReturnType<typeof shareAsComment>;

// CLEAR_SHARED_AS_COMMENT_VALUES
export const CLEAR_SHARED_AS_COMMENT_VALUES = 'CLEAR_SHARED_AS_COMMENT_VALUES' as const;

export const clearSharedAsCommentValues = () => ({
	type: CLEAR_SHARED_AS_COMMENT_VALUES,
});

export type ClearSharedAsCommentValuesAction = ReturnType<typeof clearSharedAsCommentValues>;
