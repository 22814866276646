import type { MiddlewareAPI } from 'redux';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	EDIT_COMMENT_BEGIN,
	ADD_COMMENT_FORM_EXPAND,
	REPLY_COMMENT_BEGIN,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { multilineFieldEditBegin } from '@atlassian/jira-issue-view-store/src/common/actions/multiline-field-edit-actions';
import {
	getEditingMultilineFieldIdsSelector,
	isMultilineFieldSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { FIELD_EDIT_BEGIN } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions';
import {
	getEditingCommentIdsSelector,
	isAddCommentEditorExpandedSelector,
	isCommentReplyEditorOpenSelector,
	isReplyingToCommentSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector';

const getMultilineFieldEditBeginAction = (
	state: State,
	{
		fieldId,
		commentId,
	}: {
		fieldId?: string;
		commentId?: string;
	},
	isAddCommentEditorExpandAction = false,
	isReplyCommentAction = false,
) => {
	const multilineFieldIdsToClose = getEditingMultilineFieldIdsSelector(state).filter(
		(id) => id !== fieldId,
	);
	const commentIdsToClose = getEditingCommentIdsSelector(state).filter((id) => id !== commentId);

	const shouldCloseAddCommentForm =
		!isAddCommentEditorExpandAction && isAddCommentEditorExpandedSelector(state);

	if (fg('jira_threaded_comments_experiment')) {
		const shouldCloseReplyCommentForm =
			isCommentReplyEditorOpenSelector(state) &&
			!(isReplyCommentAction && commentId && isReplyingToCommentSelector(commentId)); // Reply clicked on the same comment

		return multilineFieldIdsToClose.length > 0 ||
			commentIdsToClose.length > 0 ||
			shouldCloseAddCommentForm ||
			shouldCloseReplyCommentForm
			? multilineFieldEditBegin(
					multilineFieldIdsToClose,
					commentIdsToClose,
					shouldCloseAddCommentForm,
					shouldCloseReplyCommentForm,
				)
			: null;
	}
	return multilineFieldIdsToClose.length > 0 ||
		commentIdsToClose.length > 0 ||
		shouldCloseAddCommentForm
		? multilineFieldEditBegin(
				multilineFieldIdsToClose,
				commentIdsToClose,
				shouldCloseAddCommentForm,
			)
		: null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editFieldEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_EDIT_BEGIN)
		.filter((action) => isMultilineFieldSelector(action.payload.fieldId)(store.getState()))
		.map((action) =>
			getMultilineFieldEditBeginAction(store.getState(), {
				fieldId: action.payload.fieldId,
			}),
		)
		.filter(Boolean);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editCommentEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(EDIT_COMMENT_BEGIN)
		.map((action) =>
			getMultilineFieldEditBeginAction(store.getState(), {
				commentId: action.payload.id,
			}),
		)
		.filter(Boolean);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addCommentEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) => {
	const action = action$
		.ofType(ADD_COMMENT_FORM_EXPAND)
		.map(() => getMultilineFieldEditBeginAction(store.getState(), {}, true))
		.filter(Boolean);

	const EVENT_HORIZON_DELAY = 100;
	// FIXME this is a mitigation for issue (JRACLOUD-82570) for the period of time before fix in DST (DSP-15719)
	// execute "expand" event in timeout to give focus/blur events time to save data
	// as they will be destroyed MULTILINE_FIELD_EDIT_BEGIN action
	// we are using timeout "greater than anything" to hard separate comment focus and collapse of other fields
	return action.delay(EVENT_HORIZON_DELAY);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replyCommentEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(REPLY_COMMENT_BEGIN)
		.map((action) =>
			getMultilineFieldEditBeginAction(
				store.getState(),
				{
					commentId: action.payload.commentId,
				},
				false,
				true,
			),
		)
		.filter(Boolean);

const multilineFieldEditEpicOld = () =>
	combineEpics(editFieldEpic, editCommentEpic, addCommentEpic);
const multilineFieldEditEpicNew = () =>
	combineEpics(editFieldEpic, editCommentEpic, addCommentEpic, replyCommentEpic);

export default functionWithCondition(
	() => fg('jira_threaded_comments_experiment'),
	multilineFieldEditEpicNew(),
	multilineFieldEditEpicOld(),
);
