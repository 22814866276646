import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/of';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators/catchError';
import { mergeMap } from 'rxjs/operators/mergeMap';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import {
	type IssueFetchAction,
	FETCH_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import {
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	fieldEditingValueSelector,
	richTextFieldIdsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { loadDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions';
import { getDraftKey } from '@atlassian/jira-issue-view-store/src/drafts/draft-utils';
import { fieldLoadDraftError } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-draft-actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadFieldDraftRequests = (action: any, state: State) => {
	const loggedInAccountId = accountIdloggedInUserSelector(state);
	const issueKey = issueKeySelector(state);
	const richTextFieldIds = richTextFieldIdsSelector(state);
	return richTextFieldIds.map((fieldId) => {
		const currentFieldValue = fieldEditingValueSelector(fieldId)(state);
		const draftKey = getDraftKey(loggedInAccountId, issueKey, fieldId);
		return Observable.of(loadDraftRequest(fieldId, draftKey, null, currentFieldValue));
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError(
		'issue.load-field-draft',
		'Failed to send request to load field draft from local storage ',
		error,
	);
	return Observable.of(fieldLoadDraftError(error));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<IssueFetchAction>, store: MiddlewareAPI<State>) =>
	action$.pipe(
		ofTypeOrBatchType(FETCH_ISSUE_SUCCESS),
		mergeMap((action) => Observable.merge(...loadFieldDraftRequests(action, store.getState()))),
		catchError((error) => errorHandler(error)),
	);
