import { freeze, setIn, updateIn, chain, set, unset, getIn } from 'icepick';
import get from 'lodash/get';
import { doc, mention, p, text } from '@atlaskit/adf-utils/builders';
import unsetIn from '@atlassian/jira-common-icepick/src/utils/unset-in/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SAVE_COMMENT_LOADING,
	LOADING,
	COMPLETE,
	type CommentId,
	type CommentsUiState,
	type OptimisticComment,
	type CommentEditors,
} from '@atlassian/jira-issue-view-common-types/src/comment-type';
import {
	type CommentAction,
	ADD_COMMENT_FORM_EXPAND,
	ADD_COMMENT_FORM_CANCEL,
	ADD_COMMENT_FORM_COLLAPSE,
	SAVE_COMMENT_REQUEST,
	SAVE_COMMENT_SUCCESS,
	SAVE_COMMENT_FAILURE,
	SAVE_COMMENT_RETRY,
	SAVE_COMMENT_EDIT,
	SAVE_COMMENT_CANCEL,
	EDIT_COMMENT_BEGIN,
	EDIT_COMMENT_CANCEL,
	EDIT_COMMENT_UPDATE,
	EDIT_COMMENT_VISIBILITY,
	DELETE_COMMENT_REQUEST,
	DELETE_COMMENT_SUCCESS,
	DELETE_COMMENT_FAILURE,
	SHOW_DELETE_COMMENT_MODAL,
	DELETE_COMMENT_CANCEL,
	FETCH_OLDER_COMMENTS_REQUEST,
	FETCH_NEWER_COMMENTS_REQUEST,
	FETCH_MORE_COMMENTS_SUCCESS,
	FETCH_MORE_COMMENTS_FAILURE,
	EDIT_COMMENT_COLLAPSE,
	CHANGE_COMMENT_VISIBILITY,
	SET_COMMENT_ATTACHMENT_IN_PROGRESS,
	SET_COMMENT_SCROLL_STATUS,
	SET_COMMENT_VALUE,
	FETCH_SORTED_COMMENTS_REQUEST,
	FETCH_SORTED_COMMENTS_SUCCESS,
	EDIT_COMMENT_EVENT_OCCURRED_AT,
	REPLY_COMMENT_BEGIN,
	REPLY_COMMENT_UPDATE,
	REPLY_COMMENT_CANCEL,
} from '../actions/comment-actions';
import { FETCH_ISSUE_SUCCESS } from '../common/actions/issue-fetch-actions';
import {
	type MultilineFieldEditBeginAction,
	MULTILINE_FIELD_EDIT_BEGIN,
} from '../common/actions/multiline-field-edit-actions';
import { isJsmTimelineEnabled } from '../feature-flags';
import {
	PREVIEW_DATA_FETCHED,
	type PreviewDataFetchedAction,
} from '../issue-field/state/actions/field-preview-actions';
import { NEW_COMMENT_ID, NEW_REPLY_COMMENT_ID_PREFIX } from '../selectors/comment-constants';

const initialState = freeze({
	pendingSavedValues: {},
	pendingDeletedIds: [],
	deleteModalCommentId: null,
	isLoadingMoreComments: false,
	commentEditors: {},
	isCommentAttachmentInProgress: false,
	hasScrolledPermalinkComment: false,
	loadingStage: LOADING,
	replyingToCommentId: null,
	replyingToAuthorId: null,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: CommentsUiState = initialState,
	action: CommentAction | MultilineFieldEditBeginAction | PreviewDataFetchedAction,
) => {
	const isEditExistingComment = (commentId: CommentId) =>
		!!getIn(state, ['commentEditors', commentId]);

	const removeDeletedComment = (id: CommentId) =>
		updateIn(state, ['pendingDeletedIds'], (ids) =>
			// @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS7006 - Parameter 'pendingId' implicitly has an 'any' type.
			ids.filter((pendingId) => pendingId !== id),
		);

	switch (action.type) {
		case PREVIEW_DATA_FETCHED: {
			return set(state, 'loadingStage', COMPLETE);
		}

		case SAVE_COMMENT_REQUEST: {
			const {
				isNewComment,
				id,
				createdDate,
				authorId,
				bodyHtml,
				bodyAdf,
				visibility,
				eventOccurredAt,
				jsdIncidentActivityViewHidden,
				fullIssueUrl,
				triggerIncidentSaveCommentFlag,
				parentId,
			} = action.payload.comment;
			let changedState = state;
			if (isNewComment) {
				const newComment = state.commentEditors[NEW_COMMENT_ID];
				if (newComment !== undefined) {
					const tempState = unsetIn(state, ['commentEditors', NEW_COMMENT_ID]);
					changedState = setIn(tempState, ['commentEditors', id], newComment);
				}
			}

			const isInternal = get(
				changedState,
				// When adding a new comment, at this point, `id` is no longer equal to `NEW_OPTIMISTIC_COMMENT`,
				// it already became an optimistic id (which is `OPTIMISTIC_COMMENT_<timestamp>`).
				['commentEditors', id, 'isInternal'],
				true,
			);

			const additionalOptimisticCommentProperties = isJsmTimelineEnabled()
				? {
						eventOccurredAt,
						jsdIncidentActivityViewHidden,
						fullIssueUrl,
						triggerIncidentSaveCommentFlag,
					}
				: {};

			const optimisticComment: OptimisticComment = {
				id,
				createdDate,
				authorId,
				bodyHtml,
				bodyAdf,
				isInternal,
				visibility,
				...additionalOptimisticCommentProperties,
				...(fg('jira_threaded_comments_experiment') ? { parentId } : {}),
			};

			changedState = isNewComment
				? setIn(changedState, ['loadingStage'], SAVE_COMMENT_LOADING)
				: changedState;

			if (fg('jira_threaded_comments_experiment')) {
				changedState = parentId
					? chain(changedState)
							.set('replyingToCommentId', null)
							.set('replyingToAuthorId', null)
							.value()
					: changedState;
			}

			return chain(changedState)
				.setIn(['pendingSavedValues', id], optimisticComment)
				.setIn(['commentEditors', id, 'isEditing'], false)
				.value();
		}

		case FETCH_OLDER_COMMENTS_REQUEST:
		case FETCH_NEWER_COMMENTS_REQUEST:
			return set(state, 'isLoadingMoreComments', true);

		case FETCH_MORE_COMMENTS_SUCCESS:
		case FETCH_MORE_COMMENTS_FAILURE:
			return set(state, 'isLoadingMoreComments', false);

		case SAVE_COMMENT_RETRY: {
			const { payload } = action;
			const changedState = unsetIn(state, [
				'pendingSavedValues',
				payload.optimisticId,
				'hasSaveFailed',
			]);

			if (isEditExistingComment(payload.optimisticId)) {
				return changedState;
			}

			// need to update created date when saving a new comment (retry)
			return setIn(
				changedState,
				['pendingSavedValues', payload.optimisticId, 'createdDate'],
				payload.createdDate,
			);
		}

		case SAVE_COMMENT_EDIT:
			return setIn(state, ['commentEditors', action.payload.optimisticId, 'isEditing'], true);

		case SAVE_COMMENT_SUCCESS:
		case SAVE_COMMENT_CANCEL:
			return unsetIn(unsetIn(state, ['commentEditors', action.payload.optimisticId]), [
				'pendingSavedValues',
				action.payload.optimisticId,
			]);

		case FETCH_SORTED_COMMENTS_REQUEST:
			return set(state, 'loadingStage', LOADING);
		case FETCH_SORTED_COMMENTS_SUCCESS:
		case FETCH_ISSUE_SUCCESS:
			return set(state, 'loadingStage', COMPLETE);

		case SAVE_COMMENT_FAILURE:
			return setIn(
				state,
				['pendingSavedValues', action.payload.optimisticId, 'hasSaveFailed'],
				true,
			);

		case DELETE_COMMENT_REQUEST: {
			const { payload } = action;
			return (
				chain(state)
					// @ts-expect-error - TS2488 - Type 'unknown' must have a '[Symbol.iterator]()' method that returns an iterator.
					.updateIn(['pendingDeletedIds'], (ids) => [...ids, payload.id])
					.set('deleteModalCommentId', null)
					.value()
			);
		}

		case DELETE_COMMENT_SUCCESS:
			return removeDeletedComment(action.payload.id);

		case DELETE_COMMENT_FAILURE:
			return removeDeletedComment(action.payload);

		// not needed once jira_issue_view_entrypoint_delete_comment_modal is rolled out
		case SHOW_DELETE_COMMENT_MODAL:
			return set(state, 'deleteModalCommentId', action.payload);
		// not needed once jira_issue_view_entrypoint_delete_comment_modal is rolled out
		case DELETE_COMMENT_CANCEL:
			return set(state, 'deleteModalCommentId', null);

		case EDIT_COMMENT_BEGIN:
			return setIn(state, ['commentEditors', action.payload.id], {
				isEditing: true,
				isInternal: action.payload.isInternal,
			});

		case EDIT_COMMENT_UPDATE:
			return setIn(state, ['commentEditors', action.payload.id], {
				isEditing: true,
				isInternal: getIn(state, ['commentEditors', action.payload.id, 'isInternal']),
				editingValue: action.payload.value,
				visibility: getIn(state, ['commentEditors', action.payload.id, 'visibility']),
				eventOccurredAt: getIn(state, ['commentEditors', action.payload.id, 'eventOccurredAt']),
			});

		case EDIT_COMMENT_VISIBILITY:
			return setIn(
				state,
				['commentEditors', action.payload.id, 'visibility'],
				action.payload.visibility,
			);

		case EDIT_COMMENT_COLLAPSE:
		case EDIT_COMMENT_CANCEL:
			return unsetIn(state, ['commentEditors', action.payload.id]);

		case ADD_COMMENT_FORM_CANCEL:
		case ADD_COMMENT_FORM_COLLAPSE: {
			const updatedState = unset(state, 'addCommentEditorExpand');
			return unsetIn(updatedState, ['commentEditors', NEW_COMMENT_ID]);
		}

		case ADD_COMMENT_FORM_EXPAND:
			return chain(state)
				.set('addCommentEditorExpand', true)
				.set('addCommentEditorForceFocus', (state.addCommentEditorForceFocus || 0) + 1)
				.value();

		case CHANGE_COMMENT_VISIBILITY: {
			const { id: commentId, isInternal } = action.payload;
			return setIn(state, ['commentEditors', commentId, 'isInternal'], isInternal);
		}
		case EDIT_COMMENT_EVENT_OCCURRED_AT: {
			return setIn(
				state,
				['commentEditors', action.payload.id, 'eventOccurredAt'],
				action.payload.eventOccurredAt,
			);
		}
		case SET_COMMENT_ATTACHMENT_IN_PROGRESS:
			return set(state, 'isCommentAttachmentInProgress', action.payload.isInProgress);

		case SET_COMMENT_SCROLL_STATUS:
			return set(state, 'hasScrolledPermalinkComment', action.payload.status);

		case SET_COMMENT_VALUE:
			return setIn(state, ['commentEditors', action.payload.id, 'bodyAdf'], action.payload.value);

		case MULTILINE_FIELD_EDIT_BEGIN: {
			const { commentIdsToClose, shouldCloseAddCommentForm, shouldCloseCommentReplyEditor } =
				action.payload;

			if (commentIdsToClose != null) {
				let commentIdsToBeClosed = shouldCloseAddCommentForm
					? commentIdsToClose.concat(NEW_COMMENT_ID)
					: commentIdsToClose;
				if (fg('jira_threaded_comments_experiment')) {
					commentIdsToBeClosed =
						shouldCloseCommentReplyEditor && state.replyingToCommentId
							? commentIdsToBeClosed.concat(
									`${NEW_REPLY_COMMENT_ID_PREFIX}-${state.replyingToCommentId}`,
								)
							: commentIdsToBeClosed;
				}
				const commentEditorsToBeClosed: CommentEditors = commentIdsToBeClosed.reduce<
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					Record<string, any>
				>(
					(accu, commentId) => ({
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...accu,
						[commentId]: {
							...state.commentEditors[commentId],
							editingValue: undefined,
							visibility: undefined,
							isEditing: false,
						},
					}),
					{},
				);

				return {
					...state,
					commentEditors: {
						...state.commentEditors,
						...commentEditorsToBeClosed,
					},
					addCommentEditorExpand: shouldCloseAddCommentForm ? false : state.addCommentEditorExpand,
					...(fg('jira_threaded_comments_experiment') && {
						replyingToCommentId: shouldCloseCommentReplyEditor ? null : state.replyingToCommentId,
						replyingToAuthorId: shouldCloseCommentReplyEditor ? null : state.replyingToAuthorId,
					}),
				};
			}
			return state;
		}

		case REPLY_COMMENT_BEGIN: {
			const adfBody = doc(
				p(
					mention({ id: action.payload.authorId, text: `@${action.payload.authorDisplayName}` }),
					text(' '),
				),
			);
			return chain(state)
				.set('replyingToCommentId', action.payload.commentId)
				.set('replyingToAuthorId', action.payload.authorId)
				.setIn(['commentEditors', `${NEW_REPLY_COMMENT_ID_PREFIX}-${action.payload.commentId}`], {
					isEditing: true,
					isInternal: false,
					editingValue: adfBody,
					visibility: action.payload.visibility,
				})
				.value();
		}

		case REPLY_COMMENT_UPDATE:
			return setIn(
				state,
				['commentEditors', `${NEW_REPLY_COMMENT_ID_PREFIX}-${action.payload.parentId}`],
				{
					isEditing: true,
					isInternal: false,
					editingValue: action.payload.value,
					visibility: getIn(state, [
						'commentEditors',
						`${NEW_REPLY_COMMENT_ID_PREFIX}-${action.payload.parentId}`,
						'visibility',
					]),
				},
			);

		case REPLY_COMMENT_CANCEL: {
			const updatedState = chain(state)
				.set('replyingToCommentId', null)
				.set('replyingToAuthorId', null)
				.value();
			return unsetIn(updatedState, [
				'commentEditors',
				`${NEW_REPLY_COMMENT_ID_PREFIX}-${action.payload.parentId}`,
			]);
		}

		default:
			return state;
	}
};
