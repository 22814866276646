import 'rxjs/add/observable/from';
import { Observable } from 'rxjs/Observable';
import { INTERNAL_SERVER_ERROR } from '@atlassian/jira-common-constants/src/http-status-codes';
import type { Event } from '@atlassian/jira-common-util-logging/src/log';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { ISSUE_AGG_OPERATION_NAME } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import fetchIssueAggData, {
	type IssueAggData,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-agg-data/index.tsx';
import { AGG_ISSUE_ERROR_ANALYTICS } from '@atlassian/jira-issue-view-common-constants';
import { fallbackOnMissingOrError$ } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/utils/fallback-on-error';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type { IssueKey, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { makeErrorObject, type Errors } from '../error-utils';
import {
	EMPTY_AGG_RESPONSE_ERROR,
	EMPTY_AGG_ISSUE_RESPONSE_ERROR,
	RETRIEVE_DATA_ERROR,
} from './constants';

const getErrorMessage = (defaultMessage: string, errors?: Errors) =>
	errors ? JSON.stringify(makeErrorObject(errors, AGG_ISSUE_ERROR_ANALYTICS)) : defaultMessage;

export const reportAggError = (event: Event) =>
	log.safeErrorWithoutCustomerData(
		'issue.fetch.agg',
		'Something went wrong when fetching data from AGG',
		event,
	);

// Visible for testing
export const fetchAggData = async (
	issueKey: IssueKey,
	cloudId: CloudId,
	prefetchedResource?: Promise<IssueAggData> | null,
) => {
	const response = fallbackOnMissingOrError$(prefetchedResource, () =>
		Observable.from(fetchIssueAggData(issueKey, cloudId, getRelayEnvironment())),
	).toPromise();

	const { data, errors, traceId } = await response;

	if (!data?.jira) {
		// We didn't get any data in the response at all - this is very bad
		const message = getErrorMessage(EMPTY_AGG_RESPONSE_ERROR, errors);
		throw new IssueViewFetchError(new Error(message), ISSUE_AGG_OPERATION_NAME, traceId);
	}

	if (!data.jira.issueByKey) {
		const message = getErrorMessage(EMPTY_AGG_ISSUE_RESPONSE_ERROR, errors);

		const dataFetchException = errors?.find(
			(error) => error?.extensions?.classification === 'DataFetchingException',
		);

		const error = dataFetchException?.extensions
			? new FetchError(dataFetchException.extensions.statusCode, message)
			: new Error(message);

		throw new IssueViewFetchError(error, ISSUE_AGG_OPERATION_NAME, traceId);
	}

	if (data.jira.issueByKey?.errorRetrievingData === true) {
		const message = getErrorMessage(RETRIEVE_DATA_ERROR, errors);

		throw new IssueViewFetchError(
			new FetchError(INTERNAL_SERVER_ERROR, message),
			ISSUE_AGG_OPERATION_NAME,
			traceId,
		);
	}

	if (data && errors && errors.length > 0) {
		reportAggError(makeErrorObject(errors, AGG_ISSUE_ERROR_ANALYTICS));
	}

	return data;
};
