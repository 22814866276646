import type {
	Project,
	TransformedProject,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/project.tsx';

export { getQuery } from './graphql';

export const transformData = (project?: Project): TransformedProject => ({
	issueTypes: project ? project.issueTypes : [],
	newIssueViewLockedIn: Boolean(project && project.newIssueViewLockedIn),
});
