import { getContentPanelsQuery } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/gira-fragments/content-panels/index.tsx';
import { getEcosystemQuery } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/gira-fragments/ecosystem/index.tsx';
import { getJiraQueryEcosystemFirstSeenOnIssueView } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/index.tsx';
import type { GiraQuery } from '@atlassian/jira-issue-fetch-services/src/common/utils/client';
import type { DynamicGraphQlResponse } from '@atlassian/jira-issue-view-common-types/src/gira';
import { getQuery as getTimeTrackingQuery } from '@atlassian/jira-issue-view-services/src/issue/gira/jira-settings/graphql.tsx';
import { getQuery as getCommentsQuery } from '../comments';
import { getLegacyRightWebPanelsQuery } from '../ecosystem';

export type DynamicIssueQueryParams = {
	shouldRefreshComments?: boolean;
};

export const buildDynamicIssueQuery = ({
	shouldRefreshComments,
}: DynamicIssueQueryParams = {}): GiraQuery<DynamicGraphQlResponse> =>
	`query issueViewDynamicQuery($issueKey: String!) {
        ${getJiraQueryEcosystemFirstSeenOnIssueView()}
        viewIssue(issueKey: $issueKey) {
            ${shouldRefreshComments === true ? getCommentsQuery() : ''}  
            ${getEcosystemQuery()}
            ${getLegacyRightWebPanelsQuery()}
            ${getContentPanelsQuery()}
        }
        ${getTimeTrackingQuery()}
        activitySortOrder
    }`;
