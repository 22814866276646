import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { useStickyHeaderRegistration } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import type { HeaderComponentProps, Props, RequiredComponents } from '../../types';
import BaseGroup from '../base-group';
import { defaultComponents } from '../styled';

const HEADER_TITLE_DATA_TEST_ID =
	'issue-view-layout-group.common.ui.collapsible-group-factory.title';

const HeaderComponent = ({
	id,
	isOpen,
	subTitle,
	title,
	onToggle,
	renderHeaderActions,
	stickyHeaderPosition,
	componentsMerged,
	innerRef,
}: HeaderComponentProps) => {
	const { Title, SubTitle, Header, HeaderRightAligned } = componentsMerged;
	const testId =
		id === null || id === undefined
			? undefined
			: {
					'data-testid': `issue-view-layout-group.common.ui.collapsible-group-factory.${id}`,
				};

	return (
		<Header
			aria-label={title}
			onClick={onToggle}
			tabIndex={0}
			{...testId}
			stickyHeaderPosition={stickyHeaderPosition}
			ref={innerRef}
			role="button"
		>
			<Title data-testid={HEADER_TITLE_DATA_TEST_ID}>{title}</Title>
			{subTitle !== undefined && !isOpen && <SubTitle aria-hidden="true">{subTitle}</SubTitle>}
			{typeof renderHeaderActions === 'function' && (
				<HeaderRightAligned isOpen={isOpen}>{renderHeaderActions()}</HeaderRightAligned>
			)}
		</Header>
	);
};

/**
 * The default Composable Component group, that acts
 * as a skeleton, and can be re-used for different purpose or
 * design.
 */
export const CollapsibleGroupFactory = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		components,
		id,
		isOpen,
		onHeaderClick,
		renderHeaderActions,
		stickyHeaderPosition,
		subTitle,
		title,
	} = props;
	const componentsMerged: RequiredComponents = { ...defaultComponents, ...components };
	const { Group, Body } = componentsMerged;
	const didOpenAtLeastOnceRef = useRef(false);

	const onToggle = useCallback(
		(event: Event) => {
			// Using `useRef` here instead of `useState` prevents an extra re-render
			// because `useState`'s updater functionc causes a re-render when being
			// updated.
			// See: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/60341/overview?commentId=3695533
			didOpenAtLeastOnceRef.current = true;
			event.preventDefault();
			onHeaderClick?.(event);
		},
		// `onHeaderClick` should be a dependency as this is passed from a prop and
		// would otherwise trigger un-necessary re-renders (especially as this is not
		// memoized in the parent component `CollapsibleGroup`).
		[onHeaderClick],
	);

	const headerRef = useRef(null);

	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();

	useEffect(() => {
		const STICKY_NAME = Symbol('group-header');
		registerSticky(STICKY_NAME, headerRef);
		return () => deregisterSticky(STICKY_NAME);
	}, [registerSticky, deregisterSticky, stickyHeaderPosition]);

	return (
		<BaseGroup
			headerNode={
				<HeaderComponent
					isOpen={isOpen}
					componentsMerged={componentsMerged}
					id={id}
					onToggle={onToggle}
					renderHeaderActions={renderHeaderActions}
					stickyHeaderPosition={stickyHeaderPosition}
					subTitle={subTitle}
					title={title}
					innerRef={headerRef}
				/>
			}
			Group={Group}
			isOpen={isOpen}
			ref={ref}
		>
			{(!didOpenAtLeastOnceRef.current && !isOpen) || children === undefined ? null : (
				<Body isOpen={isOpen}>{children}</Body>
			)}
		</BaseGroup>
	);
});

export default CollapsibleGroupFactory;
