import { CHANGEBOARDING } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type { ChangeboardingUiState } from '@atlassian/jira-issue-view-common-types/src/changeboarding-type';
import {
	type ChangeboardingAction,
	CHANGEBOARDING_TOUR_BEGIN,
	CHANGEBOARDING_TOUR_END,
	CHANGEBOARDING_BANNER_DISMISS,
} from '../actions/changeboarding-actions';
import {
	type FetchIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
} from '../common/actions/issue-fetch-actions';
import {
	type FieldPreviewAction,
	PREVIEW_DATA_FETCHED,
} from '../issue-field/state/actions/field-preview-actions';

const initialState = {
	isBannerVisible: false,
	isBannerDismissed: false,
	isTourActive: false,
} as const;

type Action = ChangeboardingAction | FetchIssueSuccessAction | FieldPreviewAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ChangeboardingUiState = initialState, action: Action) => {
	switch (action.type) {
		case PREVIEW_DATA_FETCHED:
		case FETCH_ISSUE_SUCCESS: {
			const prefs = action.payload.myPreferences && action.payload.myPreferences[CHANGEBOARDING];
			if (prefs !== undefined) {
				return {
					...state,
					isBannerVisible: prefs === null,
				};
			}
			return state;
		}

		case CHANGEBOARDING_BANNER_DISMISS:
			return {
				...state,
				isBannerVisible: false,
				isBannerDismissed: true,
			};

		case CHANGEBOARDING_TOUR_BEGIN:
			return {
				...state,
				isBannerVisible: false,
				isTourActive: true,
			};

		case CHANGEBOARDING_TOUR_END:
			return {
				...state,
				isTourActive: false,
			};

		default:
			return state;
	}
};
