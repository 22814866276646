import React from 'react';
import {
	ExperienceFailureTracker as ViewExperienceFailureTracker,
	ExperienceSuccessTracker as ViewExperienceSuccessTracker,
} from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import type { IssueErrorAttributes } from '@atlassian/jira-issue-shared-types/src/common/types/error-type';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	type IssueError,
} from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import { AuthenticationErrorView } from '@atlassian/jira-issue-view-errors/src/ui/authentication-error-view/index.tsx';
import { PermissionErrorView } from '@atlassian/jira-issue-view-errors/src/ui/permission-error-view/index.tsx';
import { UnknownErrorView } from '@atlassian/jira-issue-view-errors/src/ui/unknown-error-view/index.tsx';

type Props = {
	errorType: IssueError;
	errorAttributes?: IssueErrorAttributes;
	loginRedirectUrl?: string;
};

export const SUCCESS_TRACKER_TESTID =
	'issue.views.issue-details.error.ui.issue-error-view-with-success-tracking.view-experience-success-tracker';

export const FAILURE_TRACKER_TESTID =
	'issue.views.issue-details.error.ui.issue-error-view-with-success-tracking.view-experience-failure-tracker';

export const IssueErrorViewWithSuccessTracking = ({
	errorType,
	loginRedirectUrl,
	errorAttributes,
}: Props) => {
	switch (errorType) {
		case AUTHENTICATION_ERROR:
			return (
				<ViewExperienceSuccessTracker data-testid={SUCCESS_TRACKER_TESTID} location="auth-error">
					<AuthenticationErrorView loginRedirectUrl={loginRedirectUrl} />
				</ViewExperienceSuccessTracker>
			);
		case NOT_FOUND_OR_NO_PERMISSION_ERROR:
			return (
				<ViewExperienceSuccessTracker
					data-testid={SUCCESS_TRACKER_TESTID}
					location="not-found-or-no-permission-error"
				>
					<PermissionErrorView />
				</ViewExperienceSuccessTracker>
			);
		case CONNECTIVITY_ERROR:
			return <UnknownErrorView />;
		default:
			return (
				<ViewExperienceFailureTracker
					data-testid={FAILURE_TRACKER_TESTID}
					location="unknown-error"
					failureEventAttributes={errorAttributes}
				>
					<UnknownErrorView />
				</ViewExperienceFailureTracker>
			);
	}
};
