import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ServerWorklog } from '../model';
import { getSortedWorklogsGiraQuery } from './common';

export type FetchWorklogResponse = {
	startAt: number;
	maxResults: number;
	total: number;
	worklogs: ServerWorklog[];
};

const fetchSortedWorklogsFromGira = ({
	baseUrl,
	issueKey,
	maxResults,
	orderBy,
}: {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	maxResults: number;
	orderBy: string;
}) =>
	fetchJson$(`${baseUrl}/rest/gira/1/`, {
		method: 'POST',
		body: JSON.stringify({
			query: getSortedWorklogsGiraQuery({ maxResults, issueKey, orderBy }),
		}),
	});

export default fetchSortedWorklogsFromGira;
