import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type { ServerRestUser } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueHtmlUrl } from './issue-urls';
import { transformUser } from './user-transformer';

const getAssigneeFields = (
	assignee?: ServerRestUser | null,
): {
	assigneeUrl?: string | null;
	assigneeDisplayName: string | null;
} => {
	if (assignee != null) {
		const { avatarUrl, displayName } = transformUser(assignee);
		return {
			assigneeUrl: avatarUrl,
			assigneeDisplayName: displayName,
		};
	}
	return {
		assigneeUrl: null,
		assigneeDisplayName: null,
	};
};

export const transformChild = (child: ServerAssociatedIssue, baseUrl: BaseUrl): ChildIssue => {
	const { fields } = child;
	return {
		id: child.id,
		issueLink: getIssueHtmlUrl(baseUrl, child.key),
		issueTypeIconUrl: fields.issuetype.iconUrl,
		issueTypeName: fields.issuetype.name,
		issuePriorityUrl: (fields.priority && fields.priority.iconUrl) || undefined,
		issuePriorityName: (fields.priority && fields.priority.name) || undefined,
		issueKey: child.key,
		issueSummary: fields.summary,
		statusCategory: statusCategoryForId(fields.status.statusCategory.id),
		statusCategoryId: fields.status.statusCategory.id,
		statusName: fields.status.name,
		statusId: fields.status.id,
		...getAssigneeFields(fields.assignee),
		isLoading: false,
	};
};

export const transformChildren = (
	children: ServerAssociatedIssue[] | null = [],
	baseUrl: BaseUrl,
): ChildIssue[] => (!children ? [] : children.map((child) => transformChild(child, baseUrl)));
