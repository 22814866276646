import type { Worklog } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';

export const isNotEmptyComment = (comment: string | ADF | null) =>
	typeof comment === 'string' ? comment.trim() !== '' : !isOnlyWhitespaceAdf(comment);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (worklog: Worklog & { comment: Worklog['comment'] | string }): boolean => {
	const { comment } = worklog;
	if (!comment) {
		return false;
	}

	return typeof comment === 'string' ? comment.trim() !== '' : !isOnlyWhitespaceAdf(comment);
};
