import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { ActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type';

// SELECT_ACTIVITY_ITEM
export const SELECT_ACTIVITY_ITEM = 'SELECT_ACTIVITY_ITEM' as const;

export const setSelectedActivityItem = (activityItem: ActivityItem) => ({
	type: SELECT_ACTIVITY_ITEM,
	payload: activityItem,
});

export type SetSelectedActivityItem = ReturnType<typeof setSelectedActivityItem>;

// SELECT_INITIAL_ACTIVITY_ITEM
/**
 * Used to set the initiallyselected activity item without causing side effects
 * in epics
 */
export const SELECT_INITIAL_ACTIVITY_ITEM = 'SELECT_INITIAL_ACTIVITY_ITEM' as const;

export const setInitialSelectedActivityItem = (activityItem: ActivityItem) => ({
	type: SELECT_INITIAL_ACTIVITY_ITEM,
	payload: activityItem,
});

export type SetInitialSelectedActivityItem = ReturnType<typeof setInitialSelectedActivityItem>;

// SELECT_ACTIVITY_SORT_ORDER
export const SELECT_ACTIVITY_SORT_ORDER = 'SELECT_ACTIVITY_SORT_ORDER' as const;

export const setSelectedActivitySortOrder = (activitySortOrder: ActivitySortOrderType) => ({
	type: SELECT_ACTIVITY_SORT_ORDER,
	payload: activitySortOrder,
});

export type SetSelectedActivitySortOrder = ReturnType<typeof setSelectedActivitySortOrder>;

// UPDATE_ACTIVITY_SORT_ORDER_REQUEST
export const UPDATE_ACTIVITY_SORT_ORDER_REQUEST = 'UPDATE_ACTIVITY_SORT_ORDER_REQUEST' as const;

export type UpdateActivitySortOrderRequestAction = {
	type: typeof UPDATE_ACTIVITY_SORT_ORDER_REQUEST;
	payload: ActivitySortOrderType;
};

export const updateActivitySortOrderRequest = (
	sort: ActivitySortOrderType,
): UpdateActivitySortOrderRequestAction => ({
	type: UPDATE_ACTIVITY_SORT_ORDER_REQUEST,
	payload: sort,
});

export type UpdateActivitySortOrderRequest = ReturnType<typeof updateActivitySortOrderRequest>;

export type Action =
	| SetSelectedActivityItem
	| SetInitialSelectedActivityItem
	| SetSelectedActivitySortOrder;
