import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Lozenge from '@atlaskit/lozenge';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { R300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useForgeUiIssueData } from '@atlassian/jira-forge-controllers-extension-context';
import type { IssueContext } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useDynamicProperties } from '@atlassian/jira-forge-ui/src/services/dynamic-properties';
import type { ContextPanel } from '@atlassian/jira-issue-shared-types/src/common/types/context-panel-type.tsx';

const StatusInner = ({ status }: { status: ContextPanel['status'] }) => {
	// entity props is arbitrary so it's possible that it's missing information
	if (!status?.value?.label) {
		return null;
	}

	const { value, type } = status;
	switch (type) {
		case 'badge': {
			const num = parseInt(value.label || '', 10);
			if (num >= 0) {
				return (
					<StyledStatus>
						<Badge appearance="default">{num}</Badge>
					</StyledStatus>
				);
			}
			return null;
		}
		case 'icon': {
			return (
				<StyledStatus>
					<AsyncIcon url={value.label} width={24} height={24} />
				</StyledStatus>
			);
		}
		case 'lozenge': {
			return (
				<StyledStatus>
					<Lozenge appearance={value.type}>{value.label}</Lozenge>
				</StyledStatus>
			);
		}
		default:
			return null;
	}
};

const DynamicStatus = ({ extension }: { extension: IssueContext }) => {
	const issueData = useForgeUiIssueData();
	const { properties, error, isLoading, fetch } = useDynamicProperties(extension, issueData);

	useEffect(() => {
		fetch();
	}, [fetch]);

	if (isLoading) {
		return (
			<StyledStatus>
				<Spinner size="small" testId="issue-view-layout-group.ui.status.spinner" />
			</StyledStatus>
		);
	}

	if (error) {
		return (
			<StyledStatus>
				<ErrorIcon label="Error" primaryColor={token('color.icon.danger', R300)} />
			</StyledStatus>
		);
	}

	// ensure consistency with static statuses - StatusInner handles edge cases itself, so a typecast is fine
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return <StatusInner status={(properties as { status: ContextPanel['status'] })?.status} />;
};

export const Status = ({
	status,
	extension,
}: {
	status: ContextPanel['status'];
	extension?: ContextPanel['extension'];
}) => {
	const hasDynamicProperties = !!extension?.properties?.dynamicProperties;

	return hasDynamicProperties ? (
		<DynamicStatus extension={extension} />
	) : (
		<StatusInner status={status} />
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledStatus = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N70),
	display: 'flex',
	alignItems: 'center',
	marginRight: token('space.100', '8px'),
});
