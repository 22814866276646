import React, { useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { useIssueAbsoluteUrl } from '@atlassian/jira-issue-hooks/src/services/use-issue-absolute-url';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari';
import { useSubProductId } from '@atlassian/jira-issue-hooks/src/services/use-subproduct-id';
import { useShareKeyboardShortcutsStore } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/services/store.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import ShareButton, { ShareLoadingPlaceholder } from '@atlassian/jira-share-button';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants';
import { useShareIntegrations } from '@atlassian/jira-share-integrations';

export const ShareView = () => {
	const issueKey = useIssueKey();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = ff('relay-migration-issue-fields-summary_eog1v') ? useIssueId() : undefined;
	const [{ value: summary }] = useSummaryField({ issueKey, issueId });
	const issueAri = useIssueAri();
	const { shareIntegrations } = useShareIntegrations();
	const issueUrl = useIssueAbsoluteUrl();
	const subProductId = useSubProductId();

	const [shareDropdownTriggerCount, { resetComponent }] = useShareKeyboardShortcutsStore();

	const prevShareShortcutTriggerCount = usePreviousWithInitial(shareDropdownTriggerCount);

	// clean up - reset trigger when we unmount
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => resetComponent('shareDropdownTriggerCount'), []);

	return (
		<ShareButton
			integrationType={integrationTypes.BENTO_ISSUE}
			integrationMode={shareIntegrations && shareIntegrations.length > 0 ? 'tabs' : 'off'}
			objectAri={issueAri}
			productId="jira"
			shareContentType="issue"
			shareIntegrations={shareIntegrations}
			shareLink={issueUrl}
			shareTitle={summary ?? ''}
			shouldAutoDismissFlag={false}
			// @ts-expect-error - TS2322 - Type 'SubProductId' is not assignable to type 'ProductId'.
			subProductId={subProductId}
			triggerButtonStyle="icon-only"
			shareShortcutTriggerCount={shareDropdownTriggerCount}
			prevShareShortcutTriggerCount={prevShareShortcutTriggerCount}
		/>
	);
};

export default ShareView;

export { ShareLoadingPlaceholder };
