import 'rxjs/add/operator/bufferCount';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getSearchUrl } from './issue-urls';

type SearchResults<T> = {
	issues: T[];
};

const JQL_PAGE_SIZE = 50;

/**
 * transform search result to issue array
 * @param issues
 */
// @ts-expect-error - TS2741 - Property 'issues' is missing in type '{}' but required in type 'SearchResults<T>'.
const searchResultTransformer = <T,>({ issues = [] }: SearchResults<T> = {}): T[] => issues;

const search = (baseUrl: BaseUrl, jql: string, fields: Readonly<string[]>) =>
	fetchJson$(getSearchUrl(baseUrl), {
		method: 'POST',
		body: JSON.stringify({
			jql,
			fields,
		}),
		// @ts-expect-error - TS2345 - Argument of type '<T>({ issues, }?: SearchResults<T>) => T[]' is not assignable to parameter of type '(value: unknown, index: number) => unknown[]'.
	}).map(searchResultTransformer);

export const fetchBulkIssues = (
	baseUrl: BaseUrl,
	issueKeys: IssueKey[],
	fields: Readonly<string[]>,
) =>
	// @ts-expect-error - TS2769 - No overload matches this call.
	Observable.from(issueKeys)
		.bufferCount(JQL_PAGE_SIZE)
		.flatMap((keys) => search(baseUrl, `key in (${keys.join(',')})`, fields)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Array<any>>(
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			(issues, currentBufferedIssues) => [...issues, ...currentBufferedIssues],
			[],
		);
