import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { SET_CONTEXT } from '@atlassian/jira-issue-view-store/src/actions/context-actions';
import { navigateToNewIssue } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions';
import { contextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const navigateToNewIssueIfNecessary = (fromIssueKey: IssueKey, toIssueKey: IssueKey) =>
	fromIssueKey !== toIssueKey
		? Observable.of(navigateToNewIssue({ fromIssueKey, toIssueKey }))
		: Observable.empty<never>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(SET_CONTEXT)
		.map(() => contextSelector(store.getState()))
		.pairwise() // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.mergeMap(([prevContext, currentContext]: [any, any]) =>
			navigateToNewIssueIfNecessary(prevContext.issueKey, currentContext.issueKey),
		);
