import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { FORBIDDEN } from '@atlassian/jira-common-constants/src/http-status-codes';
import type { UserAuthResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-user-auth/index.tsx';
import { fetchUserAuth$ } from '@atlassian/jira-issue-fetch-services/src/services/issue-media-user-auth/index.tsx';
import { userAuthTransformer } from '@atlassian/jira-issue-media-provider/src/controllers/create-auth-provider/utils.tsx';
import { genericRetryStrategy } from '@atlassian/jira-issue-view-common-utils/src/rx/retry.tsx';
import { fallbackOnMissingOrError$ } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/utils/fallback-on-error';
import {
	fetchUserAuthSuccess,
	fetchUserAuthFailure,
} from '@atlassian/jira-issue-view-store/src/common/media/user-auth/user-auth-actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createFailureStream = (error: any) => Observable.of(fetchUserAuthFailure(error.statusCode));

export const fetchUserAuth = (
	baseUrl: string,
	prefetchedUserAuth?: Promise<UserAuthResponse> | null,
) => {
	// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<UserAuthResponse>'.
	const fetchUserAuthObservable = fallbackOnMissingOrError$(prefetchedUserAuth, () =>
		fetchUserAuth$(baseUrl),
	)
		.map(userAuthTransformer)
		.map(fetchUserAuthSuccess);

	return fetchUserAuthObservable
		.catch((error) => {
			if (error.statusCode === FORBIDDEN) {
				return createFailureStream(error);
			}
			throw error;
		})
		.retryWhen(genericRetryStrategy)
		.catch(createFailureStream);
};
