import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError, switchMap } from 'rxjs/operators';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { PersonalizationAPIResponse } from '@atlassian/jira-issue-shared-types/src/common/types/personalization-tap-traits-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { SET_CONTEXT } from '@atlassian/jira-issue-view-store/src/actions/context-actions';
import {
	personalizationTapTraitFetched,
	type PersonalizationTapTraitFetchAction,
} from '@atlassian/jira-issue-view-store/src/actions/personalization-fetch-tap-trait-action';
import {
	accountIdloggedInUserSelector,
	cloudIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';

const PERSONALIZATION_BASE_URL = '/gateway/api/tap-delivery/api/v3/personalization';
const HAS_SHOWN_INTENT_TO_XFLOW_TO_CONFLUENCE_TAP_TRAIT =
	'site_user_has_shown_intent_to_xflow_to_confluence';
const USER_QUERY_PARAM = '/user/';
const SITE_QUERY_PARAM = '/site/';
const LOG_LOCATION = 'issue.fetch.personalization-tap-traits-epic';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<PersonalizationTapTraitFetchAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(SET_CONTEXT).switchMap(() => {
		// Killswitch ff: https://app.launchdarkly.com/jira/production/features/reminder-nudge-for-failed-xflow_z8l8q/targeting
		if (ff('reminder-nudge-for-failed-xflow_z8l8q')) {
			const state: State = store.getState();
			const loggedInAccountId = accountIdloggedInUserSelector(state);
			const cloudId = cloudIdSelector(state);

			if (!loggedInAccountId || !cloudId) return Observable.empty<never>();

			// Reference doc: https://developer.atlassian.com/platform/targeting/overview/consumers/using-with-frontend-api/
			const userPersonalizationUrl =
				PERSONALIZATION_BASE_URL +
				SITE_QUERY_PARAM +
				cloudId +
				USER_QUERY_PARAM +
				loggedInAccountId;

			return fetchJson$<PersonalizationAPIResponse | null>(userPersonalizationUrl).pipe(
				switchMap((response) => {
					if (response && response.attributes) {
						const hasShownIntentToXflowToConfluence = response.attributes.some(
							(attribute) =>
								attribute.name === HAS_SHOWN_INTENT_TO_XFLOW_TO_CONFLUENCE_TAP_TRAIT &&
								attribute.value === true,
						);
						return Observable.of(personalizationTapTraitFetched(hasShownIntentToXflowToConfluence));
					}
					return Observable.of(personalizationTapTraitFetched(false));
				}),
				catchError((error) => {
					trackOrLogClientError(
						LOG_LOCATION,
						'Failed to fetch TAP traits via the personalization api',
						error,
					);
					return Observable.empty<never>();
				}),
			);
		}
		return Observable.empty<never>();
	});
