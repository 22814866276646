import React, { type MouseEvent } from 'react';
import noop from 'lodash/noop';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import AkTooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { truncateBreadcrumbs } from '../common/utils';
import {
	IconPaddingWrapper,
	ProjectIconWrapper,
	ProjectContainer,
	SmallBreadcrumbsItem,
} from '../styled';

export type Props = {
	shouldShowProjectLevelBreadcrumb: boolean;
	projectName: string;
	projectUrl: string;
	projectAvatarUrl: string;
	onClick: (event: MouseEvent) => void;
};

export const BreadcrumbProject = ({
	shouldShowProjectLevelBreadcrumb = false,
	projectName = '',
	projectUrl = '',
	projectAvatarUrl = '',
	onClick = noop,
}: Props) => {
	const showProjectBreadcrumb = Boolean(
		shouldShowProjectLevelBreadcrumb && projectName && projectUrl,
	);

	const asyncIcon = <AsyncIcon url={projectAvatarUrl} alt={projectName} />;
	const projectIcon = (
		<IconPaddingWrapper>
			<ProjectIconWrapper>{asyncIcon}</ProjectIconWrapper>
		</IconPaddingWrapper>
	);
	const breadcrumbsItem = (
		<BreadcrumbsItem
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.foundation.breadcrumbs.project.item"
			href={projectUrl}
			onClick={onClick}
			text={truncateBreadcrumbs(projectName)}
			truncationWidth={0}
			iconBefore={projectIcon}
			component={SmallBreadcrumbsItem}
		/>
	);

	return showProjectBreadcrumb ? (
		<ProjectContainer
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.breadcrumbs.project.container"
			fixExtraDivider
		>
			<AkTooltip
				content={projectName}
				position="top"
				delay={0}
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.project.tooltip"
			>
				{breadcrumbsItem}
			</AkTooltip>
		</ProjectContainer>
	) : null;
};

export default BreadcrumbProject;
