import forEach from 'lodash/forEach';
import type { IssueLink } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { ChangedIssueLink } from '@atlassian/jira-issue-view-model/src/change-type';
import type { FetchLinkedIssuesDataSuccess } from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions';
import { toIssueId, type IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getNewLinksFromAction = (
	issueId: IssueId,
	action: FetchLinkedIssuesDataSuccess,
): ChangedIssueLink[] => {
	const {
		payload: { optimisticLinks, issueLinks },
	} = action;
	const links: Array<ChangedIssueLink> = [];

	forEach(optimisticLinks, (optimisticLink: IssueLink) => {
		const linkedIssue = issueLinks.linkedIssues[optimisticLink.linkedIssueKey];

		if (linkedIssue) {
			links.push({
				linkedIssueId: toIssueId(linkedIssue.id),
				direction: optimisticLink.direction,
			});
		}
	});

	return links;
};
