import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import { filter } from 'rxjs/operators/filter';
import { mergeMap as flatMap } from 'rxjs/operators/mergeMap';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import { ATTACHMENT_JIRA_CREATE_SUCCESS } from '@atlassian/jira-issue-view-store/src/actions/attachment-picker-actions';
import {
	cardCoverFetchSuccess,
	cardCoverFetchFailure,
} from '@atlassian/jira-issue-view-store/src/actions/card-cover-actions';
import { FETCH_ISSUE_SUCCESS } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import { rapidViewIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { idSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { isCardCoverActionsEnabledSelector } from '@atlassian/jira-issue-view-store/src/selectors/card-cover-selectors';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

const getIssueMediaUrl = (issueId: IssueId): string =>
	`/rest/greenhopper/1.0/xboard/work/media?issueIds=${issueId}`;

const getAdditionalBoardDataUrl = (rapidViewId: number): string =>
	`/rest/greenhopper/1.0/xboard/work/additionalBoardData?rapidViewId=${rapidViewId}`;

const fetchIssueMedia = (state: State, issueId: IssueId): Observable<unknown> =>
	fetchJson$(getIssueMediaUrl(issueId), {
		method: 'GET',
	});

const fetchAdditionalBoardData = (state: State, rapidViewId: number): Observable<unknown> =>
	fetchJson$(getAdditionalBoardDataUrl(rapidViewId), {
		method: 'GET',
	});

const handleSuccess = (issueMedia: unknown, additionalBoardData: unknown) => {
	const isEnabled = get(additionalBoardData, 'isMediaOnCardsEnabled', false);

	const attachmentId = get(issueMedia, 'cardMedia[0].attachmentId', null);
	const availableAttachmentIds = get(issueMedia, 'cardMedia[0].availableAttachmentIds', []);

	return Observable.of(cardCoverFetchSuccess({ isEnabled, attachmentId, availableAttachmentIds }));
};

const handleFailure = (error: Error) => {
	trackOrLogClientError('issue.card-cover.fetch', error.message, error);
	return Observable.of(cardCoverFetchFailure());
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.pipe(
		ofTypeOrBatchType(FETCH_ISSUE_SUCCESS, ATTACHMENT_JIRA_CREATE_SUCCESS),
		filter(() => isCardCoverActionsEnabledSelector(store.getState())),
		flatMap(() => {
			const state = store.getState();
			const rapidViewId = rapidViewIdSelector(state);
			const issueId = idSelector(state);

			if (
				rapidViewId === null ||
				rapidViewId === undefined ||
				issueId === null ||
				issueId === undefined
			) {
				return Observable.empty<never>();
			}

			return Observable.zip(
				fetchIssueMedia(state, issueId),
				fetchAdditionalBoardData(state, rapidViewId),
			) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.flatMap(([issueMedia, additionalBoardData]: [any, any]) =>
					handleSuccess(issueMedia, additionalBoardData),
				)
				.catch((error) => handleFailure(error));
		}),
	);
