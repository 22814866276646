/**
 * ISSUE RESTRICTION IN ISSUE VIEW Screen
 * Details can be found here -
 * https://hello.atlassian.net/wiki/spaces/JPCE/pages/613261740/LDR+View+Issue+API+changes+for+supporting+Issue+Security+in+Next-Gen
 * https://hello.atlassian.net/wiki/spaces/JPCE/pages/574029166/Issue+restrictions+E2E+journey
 */

import React from 'react';
import AsyncIssueRestrictions from '@atlassian/jira-issue-restrictions/src/async.tsx';
import { ISSUE } from '@atlassian/jira-issue-restrictions/src/common/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { applicationEditionSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/application-selector';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	errorSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	currentIssueRestrictionSelector,
	isIssueRestrictionDisplaySelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/issue-restriction-selector';
import { isSubtaskSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector';
import { toProjectKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Props, RestrictionType } from './types';

const restrictionType: RestrictionType = ISSUE;

const IssueRestrictionWrapper = ({
	currentIssueRestriction,
	isIssueRestrictionDisplayForIssue,
	hasError,
	issueKey,
	projectKey,
	edition,
	isSubtask,
}: Props) => {
	if (!hasError && isIssueRestrictionDisplayForIssue) {
		if (!isSubtask || currentIssueRestriction.length > 0) {
			return (
				// @ts-expect-error - TS2322 - Type '{ entityKey: string; currentRestrictions: Value[]; restrictionType: string; projectKeyOrId: string; edition: ApplicationEdition; isSubtask: boolean; }' is not assignable to type 'IntrinsicAttributes & { children?: ReactNode; }'.
				<AsyncIssueRestrictions
					entityKey={toIssueKey(issueKey)}
					currentRestrictions={currentIssueRestriction}
					restrictionType={restrictionType}
					projectKeyOrId={toProjectKey(projectKey)}
					edition={edition}
					isSubtask={isSubtask}
				/>
			);
		}
	}
	return null;
};

export const IssueRestriction = flowWithSafeComponent(
	connect(
		(state: State) => ({
			currentIssueRestriction: currentIssueRestrictionSelector(state),
			isIssueRestrictionDisplayForIssue: isIssueRestrictionDisplaySelector(state),
			hasError: !!errorSelector(state),
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			projectKey: projectKeySelector(state)!,
			issueKey: issueKeySelector(state),
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			edition: applicationEditionSelector(state)!,
			isSubtask: isSubtaskSelector(state),
		}),
		{},
	),
)(IssueRestrictionWrapper);
