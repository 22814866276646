import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import {
	type IssueScrollActions,
	SCROLL_TARGET_TYPES,
} from '@atlassian/jira-issue-scroll/src/services/types.tsx';
import { NUM_INITIAL_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed';
import { WORKLOG } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import flagMessages from '@atlassian/jira-issue-view-common/src/messages/flags';
import { setInitialSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions';
import { SCROLL_TO_ATTACHMENT_WORKLOG } from '@atlassian/jira-issue-view-store/src/actions/issue-scroll-actions';
import {
	setWorklogScrollStatus,
	type Action,
	fetchWorklogsByIdSuccess,
	fetchWorklogsByIdFailure,
	type AddWorklogSuccessAction,
	FETCH_SURROUNDING_WORKLOGS_BY_ID,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector';
import { getUrlPermalinkParameter } from '@atlassian/jira-platform-issue-permalinks';
import { PermalinkType } from '@atlassian/jira-platform-issue-permalinks/src/constants.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { worklogExperienceDescription } from '../../common/experience-description';
import fetchSurroundingSortedWorklogsService from '../../services/fetch-surrounding-worklogs-sorted';
import { messages } from './messages';

const fetchObservable = (state: State, worklogId: string) => {
	const baseUrl = baseUrlSelector(state);
	const issueKey = toIssueKey(issueKeySelector(state));
	const selectedSortOrder = getSelectedActivitySortOrder(state);
	return fetchSurroundingSortedWorklogsService(
		baseUrl,
		issueKey,
		Math.floor(NUM_INITIAL_ITEMS_TO_LOAD),
		selectedSortOrder,
		worklogId,
	);
};

export const fetchSurroundingWorklogsByIdEpic = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(action$.ofType(FETCH_SURROUNDING_WORKLOGS_BY_ID) as Observable<AddWorklogSuccessAction>)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.switchMap((action) => {
			const state = store.getState();
			const projectType = projectTypeSelector(state);
			const { payload } = action;

			return fetchObservable(state, payload.worklogId)
				.map(({ totalWorklogs, worklogs, sortedWorklogIds, startIndex }) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_SURROUNDING',
								analyticsSource: 'fetchSurroundingWorklogsByIdEpic',
								projectType,
							}),
					});
					return fetchWorklogsByIdSuccess(
						sortedWorklogIds,
						worklogs,
						startIndex || 0,
						totalWorklogs,
					);
				})
				.catch((error) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_SURROUNDING',
								analyticsSource: 'fetchSurroundingWorklogsByIdEpic',
								projectType,
								errorMessage: error.message,
							}),

						error,
					});
					log.safeErrorWithoutCustomerData(
						'issue.activity.worklog.fetch-worklog-by-id',
						'Failed to load worklog list after adding new worklog',
						error,
					);

					return Observable.of(fetchWorklogsByIdFailure());
				});
		});

export const fetchSurroundingWorklogsAttachmentScroll =
	(issueScrollAction: IssueScrollActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(SCROLL_TO_ATTACHMENT_WORKLOG).switchMap(() => {
			const state = store.getState();
			const projectType = projectTypeSelector(state);
			const worklogId = issueScrollAction.getScrollTargetId(SCROLL_TARGET_TYPES.WORKLOG);

			// the store does not contain the right scroll target. Abort fetch call to backend
			if (worklogId === null) {
				return Observable.of();
			}

			return fetchObservable(state, worklogId)
				.flatMap(({ totalWorklogs, worklogs, sortedWorklogIds, startIndex }) => {
					const worklogActivityItem = {
						key: WORKLOG,
						type: WORKLOG,
						name: messages.worklog.defaultMessage,
					};
					// modify the url, so that specific worklogId can scroll within worklog-item-view.js
					window.history.replaceState(
						{},
						// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.
						null,
						getUrlPermalinkParameter(worklogId, PermalinkType.WORKLOG),
					);
					issueScrollAction.setScrollTarget(null);

					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_SURROUNDING',
								analyticsSource: 'fetchSurroundingWorklogsAttachmentScroll',
								projectType,
							}),
					});

					return Observable.of(
						fetchWorklogsByIdSuccess(sortedWorklogIds, worklogs, startIndex || 0, totalWorklogs),
						// @ts-expect-error - TS2769 - No overload matches this call. | TS2345 - Argument of type '{ key: "Worklog"; type: "Worklog"; name: string | undefined; }' is not assignable to parameter of type 'ActivityItem'.
						setInitialSelectedActivityItem(worklogActivityItem),
						setWorklogScrollStatus(false),
					);
				})
				.catch((error) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_SURROUNDING',
								analyticsSource: 'fetchSurroundingWorklogsAttachmentScroll',
								projectType,
								errorMessage: error.message,
							}),
						error,
					});
					log.safeErrorWithoutCustomerData(
						'issue.activity.worklog.fetch-worklog-by-id',
						'Failed to load worklog list after attempting worklog attachment fetch',
						error,
					);

					const { showFlag } = useFlagsService();
					const field = 'worklog';
					showFlag({
						type: 'error',
						title: [flagMessages.commentWorklogAttachmentFetchFailedTitle, { field }],
						description: flagMessages.commentWorklogAttachmentFetchFailedDescription,
						isAutoDismiss: true,
					});

					return Observable.of(fetchWorklogsByIdFailure());
				});
		});
