import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage';
import type { Storage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { loadDraftFailure, loadDraftSuccess, LOAD_DRAFT_REQUEST } from './draft-actions';
import { getFieldNameFromKey } from './draft-utils';

let localStorage: Storage;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadDraft = (action: any) => {
	const {
		payload: { fieldId, draftKey, draftHandler },
	} = action;
	const fieldName = getFieldNameFromKey(draftKey);

	const fieldDraft = localStorage.get(draftKey);
	if (fieldDraft) {
		if (isOnlyWhitespaceAdf(fieldDraft)) {
			return Observable.empty<never>();
		}
		const loadDraftObservable = draftHandler
			? Observable.of(draftHandler(fieldId, fieldDraft))
			: Observable.empty<never>();
		const loadedSuccessObservable = Observable.of(loadDraftSuccess(fieldName, fieldDraft));
		const loadDraftAndSuccess$ = Observable.concat(loadDraftObservable, loadedSuccessObservable);
		return loadDraftAndSuccess$.catch((error) => {
			trackOrLogClientError('issue.draft.fetch', 'Failed to fetch issue draft', error);
			return Observable.of(loadDraftFailure(fieldName, error));
		});
	}

	return Observable.empty<never>();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => Observable.of(loadDraftFailure(null, error));

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>): Observable<any> => {
	localStorage = createLocalStorageProvider('issue-drafts');
	return action$
		.ofType(LOAD_DRAFT_REQUEST)
		.mergeMap((action) => loadDraft(action))
		.catch((error) => errorHandler(error));
};
