import type { IssueFieldUnion as IssueServiceFieldUnion } from '@atlassian/jira-issue-fetch-services/src/types';
import type { IssueFieldUnion } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';

export const getByTypeName = <T,>(
	fields: IssueFieldUnion[] | IssueServiceFieldUnion[],
	typename: string, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
): T => fields.find((f) => f.__typename === typename) as any;

export const normalizeItemArray = <
	T extends {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	},
>(
	items: T[],
	key: string,
): {
	[key: string]: T;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const normalizedItems: Record<string, any> = {};
	items.forEach((item) => {
		normalizedItems[item[key]] = item;
	});
	return normalizedItems;
};
