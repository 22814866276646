import { createSelector } from 'reselect';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import {
	getApplicationForProject,
	type Application,
} from '@atlassian/jira-shared-types/src/application.tsx';
import { type ApplicationEditions, getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { appEditionsSelector } from './context-selector';
import { projectTypeSelector } from './issue-selector';

export const applicationSelector = createSelector(
	projectTypeSelector,
	(projectType: ProjectType | null) => (projectType ? getApplicationForProject(projectType) : null),
);

export const applicationEditionSelector = createSelector(
	[appEditionsSelector, applicationSelector],
	(editions: ApplicationEditions, application: Application | null) =>
		application ? getEdition(application, editions) : null,
);
