import React, { type ReactNode, type SyntheticEvent, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { LinkButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { Box, Text, Inline, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import {
	FireScreenAnalytics,
	fireUIAnalytics,
	ContextualAnalyticsData,
	MODAL,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { SpotlightTypes } from '../../common/types';
import { useActions } from '../../controllers';
import { useIsLoaded } from '../../controllers/selectors/remote-state-hooks';
import { useIsJoinCommunityFlagShown } from '../../controllers/selectors/spotlights-hooks';
import communityDark from './assets/community--dark.svg';
import communityLight from './assets/community--light.svg';
import messages from './messages';

const FLAG_APPEARANCE_DELAY = 10000; // 10 seconds
const FLAG_ID = 'join-community-flag-id';

type JoinCommunityFlagInternalProps = {
	onDismiss: () => void;
};

const JoinCommunityFlagInternal = ({ onDismiss }: JoinCommunityFlagInternalProps) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleAcceptClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'joinTheCommunity');
		onDismiss();
	};

	const handleDismissClick = (e: SyntheticEvent) => {
		e.preventDefault(); // we need to ignore the href, which is empty in this case, but necessary for UX (mouse-cursor)
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'dismiss');
		onDismiss();
	};

	return (
		<>
			<FireScreenAnalytics />
			<Container>
				<Image
					src={isDarkMode ? communityDark : communityLight}
					alt={formatMessage(messages.imageAlt)}
				/>
				<Box xcss={mainStyles}>
					{fg('jpd-visual-refresh_typography_aurora') ? (
						<Heading size="xsmall" as="h1">
							{formatMessage(messages.title)}
						</Heading>
					) : (
						<Title>{formatMessage(messages.title)}</Title>
					)}

					{fg('jpd-visual-refresh_typography_aurora') ? (
						<Box paddingBlockStart="space.150">
							<Text size="UNSAFE_small">
								{formatMessage(messages.description, {
									link1: (message: ReactNode) => (
										<Link
											href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Product-demo-Automation-for-Delivery/ba-p/2057152"
											target="_blank"
											rel="noopener noreferrer"
										>
											{message}
										</Link>
									),
									link2: (message: ReactNode) => (
										<Link
											href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Frequently-asked-questions-about-the-product-how-do-I/ba-p/2002378"
											target="_blank"
											rel="noopener noreferrer"
										>
											{message}
										</Link>
									),
								})}
							</Text>
						</Box>
					) : (
						<Description>
							{formatMessage(messages.description, {
								link1: (message: ReactNode) => (
									<Link
										href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Product-demo-Automation-for-Delivery/ba-p/2057152"
										target="_blank"
										rel="noopener noreferrer"
									>
										{message}
									</Link>
								),
								link2: (message: ReactNode) => (
									<Link
										href="https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Frequently-asked-questions-about-the-product-how-do-I/ba-p/2002378"
										target="_blank"
										rel="noopener noreferrer"
									>
										{message}
									</Link>
								),
							})}
						</Description>
					)}

					{fg('jpd-visual-refresh_typography_aurora') ? (
						<Box paddingBlockStart="space.150">
							<Inline space="space.150">
								<LinkButton
									appearance="discovery"
									href="https://community.atlassian.com/t5/Jira-Product-Discovery/ct-p/jpd"
									onClick={handleAcceptClick}
								>
									{formatMessage(messages.acceptButton)}
								</LinkButton>
								<LinkButton
									href="" // necessary for rendering the mouse cursor as pointer
									appearance="subtle"
									onClick={handleDismissClick}
								>
									{formatMessage(messages.dismissButton)}
								</LinkButton>
							</Inline>
						</Box>
					) : (
						<Actions>
							<PrimaryButton
								href="https://community.atlassian.com/t5/Jira-Product-Discovery/ct-p/jpd"
								target="_blank"
								rel="noopener noreferrer"
								onClick={handleAcceptClick}
							>
								{formatMessage(messages.acceptButton)}
							</PrimaryButton>
							<SecondaryButton onClick={handleDismissClick}>
								{formatMessage(messages.dismissButton)}
							</SecondaryButton>
						</Actions>
					)}
				</Box>
			</Container>
		</>
	);
};

export const JoinCommunityFlag = () => {
	const { showCustomFlag, dismissFlag } = useNotifications();
	const shouldShow = useIsJoinCommunityFlagShown();
	const isLoaded = useIsLoaded();
	const { closeSpotlight } = useActions();

	const handleClose = useCallback(() => {
		dismissFlag(FLAG_ID);
		closeSpotlight(SpotlightTypes.JOIN_COMMUNITY);
	}, [closeSpotlight, dismissFlag]);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isLoaded && shouldShow) {
			timeoutId = setTimeout(() => {
				showCustomFlag({
					id: FLAG_ID,
					render: (props) => (
						<ContextualAnalyticsData sourceName="joinCommunityFlag" sourceType={MODAL} {...props}>
							<JoinCommunityFlagInternal onDismiss={handleClose} />
						</ContextualAnalyticsData>
					),
				});
			}, FLAG_APPEARANCE_DELAY);
		}

		return () => {
			clearTimeout(timeoutId);
			dismissFlag(FLAG_ID);
		};
	}, [shouldShow, isLoaded, showCustomFlag, dismissFlag, handleClose]);

	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '520px',
	boxSizing: 'border-box',
	padding: token('space.300', '24px'),
	borderRadius: '8px',
	background: token('color.background.discovery', '#F3F0FF'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 12px rgba(9, 30, 66, 0.15)',
	),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h4({
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '20px',
	marginTop: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Actions = styled.div({
	marginTop: token('space.150', '12px'),
	display: 'flex',
	gap: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
});

const mainStyles = xcss({
	marginLeft: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	width: '84px',
	height: '117px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled.a({
	fontWeight: 500,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PrimaryButton = styled.a({
	fontWeight: 500,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	display: 'flex',
	alignItems: 'center',
	padding: `${token('space.075', '6px')} ${token('space.150', '12px')}`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.discovery.bold', colors.P300),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		textDecoration: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.inverse', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.discovery.bold.hovered', colors.P400),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.inverse', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.discovery.bold.pressed', colors.P500),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SecondaryButton = styled.button({
	fontWeight: 500,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	display: 'flex',
	alignItems: 'center',
	padding: `${token('space.075', '6px')} ${token('space.150', '12px')}`,
	background: 'none',
	border: 'none',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		textDecoration: 'underline',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.accent.gray', colors.N200),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.accent.gray.bolder', colors.N800),
	},
});
