import { WORKLOG } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { getPermalinkStatus } from '@atlassian/jira-platform-issue-permalinks/src';

const worklogParameter = () => {
	const { hasPermalink, permalinkId } = getPermalinkStatus(WORKLOG);

	return hasPermalink ? `worklogId: ${permalinkId}` : '';
};

// This will be removed when we remove fe.platform.spa.bento-resource-unmount-fix
export const getWorklogsQuery = (maxResults: number, startAt: number, orderBy: string) => `
    worklogs (startAt: ${startAt}, maxResults: ${maxResults}, orderBy: "${orderBy}", ${worklogParameter()}) {
        nodes {
            id
            author {
                accountId
                displayName
                avatarUrl
            }
            updateAuthor {
                accountId
                displayName
                avatarUrl
            }
            created
            started
            updated
            comment
            timeSpent
            timeSpentSeconds
        }
        totalCount
        startIndex
    }
`;
