// Note these actions are needed for Bento app to talk to the child-panel apps

import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
// SUBTASK_QUICK_ADD_CLICKED
export const SUBTASK_QUICK_ADD_CLICKED = 'SUBTASK_QUICK_ADD_CLICKED' as const;

export const subtaskQuickAddClicked = () => ({
	type: SUBTASK_QUICK_ADD_CLICKED,
});

// ISSUE_IN_EPIC_QUICK_ADD_CLICKED
export const ISSUE_IN_EPIC_QUICK_ADD_CLICKED = 'ISSUE_IN_EPIC_QUICK_ADD_CLICKED' as const;

export const issueInEpicQuickAddClicked = () => ({
	type: ISSUE_IN_EPIC_QUICK_ADD_CLICKED,
});

// CHILD_ISSUE_QUICK_ADD_CLICKED
export const CHILD_ISSUE_QUICK_ADD_CLICKED = 'CHILD_ISSUE_QUICK_ADD_CLICKED' as const;

export const childIssueQuickAddClicked = () => ({
	type: CHILD_ISSUE_QUICK_ADD_CLICKED,
});

// RESET_CHILD_ISSUE_QUICK_ADD_CLICKED
export const RESET_QUICK_ADD_CLICKED_COUNT = 'RESET_QUICK_ADD_CLICKED_COUNT' as const;

export const resetQuickAddClickedCount = () => ({
	type: RESET_QUICK_ADD_CLICKED_COUNT,
});

// CHILD_ISSUE_ADD_SUCCESS
export const CHILD_ISSUE_ADD_SUCCESS = 'CHILD_ISSUE_ADD_SUCCESS' as const;

export const childIssueAddSuccess = (childIssue: ChildIssue, fieldsData?: FieldsState) => ({
	type: CHILD_ISSUE_ADD_SUCCESS,
	payload: {
		childIssue,
		fieldsData,
	},
});

// SUBTASK_ADD_SUCCESS
// This needs to be a separate action from CHILD_ISSUE_ADD_SUCCESS because Classic Epics can have
// both child issues and subtasks. In Next-gen CHILD_ISSUE_* actions are always used.
export const SUBTASK_ADD_SUCCESS = 'SUBTASK_ADD_SUCCESS' as const;

export const subtaskAddSuccess = (subtask: ChildIssue, fieldsData?: FieldsState) => ({
	type: SUBTASK_ADD_SUCCESS,
	payload: {
		childIssue: subtask,
		fieldsData,
	},
});

// ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS
export const ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS = 'ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS' as const;

type IssueChildrenOrderChangeSuccessPayload = {
	issueIds: IssueId[];
	rankId: number;
	isRankAfter: boolean;
};

export const CHILD_ISSUE_FIELD_UPDATED = 'CHILD_ISSUE_FIELD_UPDATED' as const;

export const childIssueFieldUpdate = (childIssueId: IssueId) => ({
	type: CHILD_ISSUE_FIELD_UPDATED,
	payload: {
		childIssueId,
	},
});

export type IssueChildrenOrderChangeSuccessAction = {
	type: typeof ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS;
	payload: IssueChildrenOrderChangeSuccessPayload;
};

export const issueChildrenOrderChangeSuccess = ({
	issueIds,
	rankId,
	isRankAfter,
}: IssueChildrenOrderChangeSuccessPayload): IssueChildrenOrderChangeSuccessAction => ({
	type: ISSUE_CHILDREN_ORDER_CHANGE_SUCCESS,
	payload: {
		issueIds,
		rankId,
		isRankAfter,
	},
});

// CHILD_ISSUE_PANEL_ADD_CLICKED
export const CHILD_ISSUE_PANEL_ADD_CLICKED = 'CHILD_ISSUE_PANEL_ADD_CLICKED' as const;

export const childIssuePanelAddClick = () => ({
	type: CHILD_ISSUE_PANEL_ADD_CLICKED,
});

export type SubtaskQuickAddClickedAction = ReturnType<typeof subtaskQuickAddClicked>;
export type IssueInEpicQuickAddClickedAction = ReturnType<typeof issueInEpicQuickAddClicked>;
export type ChildIssueQuickAddClickedAction = ReturnType<typeof childIssueQuickAddClicked>;
export type ChildIssueAddSuccess = ReturnType<typeof childIssueAddSuccess>;
export type ResetQuickAddClickedCount = ReturnType<typeof resetQuickAddClickedCount>;
export type SubtaskAddSuccess = ReturnType<typeof subtaskAddSuccess>;
export type ChildIssueFieldUpdateAction = ReturnType<typeof childIssueFieldUpdate>;
