import filter from 'lodash/filter';
import pick from 'lodash/pick';
import type {
	ConfluencePages,
	ConfluencePageMap,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import {
	type DeleteConfluencePageLinkRequest,
	type CreateConfluencePageLinkRequest,
	type CreateConfluencePageLinkFailed,
	type CreateConfluencePageLinkModified,
	type LinkConfluencePageClosed,
	type CreateConfluencePageLinkSuccess,
	type LinkConfluencePageClicked,
	LINK_CONFLUENCE_PAGE_CLICKED,
	LINK_CONFLUENCE_PAGE_CLOSED,
	CREATE_CONFLUENCE_PAGE_LINK_REQUEST,
	CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
	CREATE_CONFLUENCE_PAGE_LINK_FAILED,
	CREATE_CONFLUENCE_PAGE_LINK_MODIFIED,
	DELETE_CONFLUENCE_PAGE_LINK_REQUEST,
} from '../../actions/confluence-pages-actions';
import {
	type FetchIssueRemoteDataSuccess,
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
} from '../../actions/issue-remote-data-actions';
import {
	PREVIEW_DATA_FETCHED,
	type PreviewDataFetchedAction,
} from '../../issue-field/state/actions/field-preview-actions';

type State = ConfluencePages;

type Action =
	| FetchIssueRemoteDataSuccess
	| PreviewDataFetchedAction
	| DeleteConfluencePageLinkRequest
	| CreateConfluencePageLinkRequest
	| CreateConfluencePageLinkFailed
	| CreateConfluencePageLinkModified
	| LinkConfluencePageClosed
	| CreateConfluencePageLinkSuccess
	| LinkConfluencePageClicked;

export const initialState: State = {
	isLinkingEnabled: true, // Default to true https://jdog.jira-dev.com/browse/BENTO-3775
	createLinkedPage: {
		clickedCount: 0,
		isLoading: false,
		isInvalid: false,
		errorMessage: '',
		repairLink: undefined,
	},
	linkedPages: {
		pages: [],
		pageCount: 0,
	},
	mentionedPages: {
		pages: [],
		pageCount: 0,
	},
};

const PAGE_KEYS: (keyof ConfluencePageMap)[] = ['linkedPages', 'mentionedPages'];

const removePageFromConfluencePages = (state: State, idToRemove: string): ConfluencePageMap =>
	PAGE_KEYS.reduce<ConfluencePageMap>(
		(pagesMap, key) => {
			const { pages } = pagesMap[key];
			const filteredPages = filter(pages, (page) => {
				// @ts-expect-error - TS2339 - Property 'link' does not exist on type 'FailedRemoteLink | ConfluencePage'. | TS2339 - Property 'link' does not exist on type 'FailedRemoteLink | ConfluencePage'. | TS2339 - Property 'globalId' does not exist on type 'FailedRemoteLink | ConfluencePage'.
				const globalId = page.link ? page.link.globalId : page.globalId;
				return globalId !== idToRemove;
			});

			return {
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...pagesMap,
				[key]: {
					pageCount: filteredPages.length,
					pages: filteredPages,
				},
			};
		},
		pick(state, PAGE_KEYS),
	);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case FETCH_ISSUE_REMOTE_DATA_SUCCESS: {
			const { remoteLinks } = action.payload;

			const pages = pick(remoteLinks.confluencePages, PAGE_KEYS);
			const nextState = {
				...state,
				...pages,
				createLinkedPage: {
					...state.createLinkedPage,
					isLoading: false,
					isInvalid: false,
					errorMessage: '',
					repairLink: undefined,
				},
			};

			return {
				...nextState,
				isLinkingEnabled: !!remoteLinks.isLinkingEnabled,
			};
		}

		case PREVIEW_DATA_FETCHED: {
			const { remoteLinks } = action.payload;

			if (remoteLinks && remoteLinks.confluencePages) {
				const { linkedPages, mentionedPages } = remoteLinks.confluencePages;
				return {
					...state,
					linkedPages: {
						...state.linkedPages,
						pageCount: linkedPages.pageCount,
					},
					mentionedPages: {
						...state.mentionedPages,
						pageCount: mentionedPages.pageCount,
					},
				};
			}

			return state;
		}

		case DELETE_CONFLUENCE_PAGE_LINK_REQUEST: {
			const { globalId } = action.payload;
			const pages = removePageFromConfluencePages(state, globalId);

			return {
				...state,
				...pages,
			};
		}

		case CREATE_CONFLUENCE_PAGE_LINK_REQUEST:
			return {
				...state,
				createLinkedPage: {
					...state.createLinkedPage,
					isLoading: true,
				},
			};

		case CREATE_CONFLUENCE_PAGE_LINK_FAILED: {
			const { errorMessage } = action.payload;

			return {
				...state,
				createLinkedPage: {
					...state.createLinkedPage,
					errorMessage,
					isLoading: false,
					isInvalid: true,
					...(typeof action.payload.repairLink === 'string'
						? { repairLink: action.payload.repairLink }
						: undefined),
				},
			};
		}

		case CREATE_CONFLUENCE_PAGE_LINK_MODIFIED:
			return {
				...state,
				createLinkedPage: {
					...state.createLinkedPage,
					errorMessage: '',
					isInvalid: false,
				},
			};

		case LINK_CONFLUENCE_PAGE_CLOSED:
			return {
				...state,
				createLinkedPage: initialState.createLinkedPage,
			};

		case CREATE_CONFLUENCE_PAGE_LINK_SUCCESS: {
			const pages = state.linkedPages.pages || [];
			return {
				...state,
				linkedPages: {
					...state.linkedPages,
					pages: [...pages, action.payload.confluencePageLink],
					pageCount: state.linkedPages.pageCount + 1,
				},
				createLinkedPage: {
					...state.createLinkedPage,
					isLoading: false,
					isInvalid: false,
					errorMessage: '',
					repairLink: undefined,
				},
			};
		}

		case LINK_CONFLUENCE_PAGE_CLICKED: {
			return {
				...state,
				createLinkedPage: {
					...state.createLinkedPage,
					clickedCount: state.createLinkedPage.clickedCount + 1,
				},
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
