import { createSelector } from 'reselect';
import camelCase from 'lodash/camelCase';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import {
	ProjectStyle,
	SERVICE_DESK_PROJECT_ANALYTICS,
} from '@atlassian/jira-common-constants/src/project-types';
import { categoryIdForStatusCategory } from '@atlassian/jira-common-constants/src/status-categories';
import {
	CONFLUENCE_CONTROLS_STORAGE_KEY,
	ConfluenceFeatureKeys,
	CONFLUENCE_ACCESS_STORAGE_KEY,
	CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY,
	CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY,
} from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import { getSessionStorageData } from '@atlassian/jira-confluence-integration-controls/src/services/session-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import type { AnalyticsPayload } from '@atlassian/jira-issue-view-common-types/src/analytics-types';
import { selectorWithCondition } from '@atlassian/jira-issue-view-common-utils/src/selector-with-condition/index.tsx';
import { ASSIGNEE } from '@atlassian/jira-issue-view-configurations';
import {
	analyticsSourceSelector,
	boardIdSelector,
	boardTypeSelector,
	issueIdSelector as objectIdSelector,
	accountIdloggedInUserSelector,
} from '../common/state/selectors/context-selector';
import { fieldSelector } from '../common/state/selectors/field-selector';
import {
	idSelector,
	isSimplifiedProjectSelector,
	projectIdSelector as containerIdSelector,
	projectIdSelector,
	projectTypeSelector,
} from '../common/state/selectors/issue-selector';
import { ecosystemGlancesSelector } from '../ecosystem/ecosystem-extensions-selector';
import { issueTypeIdSelector, issueTypeSelector } from './breadcrumbs-selector';
import { totalCommentsSelector } from './comment-selector';
import { practicesSelector } from './practices-selector';
import { statusCategorySelector } from './status-selector';
import { teamIdSelector } from './team-id-selector';

const assigneeIdSelector = createSelector(fieldSelector(ASSIGNEE), (assignee) =>
	assignee && assignee.value ? assignee.value.accountId : null,
);

export const payloadSelectorWithTeamId = createSelector(
	analyticsSourceSelector,
	idSelector,
	projectIdSelector,
	projectTypeSelector,
	isSimplifiedProjectSelector,
	issueTypeIdSelector,
	statusCategorySelector,
	boardTypeSelector,
	boardIdSelector,
	containerIdSelector,
	objectIdSelector,
	assigneeIdSelector,
	practicesSelector,
	// @ts-expect-error - TS2554 - Expected 2-13 arguments, but got 16.
	ecosystemGlancesSelector,
	issueTypeSelector,
	accountIdloggedInUserSelector,
	totalCommentsSelector,
	teamIdSelector,
	(
		// @ts-expect-error - TS7006 - Parameter 'analyticsSource' implicitly has an 'any' type.
		analyticsSource,
		// @ts-expect-error - TS7006 - Parameter 'issueId' implicitly has an 'any' type.
		issueId,
		// @ts-expect-error - TS7006 - Parameter 'projectId' implicitly has an 'any' type.
		projectId,
		// @ts-expect-error - TS7006 - Parameter 'projectType' implicitly has an 'any' type.
		projectType,
		// @ts-expect-error - TS7006 - Parameter 'isSimplifiedProject' implicitly has an 'any' type.
		isSimplifiedProject,
		// @ts-expect-error - TS7006 - Parameter 'issueTypeId' implicitly has an 'any' type.
		issueTypeId,
		// @ts-expect-error - TS7006 - Parameter 'statusCategory' implicitly has an 'any' type.
		statusCategory,
		// @ts-expect-error - TS7006 - Parameter 'boardType' implicitly has an 'any' type.
		boardType,
		// @ts-expect-error - TS7006 - Parameter 'boardId' implicitly has an 'any' type.
		boardId,
		// @ts-expect-error - TS7006 - Parameter 'containerId' implicitly has an 'any' type.
		containerId,
		// @ts-expect-error - TS7006 - Parameter 'objectId' implicitly has an 'any' type.
		objectId,
		// @ts-expect-error - TS7006 - Parameter 'assigneeId' implicitly has an 'any' type.
		assigneeId,
		// @ts-expect-error - TS7006 - Parameter 'practice' implicitly has an 'any' type.
		practice,
		// @ts-expect-error - TS7006 - Parameter 'ecosystemGlances' implicitly has an 'any' type.
		ecosystemGlances,
		// @ts-expect-error - TS7006 - Parameter 'issueType' implicitly has an 'any' type.
		issueType,
		// @ts-expect-error - TS7006 - Parameter 'currentUserId' implicitly has an 'any' type.
		currentUserId,
		totalComments: number,
		// @ts-expect-error - TS7006 - Parameter 'analyticsMetadata' implicitly has an 'any' type.
		teamId,
	) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const conditionalAttributes: Record<string, any> = {};
		if (analyticsSource) {
			conditionalAttributes.analyticsSource = analyticsSource;
		}
		if (camelCase(projectType) === SERVICE_DESK_PROJECT_ANALYTICS && practice) {
			conditionalAttributes.itsmPractice = practice;
		}
		const confluenceControlsStorage = createSessionStorageProvider(
			CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY,
		);
		const confluenceControlsCache = getSessionStorageData(
			CONFLUENCE_CONTROLS_STORAGE_KEY,
			confluenceControlsStorage,
		);
		const confluencePermissionsStorage = createSessionStorageProvider(
			CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY,
		);
		const confluencePermissionsCache = getSessionStorageData(
			CONFLUENCE_ACCESS_STORAGE_KEY,
			confluencePermissionsStorage,
		);

		conditionalAttributes.confluenceIntegrations = {
			quickActionContentMenuShown: confluenceControlsCache
				? confluenceControlsCache[ConfluenceFeatureKeys.CREATE_MENU_TOP_ACTIONS]?.value
				: undefined,
			createLinkContentMenuShown: confluenceControlsCache
				? confluenceControlsCache[ConfluenceFeatureKeys.CREATE_MENU_CONFLUENCE_CONTENT]?.value
				: undefined,
			useHasConfluenceSeat: confluencePermissionsCache
				? confluencePermissionsCache.permitted
				: undefined,
		};

		let glancePanelAnalytics;

		if (ecosystemGlances) {
			glancePanelAnalytics = {
				glancePanel: ecosystemGlances
					// Forge panels are only available sometimes because of a race condition.
					// Find these analytics on `viewed jiraForgeUi.issueGlanceTriggerScreen` instead
					// @ts-expect-error - TS7006 - Parameter 'glancePanel' implicitly has an 'any' type.
					.filter((glancePanel) => glancePanel.glanceType !== FORGE_ENTITY_TYPE)
					// @ts-expect-error - TS7006 - Parameter 'glancePanel' implicitly has an 'any' type.
					.map((glancePanel) => glancePanel.appKey),
			};
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			containerType: 'project',
			containerId,
			objectType: 'issue',
			objectId,
			attributes: {
				...conditionalAttributes,
				designVersion: 'v2-bento',
				projectId,
				issueId,
				projectConfig: isSimplifiedProject ? ProjectStyle.NEXT_GEN : ProjectStyle.CLASSIC,
				projectType: camelCase(projectType),
				nextGenProject: Boolean(isSimplifiedProject),
				statusCategory,
				statusCategoryId: statusCategory ? categoryIdForStatusCategory(statusCategory) : null,
				issueTypeId,
				boardType,
				boardId,
				assigneeId,
				issueType: ['bug', 'story', 'epic', 'task', 'subtask'].includes(
					issueType?.name?.toLowerCase(),
				)
					? issueType.name.toLowerCase()
					: 'other',
				...glancePanelAnalytics,
				totalComments,
				teamId,
			},
		} as AnalyticsPayload;
	},
);

export const legacyPayloadSelector = createSelector(
	analyticsSourceSelector,
	idSelector,
	projectIdSelector,
	projectTypeSelector,
	isSimplifiedProjectSelector,
	issueTypeIdSelector,
	statusCategorySelector,
	boardTypeSelector,
	boardIdSelector,
	containerIdSelector,
	objectIdSelector,
	assigneeIdSelector,
	practicesSelector,
	// @ts-expect-error - TS2554 - Expected 2-13 arguments, but got 16.
	ecosystemGlancesSelector,
	issueTypeSelector,
	accountIdloggedInUserSelector,
	totalCommentsSelector,
	(
		// @ts-expect-error - TS7006 - Parameter 'analyticsSource' implicitly has an 'any' type.
		analyticsSource,
		// @ts-expect-error - TS7006 - Parameter 'issueId' implicitly has an 'any' type.
		issueId,
		// @ts-expect-error - TS7006 - Parameter 'projectId' implicitly has an 'any' type.
		projectId,
		// @ts-expect-error - TS7006 - Parameter 'projectType' implicitly has an 'any' type.
		projectType,
		// @ts-expect-error - TS7006 - Parameter 'isSimplifiedProject' implicitly has an 'any' type.
		isSimplifiedProject,
		// @ts-expect-error - TS7006 - Parameter 'issueTypeId' implicitly has an 'any' type.
		issueTypeId,
		// @ts-expect-error - TS7006 - Parameter 'statusCategory' implicitly has an 'any' type.
		statusCategory,
		// @ts-expect-error - TS7006 - Parameter 'boardType' implicitly has an 'any' type.
		boardType,
		// @ts-expect-error - TS7006 - Parameter 'boardId' implicitly has an 'any' type.
		boardId,
		// @ts-expect-error - TS7006 - Parameter 'containerId' implicitly has an 'any' type.
		containerId,
		// @ts-expect-error - TS7006 - Parameter 'objectId' implicitly has an 'any' type.
		objectId,
		// @ts-expect-error - TS7006 - Parameter 'assigneeId' implicitly has an 'any' type.
		assigneeId,
		// @ts-expect-error - TS7006 - Parameter 'practice' implicitly has an 'any' type.
		practice,
		// @ts-expect-error - TS7006 - Parameter 'ecosystemGlances' implicitly has an 'any' type.
		ecosystemGlances,
		// @ts-expect-error - TS7006 - Parameter 'issueType' implicitly has an 'any' type.
		issueType,
		// @ts-expect-error - TS7006 - Parameter 'currentUserId' implicitly has an 'any' type.
		currentUserId,
		totalComments: number,
	) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const conditionalAttributes: Record<string, any> = {};
		if (analyticsSource) {
			conditionalAttributes.analyticsSource = analyticsSource;
		}
		if (camelCase(projectType) === SERVICE_DESK_PROJECT_ANALYTICS && practice) {
			conditionalAttributes.itsmPractice = practice;
		}
		const confluenceControlsStorage = createSessionStorageProvider(
			CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY,
		);
		const confluenceControlsCache = getSessionStorageData(
			CONFLUENCE_CONTROLS_STORAGE_KEY,
			confluenceControlsStorage,
		);
		const confluencePermissionsStorage = createSessionStorageProvider(
			CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY,
		);
		const confluencePermissionsCache = getSessionStorageData(
			CONFLUENCE_ACCESS_STORAGE_KEY,
			confluencePermissionsStorage,
		);

		conditionalAttributes.confluenceIntegrations = {
			quickActionContentMenuShown: confluenceControlsCache
				? confluenceControlsCache[ConfluenceFeatureKeys.CREATE_MENU_TOP_ACTIONS]?.value
				: undefined,
			createLinkContentMenuShown: confluenceControlsCache
				? confluenceControlsCache[ConfluenceFeatureKeys.CREATE_MENU_CONFLUENCE_CONTENT]?.value
				: undefined,
			useHasConfluenceSeat: confluencePermissionsCache
				? confluencePermissionsCache.permitted
				: undefined,
		};

		let glancePanelAnalytics;

		if (ecosystemGlances) {
			glancePanelAnalytics = {
				glancePanel: ecosystemGlances
					// Forge panels are only available sometimes because of a race condition.
					// Find these analytics on `viewed jiraForgeUi.issueGlanceTriggerScreen` instead
					// @ts-expect-error - TS7006 - Parameter 'glancePanel' implicitly has an 'any' type.
					.filter((glancePanel) => glancePanel.glanceType !== FORGE_ENTITY_TYPE)
					// @ts-expect-error - TS7006 - Parameter 'glancePanel' implicitly has an 'any' type.
					.map((glancePanel) => glancePanel.appKey),
			};
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			containerType: 'project',
			containerId,
			objectType: 'issue',
			objectId,
			attributes: {
				...conditionalAttributes,
				designVersion: 'v2-bento',
				projectId,
				issueId,
				projectConfig: isSimplifiedProject ? ProjectStyle.NEXT_GEN : ProjectStyle.CLASSIC,
				projectType: camelCase(projectType),
				nextGenProject: Boolean(isSimplifiedProject),
				statusCategory,
				statusCategoryId: statusCategory ? categoryIdForStatusCategory(statusCategory) : null,
				issueTypeId,
				boardType,
				boardId,
				assigneeId,
				issueType: ['bug', 'story', 'epic', 'task', 'subtask'].includes(
					issueType?.name?.toLowerCase(),
				)
					? issueType.name.toLowerCase()
					: 'other',
				...glancePanelAnalytics,
				totalComments,
			},
		} as AnalyticsPayload;
	},
);

export const analyticsPayloadSelector = selectorWithCondition(
	() => fg('add-team-id-analytics-data'),
	payloadSelectorWithTeamId,
	legacyPayloadSelector,
);
