import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { async } from 'rxjs/scheduler/async';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage';
import type { Storage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '../common/state/selectors/context-selector';
import {
	saveDraftSuccess,
	saveDraftError,
	type DraftsAction,
	SAVE_DRAFT_REQUEST,
	DELETE_DRAFT_SUCCESS,
} from './draft-actions';
import { getDraftKey } from './draft-utils';

const saveDraftDelay = 1000;

let localStorage: Storage;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveDraft = (action: any, state: State) => {
	const {
		payload: { fieldId, value },
	} = action;

	const issueKey = issueKeySelector(state);
	const loggedInAccountId = accountIdloggedInUserSelector(state);
	const draftKey = getDraftKey(loggedInAccountId, issueKey, fieldId);

	localStorage.set(draftKey, value);
	return Observable.of(saveDraftSuccess(draftKey, value));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError('issue.save-draft', 'Failed to save draft', error);
	return Observable.of(saveDraftError(error));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<DraftsAction>, store: MiddlewareAPI<State>) => {
	localStorage = createLocalStorageProvider('issue-drafts');

	return action$
		.ofType(SAVE_DRAFT_REQUEST, DELETE_DRAFT_SUCCESS)
		.throttleTime(saveDraftDelay, async, { leading: true, trailing: true })
		.switchMap((action) => {
			if (action.type === SAVE_DRAFT_REQUEST) {
				return saveDraft(action, store.getState());
			}
			return Observable.empty<never>();
		})
		.catch((error) => errorHandler(error));
};
