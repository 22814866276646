import { useMemo, useEffect } from 'react';
import type { PreloadedQuery } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IssueAggData } from '@atlassian/jira-issue-fetch-services/src/services/issue-agg-data/index.tsx';
import { useSelfClearingPrefetchedResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import type { mainIssueAggQuery as mainIssueAggQueryType } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { useResource } from '@atlassian/jira-router';
import { issueForgeDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-forge-data/index.tsx';
import { issueGiraDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-data/index.tsx';
import { issueGiraNonCriticalDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-non-critical-data/index.tsx';

function useForgeRouteResourceInResourceManager(resourceManager: ResourceManager) {
	const { promise: issueForgePromise } = useResource(issueForgeDataResource);
	useMemo(() => {
		if (issueForgePromise) {
			resourceManager.setForgePromise(issueForgePromise);
		}
	}, [issueForgePromise, resourceManager]);
}

function useGiraRouteResourceInResourceManager(resourceManager: ResourceManager) {
	const resourceManagerToUpdate = resourceManager;
	const { data: issueGiraData, promise: issueGiraDataPromise } = useResource(issueGiraDataResource);
	useMemo(() => {
		if (issueGiraDataPromise) {
			resourceManagerToUpdate.setGiraPromise(issueGiraDataPromise);
			resourceManagerToUpdate.allData.issueGiraData = issueGiraData;
		}
	}, [issueGiraData, issueGiraDataPromise, resourceManagerToUpdate]);
}

function useGiraNonCriticalRouteResourceInResourceManager(resourceManager: ResourceManager) {
	const resourceManagerToUpdate = resourceManager;
	const { promise: issueGiraNonCriticalDataPromise } = useResource(
		issueGiraNonCriticalDataResource,
	);
	useMemo(() => {
		if (issueGiraNonCriticalDataPromise) {
			resourceManagerToUpdate.setGiraNonCriticalPromise(issueGiraNonCriticalDataPromise);
		}
	}, [issueGiraNonCriticalDataPromise, resourceManagerToUpdate]);
}

const useRelayPreloadedQueryInResourceManager = ({
	resourceManager,
	preloadedQuery,
}: {
	resourceManager: ResourceManager;
	preloadedQuery: PreloadedQuery<mainIssueAggQueryType> | null;
}) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const preloadedQueryPromise = useMemo(
		() => preloadedQuery?.source?.toPromise(),
		[preloadedQuery?.source],
	) as Promise<IssueAggData> | undefined;

	useMemo(() => {
		if (preloadedQueryPromise) {
			resourceManager.setAggPromise(preloadedQueryPromise);
		}
	}, [preloadedQueryPromise, resourceManager]);

	// Make sure that we keep prefetchedResourceManager up to date with the results of any query kicked off by preloadedQuery
	// This allows AppProvider to use prefetched-data-consumption
	useEffect(() => {
		const subscription = preloadedQuery?.source?.subscribe({
			next: (response) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				resourceManager.setAggData(response as IssueAggData);
			},
		});
		return () => subscription?.unsubscribe(); // Make sure to unsubscribe from stale in-progress fetches
	}, [resourceManager, preloadedQuery?.source]);
};

/**
 * Provides a self-clearing PrefetchedResourceManager which is subscribed to the
 * relevant route resources for the issue-view.
 *
 * Only ONE self-clearing PrefetchedResourceManager hooks should be present per-issue-view app
 */
export const useIssueRouteResourceManager = ({
	issueKey,
	preloadedQuery,
}: {
	issueKey: string;
	preloadedQuery: PreloadedQuery<mainIssueAggQueryType> | null;
}) => {
	const resourceManager = useSelfClearingPrefetchedResourceManager(issueKey);

	useForgeRouteResourceInResourceManager(resourceManager);
	useGiraRouteResourceInResourceManager(resourceManager);
	useRelayPreloadedQueryInResourceManager({ resourceManager, preloadedQuery });

	if (ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useGiraNonCriticalRouteResourceInResourceManager(resourceManager);
	}

	return resourceManager;
};
