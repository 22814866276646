import type { Issue } from '@atlassian/jira-assign-issue-parent-modal';
import type {
	IssueParent,
	IssueParentsWithMessage,
} from '@atlassian/jira-issue-parent-services/src/services/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// SHOW_ASSIGN_ISSUE_PARENT_MODAL
export const SHOW_ASSIGN_ISSUE_PARENT_MODAL = 'SHOW_ASSIGN_ISSUE_PARENT_MODAL' as const;

export const showAssignIssueParentModal = () => ({
	type: SHOW_ASSIGN_ISSUE_PARENT_MODAL,
});
export type ShowAssignIssueParentModal = {
	type: typeof SHOW_ASSIGN_ISSUE_PARENT_MODAL;
};

// HIDE_ASSIGN_ISSUE_PARENT_MODAL
export const HIDE_ASSIGN_ISSUE_PARENT_MODAL = 'HIDE_ASSIGN_ISSUE_PARENT_MODAL' as const;

export const hideAssignIssueParentModal = () => ({
	type: HIDE_ASSIGN_ISSUE_PARENT_MODAL,
});

// FETCH_ISSUE_PARENTS__WITH_INFO_REQUEST
export const FETCH_ISSUE_PARENTS_WITH_INFO_REQUEST =
	'FETCH_ISSUE_PARENTS_WITH_INFO_REQUEST' as const;

export type FetchIssueParentsWithInfoRequest = {
	type: typeof FETCH_ISSUE_PARENTS_WITH_INFO_REQUEST;
};

export const fetchIssueParentsWithInfoRequest = (): FetchIssueParentsWithInfoRequest => ({
	type: FETCH_ISSUE_PARENTS_WITH_INFO_REQUEST,
});

export type FetchIssueParentsWithInfoRequestAction = ReturnType<
	typeof fetchIssueParentsWithInfoRequest
>;

// FETCH_ISSUE_PARENTS_SUCCESS
export const FETCH_ISSUE_PARENTS_SUCCESS = 'FETCH_ISSUE_PARENTS_SUCCESS' as const;

// FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS
export const FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS =
	'FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS' as const;

export type FetchIssueParentsSuccess = {
	type: typeof FETCH_ISSUE_PARENTS_SUCCESS;
	payload: IssueParent[];
};

export type FetchIssueParentsWithInfoSuccess = {
	type: typeof FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS;
	payload: IssueParentsWithMessage;
};

export const fetchIssueParentsSuccess = (
	issueParents: IssueParent[] = [],
): FetchIssueParentsSuccess => ({
	type: FETCH_ISSUE_PARENTS_SUCCESS,
	payload: issueParents,
});

export const fetchIssueParentsWithInfoSuccess = (
	issueParentsWithInfo: IssueParentsWithMessage = { candidates: [] },
): FetchIssueParentsWithInfoSuccess => ({
	type: FETCH_ISSUE_PARENTS_WITH_INFO_SUCCESS,
	payload: issueParentsWithInfo,
});

export type FetchIssueParentsSuccessAction = ReturnType<typeof fetchIssueParentsSuccess>;

// FETCH_ISSUE_PARENTS_FAILURE
export const FETCH_ISSUE_PARENTS_FAILURE = 'FETCH_ISSUE_PARENTS_FAILURE' as const;

export type FetchIssueParentsFailure = {
	type: typeof FETCH_ISSUE_PARENTS_FAILURE;
};

export const fetchIssueParentsFailure = (): FetchIssueParentsFailure => ({
	type: FETCH_ISSUE_PARENTS_FAILURE,
});

export type FetchIssueParentsFailureAction = ReturnType<typeof fetchIssueParentsFailure>;

// SET_PARENT_REQUEST
export const SET_PARENT_REQUEST = 'SET_PARENT_REQUEST' as const;

export const setParentRequest = (issues: Issue[], parentId: IssueId) => ({
	type: SET_PARENT_REQUEST,
	payload: {
		issues,
		parentId,
	},
});

export type SetParentRequestAction = ReturnType<typeof setParentRequest>;

// SET_PARENT_OPTIMISTIC
export const SET_PARENT_OPTIMISTIC = 'SET_PARENT_OPTIMISTIC' as const;

export const setParentOptimistic = (
	issues: Issue[],
	parentIssue: IssueParent | null,
	requestPromise: Promise<undefined | unknown>,
) => ({
	type: SET_PARENT_OPTIMISTIC,
	payload: {
		issues,
		parentIssue,
		requestPromise,
	},
});

export type SetParentOptimisticAction = ReturnType<typeof setParentOptimistic>;

// UNSET_PARENT_REQUEST
export const UNSET_PARENT_REQUEST = 'UNSET_PARENT_REQUEST' as const;

export const unsetParentRequest = (issues: Issue[]) => ({
	type: UNSET_PARENT_REQUEST,
	payload: {
		issues,
	},
});

export type UnsetParentRequestAction = ReturnType<typeof unsetParentRequest>;

// SET_PARENT_SUCCESS
export const SET_PARENT_SUCCESS = 'SET_PARENT_SUCCESS' as const;

export const setParentSuccess = (
	parentIssue: IssueParent,
	oldParentIdsByIssueIds: {
		[key: string]: IssueId | null;
	},
) => ({
	type: SET_PARENT_SUCCESS,
	payload: {
		parentIssue,
		oldParentIdsByIssueIds,
	},
});

export type SetParentSuccessAction = ReturnType<typeof setParentSuccess>;

// SET_PARENT_FAILURE
export const SET_PARENT_FAILURE = 'SET_PARENT_FAILURE' as const;

export const setParentFailure = () => ({
	type: SET_PARENT_FAILURE,
});

// UNSET_PARENT_SUCCESS
export const UNSET_PARENT_SUCCESS = 'UNSET_PARENT_SUCCESS' as const;

export const unsetParentSuccess = (oldParentIdsByIssueIds: { [key: string]: IssueId | null }) => ({
	type: UNSET_PARENT_SUCCESS,
	payload: {
		oldParentIdsByIssueIds,
	},
});

// UNSET_PARENT_FAILURE
export const UNSET_PARENT_FAILURE = 'UNSET_PARENT_FAILURE' as const;

export const unsetParentFailure = () => ({
	type: UNSET_PARENT_FAILURE,
});

export type UnsetParentSuccessAction = ReturnType<typeof unsetParentSuccess>;

export type Action =
	| ShowAssignIssueParentModal
	| ReturnType<typeof hideAssignIssueParentModal>
	| FetchIssueParentsWithInfoRequestAction
	| FetchIssueParentsSuccessAction
	| FetchIssueParentsFailureAction
	| SetParentRequestAction
	| UnsetParentRequestAction
	| SetParentSuccessAction
	| UnsetParentSuccessAction
	| ReturnType<typeof setParentFailure>
	| ReturnType<typeof unsetParentFailure>;
