import type { MiddlewareAPI, Store } from 'redux';
import { type ActionsObservable, type Epic, combineEpics } from 'redux-observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { Observable } from 'rxjs/Observable';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueViewNonCriticalDataActions } from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-issue-view-non-critical-data/types.tsx';
import { SAVE_APPROVAL_ANSWER_SUCCESS } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { isForgeField } from '@atlassian/jira-issue-view-common-utils/src/layout/index.tsx';
import { STATUS, TIME_ESTIMATE } from '@atlassian/jira-issue-view-configurations';
import type { DynamicIssueQueryParams } from '@atlassian/jira-issue-view-services/src/issue/gira/graphql';
import { fetchDynamicAppDataWithRetries } from '@atlassian/jira-issue-view-services/src/issue/issue-fetch-server';
import { ZERO_APPROVAL_REQUIRED } from '@atlassian/jira-issue-view-store/src/actions/approval-action';
import {
	refreshIssueSuccess,
	type IssueFetchAction,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import {
	markTokenAsOutdated,
	type ViewContextAction,
} from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	fieldMetaKeySelector,
	fieldEditingValueSelector,
	fieldTypeSelector,
	fieldEditSessionIdSelector,
	fieldSelector,
	fieldPersistedValueSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { deleteDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions';
import {
	type FieldEditCancelAction,
	fieldEditCancel,
	type FieldEditConfirmAction,
	FIELD_EDIT_CONFIRM,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions';
import {
	fieldSaveRequest,
	fieldSaveSuccess,
	fieldSaveFailure,
	fieldUpdated,
	type FieldSaveAction,
	type FieldUpdatedAction,
	type SweetStateFieldUpdatedAction,
	FIELD_SAVE_REQUEST,
	FIELD_UPDATED,
	FIELD_UPDATE,
	SWEET_STATE_FIELD_UPDATED,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/types.tsx';
import {
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { hasMediaFileNodesUpdated } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import getRefreshedAppDataAndStoreFields from './field-save-epic-get-refreshed-app-data-and-store-fields';

type Action =
	| IssueFetchAction
	| FieldSaveAction
	| FieldUpdatedAction
	| SweetStateFieldUpdatedAction
	| ViewContextAction
	| FieldEditCancelAction
	| FieldEditConfirmAction;

type UpdatedAction = FieldUpdatedAction | SweetStateFieldUpdatedAction;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultIdRetriever = (value: any) => value && value.id;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractValidationErrorMessage = (exception: any) => {
	if (exception instanceof ValidationError) {
		if (exception.errors && exception.errors.length > 0) {
			return exception.errors[0].error;
		}

		return exception.message;
	}

	return null;
};

const fetchNonCriticalData = (
	state: State,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) => {
	const issueKey = issueKeySelector(state);
	issueViewNonCriticalDataActions?.fetchNonCriticalData(issueKey);
};

const getRefreshedAppData = (
	state: State,
	dynamicIssueQueryParams: undefined,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) => {
	ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w') &&
		fetchNonCriticalData(state, issueViewNonCriticalDataActions);
	return fetchDynamicAppDataWithRetries(state, dynamicIssueQueryParams).map(refreshIssueSuccess);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFieldValue = (fieldType: null | string, value: any) => {
	// BENTO-2977 For ecosystem issue fields we only save id into jira.
	switch (fieldType) {
		case ISSUE_FIELD_SINGLE_SELECT_CF_TYPE:
			// Return null if value is nullish to clear
			return value?.id ?? null;
		case ISSUE_FIELD_MULTI_SELECT_CF_TYPE:
			// @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
			return value.map((v) => v.id);
		default:
			return value;
	}
};

const saveFieldWithSuccess = (
	state: State,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldId: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldOptions: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	saveFieldData: any,
	fieldValueActions: FieldValueServiceActions,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	shouldFetchViewContext: any,
) => {
	const baseUrl = baseUrlSelector(state);
	const issueKey = issueKeySelector(state);
	const fieldMetaKey = fieldMetaKeySelector(fieldId)(state);
	const fieldType = fieldTypeSelector(fieldId)(state);
	const fieldEditSessionId = fieldEditSessionIdSelector(fieldId)(state);
	const field = fieldSelector(fieldId)(state);

	const fieldValue = isForgeField(field) ? value : getFieldValue(fieldType, value);

	fieldValueActions.setFieldValue(issueKey, fieldId, fieldValue);

	const saveField$ = Observable.fromPromise(
		fieldOptions.saveField(
			{
				baseUrl,
				issueKey,
				fieldId,
				fieldMetaKey,
				value: fieldValue,
				saveFieldData,
				...(fieldEditSessionId ? { fieldEditSessionId } : null),
			},
			state,
		),
	);

	return saveField$.flatMap((saveFieldResult) => {
		// @ts-expect-error - TS2339 - Property 'updatedFieldValue' does not exist on type 'unknown'.
		const { updatedFieldValue } = saveFieldResult || {};
		let updatedValue = fieldValue;
		if (saveFieldResult && updatedFieldValue !== undefined) {
			fieldValueActions.setFieldValue(issueKey, fieldId, updatedFieldValue);
			updatedValue = updatedFieldValue;
		}
		return [
			fieldSaveSuccess(fieldId, fieldOptions, updatedValue, saveFieldResult, {
				issueKey,
				shouldFetchViewContext,
			}),
			fieldUpdated(fieldId, updatedValue),
		] as const;
	});
};

const saveFieldAndWaitForAppDataRefresh = (
	state: State,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldId: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldOptions: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	saveFieldData: any,
	fieldValueActions: FieldValueServiceActions,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	shouldFetchViewContext: any,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) =>
	saveFieldWithSuccess(
		state,
		fieldId,
		value,
		fieldOptions,
		saveFieldData,
		fieldValueActions,
		shouldFetchViewContext,
	).mergeMap((saveFieldSuccessAction) =>
		getRefreshedAppData(state, undefined, issueViewNonCriticalDataActions).mergeMap(
			(fetchIssueSuccessAction) =>
				// Emit the fetch success first so the reducer will update the value based on the issue fetch result.
				// @ts-expect-error - TS2769 - No overload matches this call.
				Observable.of(fetchIssueSuccessAction, saveFieldSuccessAction),
		),
	);

const saveRequestOnEditConfirm =
	(fieldValueActions: FieldValueServiceActions) =>
	(action$: ActionsObservable<FieldEditConfirmAction>, store: Store<State, Action>) =>
		action$.ofType(FIELD_EDIT_CONFIRM).map((action) => {
			const state = store.getState();
			const {
				fieldId,
				fieldOptions,
				saveFieldData,
				analyticsEvent,
				analyticsAttributeIdRetriever = defaultIdRetriever,
			} = action.payload;

			const issueKey = issueKeySelector(state);

			const valueToSave = fieldEditingValueSelector(fieldId)(state);
			const existingValue = fieldValueActions.getFieldValue(issueKey, fieldId);

			// cancel the edit if the value has not changed
			if (isEqual(valueToSave, existingValue)) {
				store.dispatch(fieldEditCancel(fieldId, fieldOptions));
				return deleteDraftRequest({ fieldId });
			}

			let shouldFetchViewContext = false;
			if (fieldOptions.isRichTextField && fieldOptions.canContainMediaContent) {
				const field = fieldSelector(fieldId)(state);
				const currentAdfValue = field && field.adfValue;
				shouldFetchViewContext = hasMediaFileNodesUpdated(currentAdfValue, valueToSave);

				if (shouldFetchViewContext) {
					store.dispatch(markTokenAsOutdated());
				}
			}

			const fieldAnalyticsData = pickBy(
				{
					updatedField: fieldId,
					oldValId: analyticsAttributeIdRetriever(existingValue),
					newValId: analyticsAttributeIdRetriever(valueToSave),
				},
				(value) => value != null,
			);

			return fieldSaveRequest(
				fieldId,
				valueToSave,
				fieldOptions,
				saveFieldData,
				analyticsEvent,
				// @ts-expect-error - TS2345 - Argument of type 'Dictionary<any>' is not assignable to parameter of type 'FieldAnalyticsPayload'.
				fieldAnalyticsData,
				shouldFetchViewContext,
			);
		});

const saveFieldWithoutRequest =
	(issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(FIELD_UPDATE)
			.groupBy((action) => action.payload.fieldId)
			.mergeMap((fieldSaveWithoutRequest$) =>
				fieldSaveWithoutRequest$.switchMap((action) => {
					const state = store.getState();
					const { fieldId, fieldValue, fieldOptions } = action.payload;

					/* If this is an optimistic save - do nothing and immediately dispatch a save success action.
                   If it's not optimistic - wait for an issue refresh, then dispatch the fetch issue success action. */
					const saveField$ = fieldOptions.isOptimistic
						? Observable.of(fieldUpdated(fieldId, fieldValue))
						: getRefreshedAppData(state, undefined, issueViewNonCriticalDataActions).mergeMap(
								(fetchIssueSuccessAction) =>
									// Emit the fetch success first so the reducer will update the value based on the issue fetch result.
									Observable.of(
										fetchIssueSuccessAction,
										// @ts-expect-error - TS2769 - No overload matches this call.
										fieldUpdated(fieldId, fieldValue),
									),
							);

					// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<{ type: "REFRESH_ISSUE_SUCCESS"; payload: any; }> | Observable<FieldUpdatedReturnType<any>>' is not assignable to method's 'this' of type 'Observable<{ type: "REFRESH_ISSUE_SUCCESS"; payload: any; }>'.
					return saveField$.catch((exception) => {
						/* Extract the server error if we can, otherwise just use null.
                       Passing a null into the fieldSaveFailure will result on
                       isInvalid being set to true, but with no invalidMessage,
                       which components can use to determine a fallback message. */
						const invalidMessage = extractValidationErrorMessage(exception);
						// Revert the previous persisted value in case of error
						return Observable.of(
							fieldSaveFailure(fieldId, invalidMessage, exception, fieldOptions),
						);
					});
				}),
			);

const saveFieldOnRequest =
	(
		fieldValueActions: FieldValueServiceActions,
		issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
	) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(FIELD_SAVE_REQUEST)
			.groupBy((action) => action.payload.fieldId)
			.mergeMap((fieldSaveRequest$) =>
				fieldSaveRequest$.switchMap((action) => {
					const state = store.getState();
					const issueKey = issueKeySelector(state);
					const { fieldId, value, fieldOptions, saveFieldData, shouldFetchViewContext } =
						action.payload;

					/* If this is an optimistic save - save and immediately dispatch a save success action.
                   If it's not optimistic - wait for an issue refresh, then dispatch both the save success action and the fetch issue success action. */
					const saveField$ = fieldOptions.isOptimistic
						? saveFieldWithSuccess(
								state,
								fieldId,
								value,
								fieldOptions,
								saveFieldData,
								fieldValueActions,
								shouldFetchViewContext,
							)
						: saveFieldAndWaitForAppDataRefresh(
								state,
								fieldId,
								value,
								fieldOptions,
								saveFieldData,
								fieldValueActions,
								shouldFetchViewContext,
								issueViewNonCriticalDataActions,
							);

					const prevValue = fieldPersistedValueSelector(fieldId)(state);

					// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<{ type: "REFRESH_ISSUE_SUCCESS"; payload: any; }> | Observable<FieldSaveSuccessReturnType<unknown> | FieldUpdatedReturnType<any>>' is not assignable to method's 'this' of type 'Observable<{ type: "REFRESH_ISSUE_SUCCESS"; payload: any; }>'.
					return saveField$.catch((exception) => {
						/* Extract the server error if we can, otherwise just use null.
                       Passing a null into the fieldSaveFailure will result on
                       isInvalid being set to true, but with no invalidMessage,
                       which components can use to determine a fallback message. */
						const invalidMessage = extractValidationErrorMessage(exception);
						// Revert the previous persisted value in case of error
						fieldValueActions.setFieldValue(issueKey, fieldId, prevValue);
						return Observable.of(
							fieldSaveFailure(fieldId, invalidMessage, exception, fieldOptions),
						);
					});
				}),
			);

const getDynamicIssueQueryParams = (action: UpdatedAction): DynamicIssueQueryParams | undefined => {
	const { fieldId, extraParams } = action.payload;
	if (fieldId === STATUS) {
		return {
			shouldRefreshComments: extraParams && extraParams.viaDialog,
		};
	}

	return undefined;
};

/* Some modern fields are handled externally and never trigger a FIELD_SAVE_REQUEST action inside Bento,
   therefore we'll never refresh the issue data. This epic refreshes the data manually for those fields. */
const externallyHandledFields = [STATUS];
const externallyHandledFieldsWithTimeEstimate = [...externallyHandledFields, TIME_ESTIMATE];

const conditionallyRefreshIssueAfterFieldSaveSuccess =
	(
		fieldValueActions: FieldValueServiceActions,
		fieldsConfigActions: FieldConfigServiceActions,
		workflowTransitionsActions: WorkflowTransitionsActions,
		associatedIssuesContextActions?: AssociatedIssuesContextActions,
		issueContextActions?: IssueContextServiceActions,
		issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
	) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(FIELD_UPDATED, SWEET_STATE_FIELD_UPDATED)
			.filter((action: UpdatedAction) =>
				externallyHandledFieldsWithTimeEstimate.includes(action.payload.fieldId),
			)
			.switchMap((action: UpdatedAction) =>
				getRefreshedAppDataAndStoreFields(
					store.getState(),
					getDynamicIssueQueryParams(action),
					fieldValueActions,
					fieldsConfigActions,
					workflowTransitionsActions,
					associatedIssuesContextActions,
					issueContextActions,
					issueViewNonCriticalDataActions,
				),
			);

const conditionallyRefreshIssueForApprovalsOld = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(SAVE_APPROVAL_ANSWER_SUCCESS, ZERO_APPROVAL_REQUIRED)
		// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
		.switchMap(() => getRefreshedAppData(store.getState()));

const conditionallyRefreshIssueForApprovals =
	(issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(SAVE_APPROVAL_ANSWER_SUCCESS, ZERO_APPROVAL_REQUIRED)
			.switchMap(() =>
				getRefreshedAppData(store.getState(), undefined, issueViewNonCriticalDataActions),
			);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	fieldValueActions: FieldValueServiceActions,
	fieldsConfigActions: FieldConfigServiceActions,
	workflowTransitionsActions: WorkflowTransitionsActions,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	issueContextActions?: IssueContextServiceActions,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) =>
	combineEpics<Action, State>(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		saveRequestOnEditConfirm(fieldValueActions) as Epic<Action, State>,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		saveFieldOnRequest(fieldValueActions, issueViewNonCriticalDataActions) as Epic<Action, State>,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		saveFieldWithoutRequest(issueViewNonCriticalDataActions) as Epic<Action, State>,
		conditionallyRefreshIssueAfterFieldSaveSuccess(
			fieldValueActions,
			fieldsConfigActions,
			workflowTransitionsActions,
			associatedIssuesContextActions,
			issueContextActions,
			issueViewNonCriticalDataActions,
		),
		ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')
			? conditionallyRefreshIssueForApprovals(issueViewNonCriticalDataActions)
			: conditionallyRefreshIssueForApprovalsOld,
	);
