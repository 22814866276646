import React, { useState, useCallback, useMemo } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useAutomappedOptionsCount,
	useFieldOptionsValuesMapping,
	useUnmappedOptionsCount,
} from '../../../../controllers/options-mapping';
import messages from './messages';
import { OptionsMappingModal } from './options-mapping-modal';

export const OptionsMapping = () => {
	const { formatMessage } = useIntl();
	const optionsValueMapping = useFieldOptionsValuesMapping();
	const unmappedOptionsCount = useUnmappedOptionsCount();
	const automappedOptionsCount = useAutomappedOptionsCount();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isOptionsMappingModalOpen, setIsOptionsMappingModalOpen] = useState(false);

	const totalOptions = Object.keys(optionsValueMapping).length;

	const hasUnmappedOptions = unmappedOptionsCount > 0;
	const allOptionsAutomapped = automappedOptionsCount === totalOptions;

	const onOptionsMappingModalOpen = useCallback(
		(_event: React.MouseEvent, analyticEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticEvent, 'openOptionsMappingModal');
			setIsOptionsMappingModalOpen(true);
		},
		[setIsOptionsMappingModalOpen],
	);

	const onOptionsMappingModalClose = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'closeOptionsMappingModal',
		);
		setIsOptionsMappingModalOpen(false);
	}, [setIsOptionsMappingModalOpen, createAnalyticsEvent]);

	const mappingStatusHeader = useMemo(() => {
		if (allOptionsAutomapped) {
			return messages.optionsAutomappedHeader;
		}
		if (hasUnmappedOptions) {
			return messages.optionsNotMappedHeader;
		}
		if (automappedOptionsCount > 0) {
			return messages.optionsMappedHeaderNonFinal;
		}
		return messages.optionsManuallyMappedHeaderNonFinal;
	}, [hasUnmappedOptions, allOptionsAutomapped, automappedOptionsCount]);

	return (
		<>
			<SectionMessage
				appearance={hasUnmappedOptions ? 'warning' : 'success'}
				actions={
					<Button onClick={onOptionsMappingModalOpen}>
						{formatMessage(hasUnmappedOptions ? messages.mapOptions : messages.reviewMapping)}
					</Button>
				}
			>
				<Box xcss={headingStyles}>
					<Heading size="xsmall">
						{formatMessage(mappingStatusHeader, {
							totalOptionsCount: totalOptions,
							manuallyMappedOptionsCount: totalOptions - automappedOptionsCount,
							unmappedOptionsCount,
							automappedOptionsCount,
						})}
					</Heading>
				</Box>
				{hasUnmappedOptions && formatMessage(messages.optionsNotMappedDescription)}
			</SectionMessage>
			<OptionsMappingModal
				isOpen={isOptionsMappingModalOpen}
				onClose={onOptionsMappingModalClose}
			/>
		</>
	);
};

const headingStyles = xcss({
	marginBottom: 'space.100',
});
