import type {
	Permissions,
	PermissionsListTransformed,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/permissions.tsx';
import type {
	ViewIssueData,
	Jira,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type { Permission as ServerPermission } from '@atlassian/jira-issue-view-common-types/src/gira';
import { defaultPermissions } from '@atlassian/jira-project-permissions-service/src/context.tsx';
import { isIssueExportControlEnabled } from '../../../feature-flags';
import { transformPermissions } from '../../issue-transformer';

export { getQuery } from './graphql';

// Remove when cleaning up optimise canCreateSubTask ff
export const transformPermissionsList = (data?: ServerPermission[]): PermissionsListTransformed => {
	if (!data || data.length === 0) {
		return {
			permissions: { ...defaultPermissions },
		};
	}

	const permissions = data.reduce<Record<string, ServerPermission>>((acc, permission) => {
		acc[permission.key] = permission;
		return acc;
	}, {});

	return transformPermissions({
		// @ts-expect-error - TS2740 - Type 'Record<string, any>' is missing the following properties from type 'ServerPermissionValues': ADMINISTER, ADMINISTER_PROJECTS, ASSIGN_ISSUES, EDIT_ISSUES, and 28 more.
		permissions,
	});
};

export const transformData = (
	data?: ServerPermission[],
	viewIssue?: ViewIssueData,
	jira?: Jira | null,
): Permissions => {
	const transformedPermissionsData = transformPermissionsList(data);

	transformedPermissionsData.permissions.canCreateSubtask = viewIssue?.canCreateSubTask
		? viewIssue?.canCreateSubTask
		: defaultPermissions.canCreateSubtask;
	transformedPermissionsData.permissions.canAdministerJira = jira?.hasAdministerPermission
		? jira?.hasAdministerPermission
		: defaultPermissions.canAdministerJira;
	transformedPermissionsData.permissions.canBrowseUsers = jira?.hasUserPickerPermission
		? jira?.hasUserPickerPermission
		: defaultPermissions.canBrowseUsers;

	if (isIssueExportControlEnabled()) {
		transformedPermissionsData.permissions.canExportIssue =
			viewIssue?.canExportIssue ?? defaultPermissions.canExportIssue;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return transformedPermissionsData as Permissions;
};
