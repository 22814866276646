import React, { useRef, useState, type Ref } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import { DEFAULT_PARENT_ISSUE_TYPE_NAME } from '@atlassian/jira-issue-view-common-constants/src/issue-types.tsx';
import IssueParentSwitcher from '../../issue-parent-switcher';
import { truncateBreadcrumbs } from '../common/utils';
import messages from '../messages';
import { IssueContainer, SmallBreadcrumbsItem } from '../styled';

type ExternalProps = {
	isSimplifiedProject?: boolean;
	issueTypeName?: string;
	issueHierarchyLevel?: number | null;
	triggerRef?: Ref<HTMLElement>;
	onAddOrRemoveParent?: () => void;
};

export const BreadcrumbAddParent = ({
	isSimplifiedProject = false,
	issueTypeName = DEFAULT_PARENT_ISSUE_TYPE_NAME,
	issueHierarchyLevel,
	triggerRef = { current: null },
	onAddOrRemoveParent = noop,
}: ExternalProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const breadcrumbsItemRef = useRef<unknown>();

	const target =
		issueTypeName && issueTypeName !== DEFAULT_PARENT_ISSUE_TYPE_NAME
			? issueTypeName
			: DEFAULT_PARENT_ISSUE_TYPE_NAME.toLowerCase();

	/**
	 * - For orphan issues in TMP projects, we display "Add <issueTypeName>"
	 * - For orphan issues in CMP projects, we display "Add parent"
	 *
	 * See https://hello.jira.atlassian.cloud/browse/JPO-25790 for future reference
	 *
	 * @returns MessageDescriptor
	 */
	const getIssueParentSwitcherLabel = () => {
		if (isSimplifiedProject) {
			return formatMessage(messages.addParent, { target });
		}
		return formatMessage(messages.addParentLabel);
	};

	return (
		<IssueContainer
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.breadcrumbs.add-parent.breadcrumb-add-parent"
		>
			<IssueParentSwitcher
				clickRef={breadcrumbsItemRef}
				isOpen={isOpen}
				isOrphan
				issueTypeName={issueTypeName}
				onOpenChange={(newIsOpenState: boolean) => setIsOpen(newIsOpenState)}
				text={truncateBreadcrumbs(getIssueParentSwitcherLabel())}
				issueHierarchyLevel={issueHierarchyLevel}
				onAddOrRemoveParent={onAddOrRemoveParent}
				triggerRef={triggerRef}
				component={SmallBreadcrumbsItem}
			/>
		</IssueContainer>
	);
};

export default BreadcrumbAddParent;
