/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty';
import urlParse from 'url-parse';
import type { Payload, Field } from '@atlassian/jira-issue-create/src/common/types';
import { FORMATTER, FIELD_MAPPER_MUTATION_GROUP } from './constants';
import {
	ECOSYSTEM_API_FIELD_FORMATTER,
	ECOSYSTEM_API_FIELD_MAPPER_MUTATION_GROUP,
} from './ecosystem-api-field-map';
import type { OldGICPayload, OldFieldMapper } from './types';

const CUSTOM_FIELD_PREFIX = 'customfield';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const mergePrefilled = <T extends unknown>(oldPayload: T, newPayload?: T | null): T => {
	if (newPayload !== null && newPayload !== undefined) {
		// @ts-expect-error - TS2698 - Spread types may only be created from object types. | TS2698 - Spread types may only be created from object types.
		return { ...oldPayload, ...newPayload };
	}
	return oldPayload;
};

const returnActualValue = (value: (string | number)[] | string | number): string => {
	if (Array.isArray(value)) {
		return value[0].toString();
	}
	return value.toString();
};

// @ts-expect-error - TS7023 - 'mergeFormat' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions. | TS7006 - Parameter 'format' implicitly has an 'any' type.
const mergeFormat = (format, finalKey: string, finalValue: string | Array<string>) => {
	// Ideally format can be object or string
	// Format will only contain 1 object having single key/value where value can be null or an object
	// go inside and wherever we find first null, replace the same with the value.

	// some of the mutation use fieldId whereas other uses like priorityId
	// so whichever key has value === ID will replace the same with the proper mutationKey
	if (typeof format === 'string') {
		if (format === 'ID') {
			return finalKey;
		}
		return format;
	}

	// recursively goes inside to find the null and key param and replace with the corresponding values
	// Also checks for array like labels

	return Object.keys(format).reduce<Record<string, any>>((accumulator, currentKey) => {
		const currentValue = format[currentKey];
		if (Array.isArray(currentValue)) {
			const mappedValue = Array.isArray(finalValue) ? finalValue : [finalValue];
			return {
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...accumulator,
				[currentKey]: mappedValue.map((val) => mergeFormat(currentValue[0], finalKey, val)),
			};
		}
		return {
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...accumulator,
			[currentKey]:
				currentValue === null
					? returnActualValue(finalValue)
					: mergeFormat(format[currentKey], finalKey, finalValue),
		};
	}, {});
};

const generateDefaultValuesForEcosystemAPI = (otherFields: {
	[key: string]: OldFieldMapper;
}): Field => {
	const defaultValues: Record<string, any> = {};

	const fieldIds: Array<any | string> = [];

	Object.keys(otherFields).forEach((mutationKey: string): void => {
		const mutationValue = otherFields[mutationKey];
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly summary: "singleLineTextFields"; readonly epic: "epicLinkField"; readonly epicName: "singleLineTextFields"; readonly duedate: "datePickerFields"; readonly startdate: "datePickerFields"; ... 7 more ...; readonly components: "components"; }'.
		if (ECOSYSTEM_API_FIELD_MAPPER_MUTATION_GROUP[mutationKey]) {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly summary: "singleLineTextFields"; readonly epic: "epicLinkField"; readonly epicName: "singleLineTextFields"; readonly duedate: "datePickerFields"; readonly startdate: "datePickerFields"; ... 7 more ...; readonly components: "components"; }'.
			const mutationGroup = ECOSYSTEM_API_FIELD_MAPPER_MUTATION_GROUP[mutationKey];
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly summary: "singleLineTextFields"; readonly epic: "epicLinkField"; readonly epicName: "singleLineTextFields"; readonly duedate: "datePickerFields"; readonly startdate: "datePickerFields"; ... 7 more ...; readonly components: "components"; }'.
			const formattedValue = ECOSYSTEM_API_FIELD_FORMATTER[mutationGroup](
				mutationKey,
				mutationValue,
			);
			if (formattedValue) {
				const { value, type } = formattedValue;
				fieldIds.push(mutationKey);
				if (!isEmpty(value)) {
					if (type === 'array') {
						(defaultValues[mutationGroup] = defaultValues[mutationGroup] || []).push(value);
					} else if (type === 'object') {
						defaultValues[mutationGroup] = value;
					}
				}
			}
		}
	});
	return { defaultValues, fieldIds };
};

const generateDefaultValuesLegacy = (otherFields: { [key: string]: OldFieldMapper }): Field => {
	const defaultValues: Record<string, any> = {};

	const fieldIds: Array<any | string> = [];
	Object.keys(otherFields).forEach((mutationKey: string): void => {
		const mutationValue = otherFields[mutationKey];
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly summary: "singleLineTextFields"; readonly epic: "epicLinkField"; readonly epicName: "singleLineTextFields"; readonly duedate: "datePickerFields"; readonly startdate: "datePickerFields"; ... 7 more ...; readonly components: "components"; }'.
		if (FIELD_MAPPER_MUTATION_GROUP[mutationKey]) {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly summary: "singleLineTextFields"; readonly epic: "epicLinkField"; readonly epicName: "singleLineTextFields"; readonly duedate: "datePickerFields"; readonly startdate: "datePickerFields"; ... 7 more ...; readonly components: "components"; }'.
			const mutationGroup = FIELD_MAPPER_MUTATION_GROUP[mutationKey];
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly singleLineTextFields: { readonly value: { readonly fieldId: "ID"; readonly text: null; }; readonly type: "array"; }; readonly epicLinkField: { readonly value: { readonly fieldId: "ID"; readonly epicLink: { readonly issueKey: null; }; }; readonly type: "object"; }; ... 7 more ...; readonly components: { .....'.
			const { type = null, value: depth = null } = FORMATTER[mutationGroup];
			let pattern = null;
			if (!isEmpty(mutationValue) && depth && type) {
				if (!Array.isArray(mutationValue) && typeof mutationValue === 'object') {
					const { fieldId, fieldValue } = mutationValue;
					// If fieldValue is different from the key like custom fields
					pattern = mergeFormat(depth, fieldId || mutationKey, fieldValue);
					fieldIds.push(fieldId || mutationKey);
				} else {
					// same as key like summary etc.
					pattern = mergeFormat(depth, mutationKey, mutationValue);
					fieldIds.push(mutationKey);
				}
			}
			// merge mutations based on type as some mutations are object whereas others are arrays
			if (!isEmpty(pattern)) {
				if (type === 'array') {
					(defaultValues[mutationGroup] = defaultValues[mutationGroup] || []).push(pattern);
				} else if (type === 'object') {
					defaultValues[mutationGroup] = pattern;
				}
			}
		}
	});
	return { defaultValues, fieldIds };
};

const generateDefaultValues = (
	otherFields: { [key: string]: OldFieldMapper },
	isEcosystemAPI = false,
): Field => {
	if (isEcosystemAPI) return generateDefaultValuesForEcosystemAPI(otherFields);
	return generateDefaultValuesLegacy(otherFields);
};

export const extractEventAttributes = (trigger: HTMLElement): OldGICPayload => {
	const anchor = trigger.nodeName === 'A' ? trigger : trigger.querySelector('a');

	if (anchor) {
		const href = anchor.getAttribute('href');
		// @ts-expect-error - TS2769 - No overload matches this call. | TS7006 - Parameter 'qs' implicitly has an 'any' type.
		const { query } = urlParse(href, (qs) => new URLSearchParams(qs));
		const returnData = { prefilledFields: {} };
		// If data attributes exist, we will prefer those over the query params.
		// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'. | TS2349 - This expression is not callable.
		const pid = anchor.getAttribute('data-pid') || query.get('pid');
		// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'. | TS2349 - This expression is not callable.
		const issuetype = anchor.getAttribute('data-issue-type') || query.get('issuetype');
		// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'. | TS2349 - This expression is not callable.
		const requestype = anchor.getAttribute('data-itsm-category') || query.get('itsmCategory');

		if (pid) {
			// @ts-expect-error - TS2339 - Property 'pid' does not exist on type '{}'.
			returnData.prefilledFields.pid = pid;
		}
		if (issuetype) {
			// @ts-expect-error - TS2339 - Property 'issuetype' does not exist on type '{}'.
			returnData.prefilledFields.issuetype = issuetype;
		}
		if (requestype) {
			// @ts-expect-error - TS2339 - Property 'requestype' does not exist on type '{}'.
			returnData.prefilledFields.requestype = requestype;
		}
		if (!isEmpty(returnData.prefilledFields)) {
			// @ts-expect-error - TS2322 - Type '{ prefilledFields: {}; }' is not assignable to type 'OldGICPayload'.
			return returnData;
		}
		return {};
	}

	return {};
};

export const transformOldGICPayloadToNew = (
	payload: OldGICPayload = {},
	isEcosystemAPI = false,
): Payload | null => {
	const { prefilledFields: gicPreFilledFields, target = null } = payload;
	let prefilledFields = gicPreFilledFields;
	if (target) {
		const newPayload = extractEventAttributes(target);
		prefilledFields = mergePrefilled(gicPreFilledFields, newPayload.prefilledFields);
	}
	if (!isEmpty(prefilledFields)) {
		const {
			fieldsToRetain = [],
			pid,
			issuetype,
			requestype,
			additionalContext,
			statusId,
			...otherFields
		} = prefilledFields;
		const outputPayload: Partial<Payload> = {};

		if ((!isEmpty(pid) || typeof pid === 'number') && pid !== 'undefined') {
			outputPayload.project = {
				projectId: returnActualValue(pid),
			};
		}

		if (!isEmpty(issuetype) || typeof issuetype === 'number') {
			outputPayload.issueType = {
				issueTypeId: returnActualValue(issuetype),
			};
		}

		if (!isEmpty(requestype)) {
			outputPayload.requestType = {
				key: returnActualValue(requestype),
			};
		}

		if (!isEmpty(otherFields.parentId)) {
			outputPayload.parentId = {
				parentId: returnActualValue(otherFields.parentId),
			};
		}

		if (!isEmpty(statusId)) {
			outputPayload.status = {
				statusId,
			};
		}

		if (!isEmpty(additionalContext)) {
			outputPayload.additionalContext = additionalContext;
		}
		// for other fields
		if (Object.keys(otherFields).length > 0) {
			const { parentId, ...otherFieldsWithoutParent } = otherFields;
			const { defaultValues, fieldIds } = generateDefaultValues(
				parentId !== undefined && !isEmpty(parentId)
					? { parent: parentId, ...otherFieldsWithoutParent }
					: otherFieldsWithoutParent,
				isEcosystemAPI,
			);
			if (!isEmpty(defaultValues)) {
				outputPayload.defaultValues = defaultValues;
			}
			outputPayload.customFieldsToPreserveOnFormRecreation = [...fieldsToRetain, ...fieldIds];
		}
		return outputPayload;
	}
	return null;
};

export const filterCustomFields = (payload: OldGICPayload = {}): Field => {
	// customField's id's start with the name customfield https://confluence.atlassian.com/adminjiraserver/advanced-use-of-the-jira-issue-collector-938847349.html
	const fieldsToPrefill = payload?.prefilledFields;
	if (!fieldsToPrefill) return {};
	const prefilledCustomFields: Field = {};
	const workLogSubFieldTypes: string[] = [
		'worklog_timeLogged',
		'worklog_startDate',
		'worklog_comment',
		'timetracking_remainingestimate',
	];
	Object.keys(fieldsToPrefill).forEach((key) => {
		if (key.startsWith(CUSTOM_FIELD_PREFIX) || workLogSubFieldTypes.includes(key))
			prefilledCustomFields[key] = fieldsToPrefill[key];
	});
	return prefilledCustomFields;
};

export const mergeDefaultValues = (
	defaultValuesFromAPI: Record<string, any> = {},
	defaultValuesFromFE: Field | undefined = {},
) => {
	const mergedDefaultValues = JSON.parse(JSON.stringify(defaultValuesFromAPI));
	if (!defaultValuesFromFE) return mergedDefaultValues;
	Object.keys(defaultValuesFromFE).forEach((key) => {
		if (mergedDefaultValues?.key)
			mergedDefaultValues[key] = { ...mergedDefaultValues[key], ...defaultValuesFromFE[key] };
		else mergedDefaultValues[key] = defaultValuesFromFE[key];
	});
	return mergedDefaultValues;
};

export const transformGICPayload = transformOldGICPayloadToNew;
export { generateDefaultValues };
