import type { IssueError } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import { type LoginSuccessfulAction, LOGIN_SUCCESSFUL } from '../actions/login-actions';
import {
	type FetchIssueFailureAction,
	FETCH_ISSUE_FAILURE,
} from '../common/actions/issue-fetch-actions';

type ErrorReducerAction = FetchIssueFailureAction | LoginSuccessfulAction;

const initialState = null;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: IssueError | null = initialState,
	action: ErrorReducerAction,
): IssueError | null => {
	switch (action.type) {
		case FETCH_ISSUE_FAILURE:
			return action.payload.error;
		case LOGIN_SUCCESSFUL:
			return null;
		default:
			return state;
	}
};
